import FingerprintJS, { GetResult } from '@fingerprintjs/fingerprintjs'
import { inspect, logger } from '@/utils/logger'
import { submitVisitorId } from '@/services/api'
import { appSessionStorage, sessionStorageKey } from '@/utils/storage'
import assert from 'assert'

let fingerprintResult: GetResult

const getVisitorId = async () => {
    if (fingerprintResult) {
        return fingerprintResult.visitorId
    }

    const fingerprintAgent = await FingerprintJS.load()
    fingerprintResult = await fingerprintAgent.get()
    return fingerprintResult.visitorId
}

export const initFingerprintAndUpdateAnalytics = async () => {
    const sessionId = appSessionStorage.getItem(sessionStorageKey.sessionId)
    assert(sessionId, 'initFingerprintAndUpdateAnalytics -> sessionId is not present')
    logger.info(`fingerprint.js -> Init fingerprint and get visitorId for sessionId: ${sessionId}`)

    try {
        const visitorId = await getVisitorId()
        logger.info(`fingerprint.js -> visitorId: ${visitorId}`)
        const submitVisitorIdResponse = await submitVisitorId(visitorId)

        if (!submitVisitorIdResponse.data.success) {
            logger.error(`fingerprint.js -> Failed to submit visitorId: ${visitorId} for sessionId: ${sessionId}`)
            return
        }

        logger.info(`fingerprint.js -> Successfully submitted visitorId: ${visitorId} for sessionId: ${sessionId}`)
    } catch (error: any) {
        logger.error(`fingerprint.js -> An error occurred: ${inspect(error)}`)
    }
}
