import { appLocalStorage, sessionStorageKey } from '@/utils/storage'
import { tryParseJson } from '@/utils/stringUtils'
import { mloHttpClient } from '@/utils/http-client'
import { initializeSessionRecording } from '@/services/marketing'
import { openReplay } from '@/services/openreplay'

export interface MloLoanJwt {
    sessionId: string
    jwt: string
}
class MloJwtStore {
    get = async (loanApplicationId: number): Promise<MloLoanJwt | undefined> => {
        const json = appLocalStorage.getItem(sessionStorageKey.mloJwtLoanTokens)
        if (!json) {
            return undefined
        }
        const mloJwtLoanTokens = (tryParseJson(json) || {}) as { [key: string]: MloLoanJwt }
        return mloJwtLoanTokens[`${loanApplicationId}`]
    }

    set = async (loanApplicationId: number, payload: MloLoanJwt) => {
        const json = appLocalStorage.getItem(sessionStorageKey.mloJwtLoanTokens)
        const mloJwtLoanTokens = ((json && tryParseJson(json)) || {}) as { [key: string]: MloLoanJwt }
        mloJwtLoanTokens[`${loanApplicationId}`] = payload
        appLocalStorage.setItem(sessionStorageKey.mloJwtLoanTokens, JSON.stringify(mloJwtLoanTokens))
    }

    clear = () => {
        appLocalStorage.setItem(sessionStorageKey.mloJwtLoanTokens, '')
    }
}
const mloJwtStore = new MloJwtStore()

const getOrCreateMLOJwtForLoanApplication = async (loanApplicationId: number): Promise<MloLoanJwt> => {
    const currentMLOJwt = await mloJwtStore.get(loanApplicationId)
    if (currentMLOJwt) {
        return currentMLOJwt
    }

    const response = await mloHttpClient.post('mlo/createLoanJwt', {
        loanApplicationId,
    })

    const newMLOJwt = response.data.payload as MloLoanJwt
    await mloJwtStore.set(loanApplicationId, newMLOJwt)
    initializeSessionRecording(newMLOJwt.sessionId)
    openReplay.trySetMetadata({ loanApplicationId: `${loanApplicationId}` }, newMLOJwt.sessionId)
    return newMLOJwt
}

export const mloUtils = {
    getOrCreateMLOJwtForLoanApplication,
    clearMloLoanJwt: mloJwtStore.clear,
}
