export enum ThanksPageReasons {
    authorization = 'authorization',
    blockedApplication = 'blockedApplication',
    closed = 'closed',
    coAppIdentity = 'coAppIdentity',
    declinedOffer = 'declinedOffer',
    default = 'default',
    disputeCoOwnerInfo = 'disputeCoOwnerInfo',
    duplicate = 'duplicate',
    expired = 'expired',
    geo = 'geo',
    hmdaFinished = 'hmdaFinished',
    noFile = 'noFile',
    homeIsTrustOrLLC = 'homeIsTrustOrLLC',
    homeNotFound = 'homeNotFound',
    homeOwnership = 'homeOwnership',
    jodlDocument = 'jodlDocument',
    lien = 'lien',
    identity = 'identity',
    invalidFloodZone = 'invalidFloodZone',
    involuntaryLien = 'involuntaryLien',
    privateBrowsing = 'privateBrowsing',
    renter = 'renter',
    resourceNotFound = 'resourceNotFound',
    review = 'review',
    trustNOO = 'trustNOO',
    trustRIN = 'trustRIN',
    trustUnsupportedState = 'trustUnsupportedState',
    unknownFloodZone = 'unknownFloodZone',
    unsupportedCounty = 'unsupportedCounty',
    uploadedDocs = 'uploadedDocs',
    uploadedFloodInsurance = 'uploadedFloodInsurance',
    uploadedCompetitorAgreement = 'uploadedCompetitorAgreement',
    uploadedCompetitorAgreementHeloc = 'uploadedCompetitorAgreementHeloc',
    uploadedHomeOwnersInsurance = 'uploadedHomeOwnersInsurance',
    acknowledgedFloodDisclosure = 'acknowledgedFloodDisclosure',
    missingSigners = 'missingSigners',
    insufficientIncome = 'insufficientIncome',
    uccSuccess = 'uccSuccess',
}

export const thanksPageReasonsToImgMap: { [T in ThanksPageReasons]: any } = {
    [ThanksPageReasons.authorization]: require('@/assets/images/global/padlockshield.svg'),
    [ThanksPageReasons.blockedApplication]: require('@/assets/images/global/padlockshield.svg'),
    [ThanksPageReasons.closed]: require('@/assets/images/global/sorryicecream.svg'),
    [ThanksPageReasons.coAppIdentity]: require('@/assets/images/global/sorryidentification.svg'),
    [ThanksPageReasons.declinedOffer]: require('@/assets/images/global/sadcard.svg'),
    [ThanksPageReasons.default]: require('@/assets/images/global/sorryicecream.svg'),
    [ThanksPageReasons.disputeCoOwnerInfo]: require('@/assets/images/global/human_investigate.svg'),
    [ThanksPageReasons.duplicate]: require('@/assets/images/global/human_investigate.svg'),
    [ThanksPageReasons.expired]: require('@/assets/images/global/sorryicecream.svg'),
    [ThanksPageReasons.geo]: require('@/assets/images/global/sorryidentification.svg'),
    [ThanksPageReasons.hmdaFinished]: require('@/assets/images/origination/Celebration.svg'),
    [ThanksPageReasons.noFile]: require('@/assets/images/global/sorryidentification.svg'),
    [ThanksPageReasons.uccSuccess]: require('@/assets/images/global/document-check.svg'),
    [ThanksPageReasons.homeIsTrustOrLLC]: require('@/assets/images/global/sorryhome.webp'),
    [ThanksPageReasons.homeNotFound]: require('@/assets/images/global/sorryhome.webp'),
    [ThanksPageReasons.homeOwnership]: require('@/assets/images/global/sorryhome.webp'),
    [ThanksPageReasons.identity]: require('@/assets/images/global/sorryidentification.svg'),
    [ThanksPageReasons.invalidFloodZone]: require('@/assets/images/global/sorryhome.webp'),
    [ThanksPageReasons.involuntaryLien]: require('@/assets/images/global/human_investigate.svg'),
    [ThanksPageReasons.jodlDocument]: require('@/assets/images/global/padlockshield.svg'),
    [ThanksPageReasons.lien]: require('@/assets/images/global/sorryicecream.svg'),
    [ThanksPageReasons.privateBrowsing]: require('@/assets/images/global/padlockshield.svg'),
    [ThanksPageReasons.renter]: require('@/assets/images/global/sorryhome.webp'),
    [ThanksPageReasons.resourceNotFound]: require('@/assets/images/global/human_investigate.svg'),
    [ThanksPageReasons.review]: require('@/assets/images/global/human_investigate.svg'),
    [ThanksPageReasons.trustNOO]: require('@/assets/images/global/sorryhome.webp'),
    [ThanksPageReasons.trustRIN]: require('@/assets/images/global/sorryicecream.svg'),
    [ThanksPageReasons.trustUnsupportedState]: require('@/assets/images/global/sorryicecream.svg'),
    [ThanksPageReasons.unknownFloodZone]: require('@/assets/images/global/sorryicecream.svg'),
    [ThanksPageReasons.uploadedDocs]: require('@/assets/images/global/human_investigate.svg'),
    [ThanksPageReasons.uploadedFloodInsurance]: require('@/assets/images/global/human_investigate.svg'),
    [ThanksPageReasons.uploadedHomeOwnersInsurance]: require('@/assets/images/global/human_investigate.svg'),
    [ThanksPageReasons.acknowledgedFloodDisclosure]: require('@/assets/images/global/human_investigate.svg'),
    [ThanksPageReasons.unsupportedCounty]: require('@/assets/images/global/sorryhome.webp'),
    [ThanksPageReasons.missingSigners]: require('@/assets/images/origination/Celebration.svg'),
    [ThanksPageReasons.insufficientIncome]: require('@/assets/images/global/sorryicecream.svg'),
    [ThanksPageReasons.uploadedCompetitorAgreement]: null,
    [ThanksPageReasons.uploadedCompetitorAgreementHeloc]: null,
}

export const shouldDisplayAnchorOnThanksPage: { [T in ThanksPageReasons]: boolean } = {
    [ThanksPageReasons.authorization]: false,
    [ThanksPageReasons.blockedApplication]: false,
    [ThanksPageReasons.coAppIdentity]: true,
    [ThanksPageReasons.closed]: true,
    [ThanksPageReasons.declinedOffer]: true,
    [ThanksPageReasons.default]: false,
    [ThanksPageReasons.disputeCoOwnerInfo]: false,
    [ThanksPageReasons.duplicate]: true,
    [ThanksPageReasons.expired]: true,
    [ThanksPageReasons.geo]: true,
    [ThanksPageReasons.hmdaFinished]: true,
    [ThanksPageReasons.noFile]: true,
    [ThanksPageReasons.homeIsTrustOrLLC]: true,
    [ThanksPageReasons.homeNotFound]: false,
    [ThanksPageReasons.homeOwnership]: false,
    [ThanksPageReasons.identity]: true,
    [ThanksPageReasons.invalidFloodZone]: true,
    [ThanksPageReasons.involuntaryLien]: false,
    [ThanksPageReasons.jodlDocument]: false,
    [ThanksPageReasons.lien]: false,
    [ThanksPageReasons.privateBrowsing]: false,
    [ThanksPageReasons.renter]: true,
    [ThanksPageReasons.resourceNotFound]: false,
    [ThanksPageReasons.review]: false,
    [ThanksPageReasons.trustNOO]: false,
    [ThanksPageReasons.trustRIN]: true,
    [ThanksPageReasons.trustUnsupportedState]: true,
    [ThanksPageReasons.unknownFloodZone]: false,
    [ThanksPageReasons.uploadedDocs]: true,
    [ThanksPageReasons.uploadedFloodInsurance]: false,
    [ThanksPageReasons.uploadedHomeOwnersInsurance]: false,
    [ThanksPageReasons.acknowledgedFloodDisclosure]: false,
    [ThanksPageReasons.unsupportedCounty]: false,
    [ThanksPageReasons.uploadedCompetitorAgreement]: false,
    [ThanksPageReasons.uploadedCompetitorAgreementHeloc]: false,
    [ThanksPageReasons.missingSigners]: false,
    [ThanksPageReasons.insufficientIncome]: false,
    [ThanksPageReasons.uccSuccess]: false,
}

export const shouldDisplayButtonOnThanksPage: { [T in ThanksPageReasons]: boolean } = {
    [ThanksPageReasons.authorization]: false,
    [ThanksPageReasons.blockedApplication]: false,
    [ThanksPageReasons.closed]: false,
    [ThanksPageReasons.coAppIdentity]: false,
    [ThanksPageReasons.declinedOffer]: false,
    [ThanksPageReasons.default]: false,
    [ThanksPageReasons.disputeCoOwnerInfo]: false,
    [ThanksPageReasons.duplicate]: false,
    [ThanksPageReasons.expired]: false,
    [ThanksPageReasons.geo]: false,
    [ThanksPageReasons.hmdaFinished]: false,
    [ThanksPageReasons.noFile]: false,
    [ThanksPageReasons.homeIsTrustOrLLC]: false,
    [ThanksPageReasons.homeNotFound]: false,
    [ThanksPageReasons.homeOwnership]: false,
    [ThanksPageReasons.identity]: false,
    [ThanksPageReasons.invalidFloodZone]: false,
    [ThanksPageReasons.involuntaryLien]: true,
    [ThanksPageReasons.jodlDocument]: false,
    [ThanksPageReasons.lien]: false,
    [ThanksPageReasons.privateBrowsing]: false,
    [ThanksPageReasons.renter]: false,
    [ThanksPageReasons.resourceNotFound]: true,
    [ThanksPageReasons.review]: true,
    [ThanksPageReasons.trustNOO]: false,
    [ThanksPageReasons.trustRIN]: false,
    [ThanksPageReasons.trustUnsupportedState]: false,
    [ThanksPageReasons.unknownFloodZone]: false,
    [ThanksPageReasons.uploadedDocs]: false,
    [ThanksPageReasons.uploadedFloodInsurance]: false,
    [ThanksPageReasons.uploadedHomeOwnersInsurance]: false,
    [ThanksPageReasons.acknowledgedFloodDisclosure]: false,
    [ThanksPageReasons.unsupportedCounty]: false,
    [ThanksPageReasons.uploadedCompetitorAgreement]: false,
    [ThanksPageReasons.uploadedCompetitorAgreementHeloc]: false,
    [ThanksPageReasons.missingSigners]: false,
    [ThanksPageReasons.insufficientIncome]: false,
    [ThanksPageReasons.uccSuccess]: false,
}

export const shouldDisplayAvenAdvisorDownload: { [T in ThanksPageReasons]: boolean } = {
    [ThanksPageReasons.authorization]: false,
    [ThanksPageReasons.blockedApplication]: false,
    [ThanksPageReasons.closed]: true,
    [ThanksPageReasons.coAppIdentity]: true,
    [ThanksPageReasons.declinedOffer]: true,
    [ThanksPageReasons.default]: true,
    [ThanksPageReasons.disputeCoOwnerInfo]: false,
    [ThanksPageReasons.duplicate]: false,
    [ThanksPageReasons.expired]: true,
    [ThanksPageReasons.geo]: false,
    [ThanksPageReasons.hmdaFinished]: true,
    [ThanksPageReasons.noFile]: false,
    [ThanksPageReasons.homeIsTrustOrLLC]: true,
    [ThanksPageReasons.homeNotFound]: true,
    [ThanksPageReasons.homeOwnership]: true,
    [ThanksPageReasons.identity]: false,
    [ThanksPageReasons.invalidFloodZone]: true,
    [ThanksPageReasons.involuntaryLien]: false,
    [ThanksPageReasons.jodlDocument]: false,
    [ThanksPageReasons.lien]: true,
    [ThanksPageReasons.privateBrowsing]: false,
    [ThanksPageReasons.renter]: true,
    [ThanksPageReasons.resourceNotFound]: false,
    [ThanksPageReasons.review]: false,
    [ThanksPageReasons.trustNOO]: true,
    [ThanksPageReasons.trustRIN]: true,
    [ThanksPageReasons.trustUnsupportedState]: true,
    [ThanksPageReasons.unknownFloodZone]: true,
    [ThanksPageReasons.uploadedDocs]: false,
    [ThanksPageReasons.uploadedFloodInsurance]: false,
    [ThanksPageReasons.uploadedHomeOwnersInsurance]: false,
    [ThanksPageReasons.acknowledgedFloodDisclosure]: false,
    [ThanksPageReasons.unsupportedCounty]: true,
    [ThanksPageReasons.uploadedCompetitorAgreement]: false,
    [ThanksPageReasons.uploadedCompetitorAgreementHeloc]: false,
    [ThanksPageReasons.missingSigners]: true,
    [ThanksPageReasons.insufficientIncome]: true,
    [ThanksPageReasons.uccSuccess]: false,
}

export const shouldDisplayContinueApplicationThanksPage: { [T in ThanksPageReasons]: boolean } = {
    [ThanksPageReasons.authorization]: true,
    [ThanksPageReasons.blockedApplication]: false,
    [ThanksPageReasons.closed]: false,
    [ThanksPageReasons.coAppIdentity]: false,
    [ThanksPageReasons.declinedOffer]: false,
    [ThanksPageReasons.default]: false,
    [ThanksPageReasons.disputeCoOwnerInfo]: false,
    [ThanksPageReasons.duplicate]: false,
    [ThanksPageReasons.expired]: false,
    [ThanksPageReasons.geo]: false,
    [ThanksPageReasons.hmdaFinished]: false,
    [ThanksPageReasons.noFile]: false,
    [ThanksPageReasons.homeIsTrustOrLLC]: false,
    [ThanksPageReasons.homeNotFound]: false,
    [ThanksPageReasons.homeOwnership]: false,
    [ThanksPageReasons.identity]: false,
    [ThanksPageReasons.invalidFloodZone]: false,
    [ThanksPageReasons.involuntaryLien]: false,
    [ThanksPageReasons.jodlDocument]: false,
    [ThanksPageReasons.lien]: false,
    [ThanksPageReasons.privateBrowsing]: false,
    [ThanksPageReasons.renter]: false,
    [ThanksPageReasons.resourceNotFound]: false,
    [ThanksPageReasons.review]: false,
    [ThanksPageReasons.trustNOO]: false,
    [ThanksPageReasons.trustRIN]: false,
    [ThanksPageReasons.trustUnsupportedState]: false,
    [ThanksPageReasons.unknownFloodZone]: false,
    [ThanksPageReasons.uploadedDocs]: false,
    [ThanksPageReasons.uploadedFloodInsurance]: false,
    [ThanksPageReasons.uploadedHomeOwnersInsurance]: false,
    [ThanksPageReasons.acknowledgedFloodDisclosure]: false,
    [ThanksPageReasons.unsupportedCounty]: false,
    [ThanksPageReasons.uploadedCompetitorAgreement]: true,
    [ThanksPageReasons.uploadedCompetitorAgreementHeloc]: true,
    [ThanksPageReasons.missingSigners]: false,
    [ThanksPageReasons.insufficientIncome]: true,
    [ThanksPageReasons.uccSuccess]: true,
}
