import { CountryCode, parsePhoneNumber } from 'libphonenumber-js'

// keep in sync with aven_e2e_tests/playwright/avenTests/utils/avenIntegrationProdTestData.ts
export const optOutPhoneNumber = '(415) 610-8313'

export const getCountryCode = (phoneNumber: string, country: CountryCode = 'US'): string => {
    const parsedPhoneNumber = parsePhoneNumber(phoneNumber, country)

    return parsedPhoneNumber.countryCallingCode
}

export const getPhoneNumberInE164Standard = (phoneNumber: string, country: CountryCode = 'US'): string => {
    const parsedPhoneNumber = parsePhoneNumber(phoneNumber, country)

    return parsedPhoneNumber.format('E.164')
}
