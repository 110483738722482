import { contact_info } from '@/utils/contact-info-dictionary'
import { ESTIMATED_CARD_MAIL_ARRIVAL_BUSINESS_DAYS, INSTALLMENT_PLAN_APR_MOD } from '@/utils/constants'
import { StatedUrgencyType, StatedUsageType } from 'aven_types'
import { optOutPhoneNumber } from '@/utils/phoneNumber'

const shared = {
    cardsArrangedBy:
        'Aven cards are arranged by Aven Financial, Inc., dba "Aven" or "AvenCard" (in AR, ID, and PA). NMLS #2042345. See <a class="tw-no-underline" href="/licenses">aven.com/licenses</a> for state specific details. Aven reserves the right to modify or discontinue its products or offerings at any time without notice.',
    cardIssuedBy:
        // If you update this language, be sure to update personalInfoPageCoastal in aven_e2e_tests/playwright/avenTests_prod/origination.spec.ts
        'The Aven Card is issued by Coastal Community Bank, pursuant to a license from Visa U.S.A., Inc. Aven accounts are made by Coastal Community Bank, Member FDIC. Equal Housing Lender. NMLS #462289 (<a class="text-decoration-underline" target="_blank" rel="noopener noreferrer" href="https://nmlsconsumeraccess.org/EntityDetails.aspx/COMPANY/462289">NMLS Consumer Access Page</a>). For additional information or complaints to Coastal Community Bank, visit <a class="tw-no-underline" target="_blank" rel="noopener noreferrer" href="https://www.federalreserveconsumerhelp.gov/">www.federalreserveconsumerhelp.gov</a>.',
    secondLienDisclosure:
        'If you have an existing second lien (“Second Lien”) on your subject property, you acknowledge and agree that we may transfer your application to our refinance product (subject in all cases to credit approval). The refinance product may have different terms and conditions than the initial product you applied for. Please review the Early HELOC Disclosure for more information.',
    forLicensingInformation: 'For licensing information, go to <a class="text-decoration-underline" href="https://www.nmlsconsumeraccess.org" target="_blank">www.nmlsconsumeraccess.org</a>',
    cardAllowAccessUpTo: 'Your Aven Card will allow you to access up to {revolvingShadowLimitCap} of your available line as you pay down your balance.',
    purchasesPlacedOnRevolvingBalance: 'Purchases on the Aven Card will be placed on your revolving balance.',
}

export const ENGLISH_TRANSLATIONS = {
    pages: {
        marketing: {
            // Landing
            landing: {
                description: 'Unlock the power of home equity to get insanely low credit card rates. Stop worrying about promo rates expiring. Save every single month, forever.',
                comingSoon: 'The Credit Card Reinvented',
                titleHtml: 'Our Lowest Rate<br/>5.9 - 9.9% APR<sup>1</sup>',
                subTitleHtml: 'A credit card to unlock home equity.<br/>$0 annual fee. $0 application fee.<br/>3 minutes to sign up.',
                cta: 'Check your offer<sub>†</sub>',
                ctaNotInvited: 'Request Waitlist Invite',
                phoneNumberPlaceholder: 'Enter your phone number',
                newSafeCreditScore: 'Checking your offer will <span class="text-underline">NOT</span> affect your credit score.',
                safeCreditScore: 'This will <span class="text-underline">NOT</span> affect your credit score.',
                phoneLegalText: `<sup class="sup-md">†</sup>By providing your number, you consent to automated or manual marketing messages & to <a class="h-blue highlighted" href="/docs/TermsOfUse.pdf" target="_blank">Terms of Service</a>. Consent to marketing messages is not required for any purchase. Text 'stop' to ${optOutPhoneNumber} to opt out after starting your application.`,
                phoneLegalTextWithForgotHtml: 'By providing your number, you consent to receive text messages. Data rates may apply.',
                dontHaveInviteCode: 'No Invite Code?',
                savings: {
                    titleHtml: 'Goodbye high interest rates. <br/> Hello savings.',
                    content:
                        "You shouldn't have to pay outrageous interest rates the months you don't pay your credit card bill in full. By using your car or home to secure your card, we get access to cheaper capital and we pass those savings on to you.",
                },
                intro: {
                    titleHtml: '🚀<br/>Save $1,450 <br/> Every Year',
                    subTitleHtml: 'The world’s first credit card that unlocks your home equity to reduce interest rates by up to 50% or more<sup>2</sup>',
                    savings: {
                        subTitleHtml: "Save over <strong class='text-white'>$1,450</strong> every year and over <strong class='text-white'>$120</strong> per month on interest by moving to Aven.",
                        how: '<strong>How is this calculated?</strong><br>This compares the cost of carrying an $11,000 monthly balance and making the minimum payments of 1% principal plus interest with a 20.24% APR Credit Card versus a 6.99% APR with Aven.',
                    },
                },
                steps: {
                    titleHtml: 'Get Smarter About Your Credit Card Debt',
                    p1Html: 'Put money back in your pocket by unlocking your home equity. Get historically low interest rates and stop wasting money on high interest credit cards.',
                    one: {
                        title: '1) Accept Your Invitation',
                        subTitle: 'Quickly join the waitlist by using your personal invitation code and then verifying your identity',
                    },
                    two: {
                        title: '2) Unlock Your Home Equity',
                        subTitle: "Once we launch, we'll use our patent-pending technology to unlock your home equity in minutes, not weeks – so you can get low interest rates!",
                    },
                    three: {
                        title: '3) Start Saving With Your Card',
                        subTitle: 'With your new Aven card, stop worrying about high interest charges or your “0% Intro Rate” expiring. Focus simply on savings. Forever.',
                    },
                },
                cardDetails: {
                    title: 'Money In Your Pocket Every Month',
                    apr: {
                        title: 'APR Range',
                        contentHtml: '5.9% – 9.9%<sup>1</sup>',
                    },
                    creditLine: {
                        title: 'Credit Lines',
                        content: '$5,000 – $35,000',
                    },
                    rewards: {
                        title: 'Rewards',
                        content: '1.5% Cash Back',
                    },
                    originationFee: {
                        title: 'Origination Fee',
                        content: '$0',
                    },
                    annualFee: {
                        title: 'Annual Fee',
                        content: '$0',
                    },
                    balanceTransferFee: {
                        title: 'Annual Balance Transfer',
                        content: 'Still $0 Fee',
                    },
                },
                how: {
                    titleHtml: 'As simple as a credit card. As cheap as home equity.',
                    content:
                        'The best of both worlds.<br/><br/>Aven is the first credit card that takes into account the equity you’ve built up in your home. <br/><br/>With our patented technology, we can unlock your home equity in minutes (not weeks) and help you start saving right away.',
                },
                faq: {
                    titleHtml: 'Have questions? <br /> We have answers.',
                    subTitle: 'We want to make sure you have all the answers before you start.',
                },
                allTheCardFeatures: {
                    title: 'All the credit card features you love',
                    featureOne: 'Free credit score tracking',
                    featureTwo: 'Auto line-increase reviews',
                    featureThree: 'US-based customer service',
                    featureFour: 'Simple web & mobile app',
                },
                balanceTransfer: {
                    titleHtml: 'Cut your high interest credit card debt in half - for $0 fees<sup>1</sup>',
                    subTitleHtml:
                        "You could potentially save over 50% in interest alone by transferring your existing high interest credit card debt to the low interest Aven Card<sup>2</sup></sup>  <br /><br />  No balance transfer fee.<br/>No origination fee.<br/>No introductory gimmicks.<br/><strong class='text-dark'>Just simple savings.</strong>",
                },
                reengage: {
                    titleHtml: 'Got an invite? <br/> Join the waitlist.',
                    subTitle: 'These are big decisions and we want to make sure you feel comfortable.',
                    cta: 'Join Limited Waitlist',
                    messageUs: 'Send Us a Message',
                    contactInfoHtml: `The easiest way to get in touch with us is to send us an email.`,
                },
                support: {
                    titleHtml: 'Still have questions? <br/> Happy to chat.',
                },
                error: {
                    notValidPhoneNumber: 'Invalid US Number',
                },
                reasons: {
                    title: 'Loans can be good, when used for the right reason.',
                    consolidate: {
                        title: 'Consolidate Debt',
                        body: 'Simplify your finances by paying down loan balances or credit cards. Reduce the stress of multiple bills, at a lower interest rate than most credit cards.',
                    },
                    improvements: {
                        title: 'Improvements',
                        body: 'Improving your home can increase the value and comfort of your home. With a loan, you can pay for your kitchen makeover or a new roof over a longer time period, instead of one large bill.',
                    },
                    medical: {
                        title: 'Medical Expense',
                        body: 'A loan can help cover your unexpected hospital bills, dental work or other medical expenses for you or a loved one, when you need it most.',
                    },
                    relocation: {
                        title: 'Relocation',
                        body: 'Personal loans can help manage your moving costs by spreading out the payments over time, instead of one large bill all at once.',
                    },
                },
                bounceModal: {
                    title: 'What do you think about the Aven Card?',
                    thankYou: 'Thank you for your feedback!',
                    thankYouSubTitle: 'This really helps us improve and build a better product for you.',
                    option1: 'I don’t want to use my home equity',
                    option2: 'Rates not low enough',
                    option3: 'I want it!',
                },
                comparisonCard: {
                    title: 'Save up to 50% interest.<br>Every month.<sup><a href="#footnote_2">2</a></sup>',
                    subTitle:
                        'Stop paying thousands a year in interest from your high interest debt, like a credit card or personal loan. See the savings on a {balanceText} balance if paying off over {yearText} years.',
                },
                pifFacebookShare: {
                    ogTitle: 'Aven • Get $400 in Statement credit when you Sign Up!',
                },
                travelRewardsV0: {
                    ogTitle: 'Aven • 2 Round-trip Tickets to Las Vegas!',
                },
                travelTicketsBahamas: {
                    ogTitle: 'Aven • 2 Round-trip Tickets to the Bahamas!',
                },
                dripCommunicationLanding: {
                    ogTitle: 'Credit Card in front. HELOC in back.',
                },
            },

            // Landing
            landingReduceCardBills: {
                description: 'The first smart credit card that uses your home equity or car value to get you the lowest rates.',
                comingSoon: 'The Credit Card Reinvented',
                titleHtml: 'Reduce Credit<br />Card Bills',
                subTitleHtml: '5.9% to 9.9% APR<sup>1</sup><br />Credit Card Backed by Home Equity<br />No Annual or Balance Transfer Fees<br />Only 3 Minutes to Sign Up',
                cta: 'Join the Waitlist',
                phoneNumberPlaceholder: 'Enter your phone number',
                phoneLegalText: 'By providing your number, you consent to receive text messages. Data rates may apply.',
                dontHaveInviteCode: 'No Invite Code?',
                savings: {
                    titleHtml: 'Goodbye high interest rates. <br/> Hello savings.',
                    content:
                        "You shouldn't have to pay outrageous interest rates the months you don't pay your credit card bill in full. By using your car or home to secure your card, we get access to cheaper capital and we pass those savings on to you.",
                },
                intro: {
                    titleHtml: '💸<br/>Reduce your credit <br/>card bill every month',
                    subTitleHtml: 'The world’s first credit card that unlocks your home equity to reduce interest rates by up to 50%<sup>2</sup>',
                    savings: {
                        subTitleHtml:
                            "Save over <strong class='text-white'>$3,792</strong> in 3 years on interest payments and save <strong>35%</strong> or more on your monthly credit card bill by moving to Aven.",
                        how: '<strong>How is this calculated?</strong><br>This compares the cost of carrying an $11,000 monthly balance with a 20.24% APR Credit Card versus a 6.99% APR with Aven and paying 1% principal + fees per month. Your monthly bill goes from $294 to $174.08.',
                    },
                },
                steps: {
                    titleHtml: 'As simple as a credit card. As cheap as home equity.',
                    p1Html: 'Save money by unlocking your home equity.<br /><br />Get historically low interest rates and stop wasting money on high interest credit cards.',
                    one: {
                        title: '1) Join the Waitlist',
                        subTitle: 'Verify your identity with some personal information.',
                    },
                    two: {
                        title: '2) Unlock Your Home Equity',
                        subTitle: 'Use our patented technology to unlock your home equity in minutes.',
                    },
                    three: {
                        title: '3) Start Saving',
                        subTitle: 'With your new Aven card, stop worrying about high interest charges or your “0% Intro Rate” expiring.',
                    },
                },
                cardDetails: {
                    title: 'Cut your interest rate by up to 50%<sup>2</sup>',
                    apr: {
                        title: 'APR',
                        contentHtml: '5.9% to 9.9% APR<sup>1</sup>',
                    },
                    creditLine: {
                        title: 'Credit Limits',
                        content: '$5,000 – $35,000',
                    },
                    annualFee: {
                        title: 'Annual Fee',
                        content: '$0',
                    },
                    originationFee: {
                        title: 'Origination Fee',
                        content: '$0',
                    },
                    balanceTransferFee: {
                        title: 'Annual Balance Transfer',
                        content: 'Still $0',
                    },
                },
                how: {
                    titleHtml: 'How does it work? By easily unlocking your home equity.',
                    content:
                        "Aven is a credit card that takes into account the equity you've built up in your home.<br /><br />With our innovative technology, we can unlock your home equity in minutes (not weeks) and help you start saving right away.",
                },
                faq: {
                    titleHtml: 'Have questions? <br /> We have answers.',
                    subTitle: 'This card is not for everyone, so we want to make sure you have all the answers before you start. ',
                },
                allTheCardFeatures: {
                    title: 'All the credit card features you love',
                    featureOne: 'Free credit score tracking',
                    featureTwo: 'Auto line-increase reviews',
                    featureThree: 'US-based customer service',
                    featureFour: 'Simple web & mobile app',
                },
                noneOfGimmicks: {
                    title: 'With none of the gimmicks you hate.',
                    subTitleHtml:
                        'No high interest rates - save over 50% in interest alone by transferring your existing high interest credit card debt to the low interest Aven Card.<sup>2</sup><br /><br />No balance transfer fee.<br />No origination fee.<br />No introductory gimmicks.<br /><strong>Just simple savings.</strong>',
                },
                reengage: {
                    titleHtml: 'Got an invite? <br/> Join the waitlist.',
                    subTitle: 'These are big decisions and we want to make sure you feel comfortable.',
                    cta: 'Join Limited Waitlist',
                    messageUs: 'Send Us a Message',
                    contactInfoHtml: `You can also email us at <a href='mailto:${contact_info.support_email}'>${contact_info.support_email}</a> or text us at <a href='sms:${contact_info.sms}'>${contact_info.sms}</a>`,
                },
                support: {
                    titleHtml: 'Still have questions? <br/> Happy to chat.',
                },
                error: {
                    notValidPhoneNumber: 'Invalid US Number',
                },
                reasons: {
                    title: 'Loans can be good, when used for the right reason.',
                    consolidate: {
                        title: 'Consolidate Debt',
                        body: 'Simplify your finances by paying down loan balances or credit cards. Reduce the stress of multiple bills, at a lower interest rate than most credit cards.',
                    },
                    improvements: {
                        title: 'Improvements',
                        body: 'Improving your home can increase the value and comfort of your home. With a loan, you can pay for your kitchen makeover or a new roof over a longer time period, instead of one large bill.',
                    },
                    medical: {
                        title: 'Medical Expense',
                        body: 'A loan can help cover your unexpected hospital bills, dental work or other medical expenses for you or a loved one, when you need it most.',
                    },
                    relocation: {
                        title: 'Relocation',
                        body: 'Personal loans can help manage your moving costs by spreading out the payments over time, instead of one large bill all at once.',
                    },
                },
            },
            // About
            about: {
                sectionHeader: 'Company',
                titleHtml: 'Bye high interest.<br/> Hello, Aven.',
                subTitle: 'Our mission is to provide the lowest cost, most convenient, and most transparent access to capital',
                principles: {
                    simple: {
                        title: 'Simple',
                        content:
                            'We believe that simple, clear, and honest are what make good companies great and long lasting. We want customers who genuinely want to use our products because it makes their lives better and easier.',
                    },
                    inventive: {
                        title: 'Inventive',
                        content:
                            'We believe that innovation comes from questioning and pushing what’s possible. Our singular goal is to invent new financial products that help you save money everyday.',
                    },
                    customerObsessed: {
                        title: 'Customer Obsessed',
                        content:
                            ' We believe that Aven is only as good as you think we are. We’re super focused on making sure our products are valuable to you. If we don’t think our product will help you - we’ll tell you today, then get to work on making sure it will tomorrow.',
                    },
                },
                leadership: 'Team',
                investors: 'Investors',
                bod: 'Board of Directors',
                dogs: 'Team Dogs',
            },
            // Reviews
            reviews: {
                title: 'Trusted by homeowners',
                subTitle: 'Our goal is to give you the most simple, secure, fast, and lowest cost access to your capital you can get.',
            },
            // Support
            support: {
                sectionHeader: 'Support',
                title: 'Questions? <br/> We are here.',
                subTitle: 'Get answers to frequently asked questions, explore financial resources or chat with one of our support teammates.',
                faq: 'Frequently Asked Questions',
                chatCta: 'Start Chat',
                resources: 'Resources',
                creditFacts: '',
                chat: {
                    title: 'Chat With Us',
                    cta: 'Send Us a Message',
                    contentHtml: `The simplest way to get in touch with us is to just <a href='sms:${contact_info.sms}'>text us</a>. You can also email us at <a href='mailto:${contact_info.support_email}' />${contact_info.support_email}</a> or text us at <a href='sms:${contact_info.sms}' />${contact_info.sms}</a>`,
                },
                otherContacts: `You can also email us at <a href='mailto:${contact_info.support_email}'>${contact_info.support_email}</a> or text us at <a href='sms:${contact_info.sms}'>${contact_info.sms}</a>`,
            },

            // MobileApps
            mobileApps: {
                sectionHeader: 'Aven Card App',
                title: 'Get the app',
                subTitle: 'Download the Aven Card app to easily view and manage your card. Available for iPhone and Android.',
                iphone: 'iPhone',
                android: 'Android',
            },

            // Licenses
            licenses: {
                title: 'Licenses',
            },

            // Contact
            contact: {
                sectionHeader: 'Contact Us',
                title: 'Get in touch',
                subTitle: `The easiest way to get in touch with us is to send us an email.`,
                message: 'Send Us A Message',
                call: 'Call Us',
            },

            // Careers
            careers: {
                title: 'Work at Aven. The future of consumer finance.',
                subTitle: 'We are looking for great people to join us in our mission to provide the lowest cost, most convenient, and most transparent access to capital.',
                positionsTitle: 'View Open Positions',
                emailUsHtml: `Email us at <a href='mailto:${contact_info.job_email}'>${contact_info.job_email}</a> to get in touch. We would love to chat with you.`,
            },

            // Privacy
            privacy: {
                header: 'Privacy',
                title: 'Privacy Matters',
                subTitle: 'We value your privacy and want you to know how we collect, share, and use information.',
                dataUse: 'Privacy Policy',
                consumerPrivacy: 'Aven Consumer Privacy Notice',
                ccbPrivacy: 'Coastal Community Bank Privacy Notice',
            },

            // Important Disclosures
            importantDisclosures: {
                header: 'Disclosures',
                title: 'All the Nitty Gritty',
                subTitle: 'We want to make sure that you understand our products and have easy access to our disclosures.',
                earlyHelocDisclosure: 'HELOC Application Disclosure',
                eSignConsent: 'E-SIGN Consent',
                helocBooklet: 'HELOC Brochure',
                mobileBankingPolicy: 'Digital Services Agreement',
                charmHandbook: 'CFPB Charm Handbook',
            },

            // Payment Calculator
            paymentCalculator: {
                title: 'Estimate Your Monthly Payment',
                subTitle:
                    "Unlock Aven's low rates with your home equity. Enter your phone number to access our custom calculator to estimate your monthly payments and discover how low your monthly payments can be.",
                subTitle2: 'Don’t have an offer? Enter your phone number to receive one in as fast as 15 minutes.',
                modalSubtitle: 'To continue to your payment calculator, please confirm your date of birth.',
                sectionHeader: 'Payment Calculator',
                redrawSectionHeader: 'Redraw Payment Calculator',
                paybackMethodSectionHeader: 'Pick a Payback Option',
                chartSectionHeader: 'Monthly Payment',
                cardBalanceInputPlaceholder: 'Card Balance',
                cardAPRInputPlaceholder: 'Card APR',
                balanceInputPlaceholder: 'Balance',
                aprInputPlaceholder: 'APR',
                fixedMonthlyPlanRadioTitle: 'Fixed Monthly Payments',
                fixedMonthlySubtitle: 'Estimate your monthly payment for all subsequent cashouts and balance transfers.',
                fixedMonthlyPlanRadioBody: `Lock in a fixed payment each month for 5 or 10 years.`,
                standardPlanRadioTitle: 'Standard Credit Card Payments',
                standardPlanRadioBody: 'Pay the minimum of 1% principal, plus interest, or more.',
                fixedInstallmentNotAvailableTitle: 'Why Is Fixed Not Available?',
                fixedInstallmentNotAvailableText:
                    'Your APR for our fixed plan exceeds the High-Cost Mortgage limit set by regulation (CFPB § 1026.32). If the Fed lowers interest rates, this feature may be available in the future.',
                payItDownFasterTitle: 'Can I Pay It Down Faster?',
                payItDownFasterText: `Yes, absolutely! You can always pay more than your minimum payment, and there are <strong class="text-underline">no prepayment penalties</strong>`,
                minPaymentBody: 'See <a href="/public/docs/EarlyHELOCDisclosure" target="_blank" >Min Payment Example</a> for repayment using just a minimum payment.',
                isRateDifferentForFixedTitle: `Is The Rate Different For Fixed?`,
                isRateDifferentForFixedBody: `Your interest rate for fixed monthly payments (Aven Simple Loan) is {fixedMonthlyPaymentApr}%, which is calculated using your current card APR + a ${INSTALLMENT_PLAN_APR_MOD}% premium to lock in the fixed monthly payment`,
                howIsItCalculatedTitle: 'How Are Monthly Payments Calculated?',
                howIsItCalculatedBody:
                    `<p>Your fixed monthly payment is made up of a principal payment plus interest.` +
                    ` The Interest is calculated monthly using a standard interest calculator at {fixedMonthlyPaymentApr}% (your current Aven Card APR + a ${INSTALLMENT_PLAN_APR_MOD}% premium).` +
                    ` The principal is made up of the remaining amount (Fixed Monthly Payment - Interest)</p>`,
                differentPaymentsTitle: 'Could My Payments Be Different?',
                differentPaymentsBody: `Depending on when you initiate your fixed monthly payment (Aven Simple Loan), it could vary slightly from the number above (usually very little) because of when your billing cycle begins and ends.`,
                loading: 'Crunching Numbers...',
                fixedPlanMonthlyPrincipalPayment: '${principal} Principal',
                fixedPlanMonthlyFinanceCharges: '${financeCharge} Fixed Installment Charge',
                fixedPlanNotAnOptionTitle: 'When Are Fixed Monthly Payments Not An Option?',
                fixedPlanNotAnOptionBody:
                    'There are circumstances when fixed monthly payments are not available. This depends on your APR, which may exceed the High-Cost Mortgage limit set by the federal government with a fixed plan. If interest rates decrease, then fixed monthly plans are more likely to be available.',
                phone: 'Mobile Number',
                cta: 'Get Estimate',
                ctaConfirm: 'Continue',
            },

            pifForAllLanding: {
                heroText: 'Get your exclusive invite link',
                heroSubText:
                    'Create your invite link and share with your friends, family, or followers. When someone uses your link, your banner appears on our site. Plus they get a free {formattedDollarAmount} on their card – paid by us!',
                howItWorks: {
                    title: 'How it works',
                    pointOne: {
                        title: 'Create your invite link',
                        body: 'Fill out a short form with your information and instantly get your exclusive invite link.',
                    },
                    pointTwo: {
                        title: 'Share your exclusive link with your network',
                        body: 'Share or post your exclusive link with friends, family, followers, clients or network – anyone!',
                    },
                    pointThree: {
                        title: 'You get all the recognition and they get {formattedDollarAmount}.',
                        body: 'Your banner appears at the top of our landing page and they get {formattedDollarAmount} on their Aven card (paid by us) when they join.',
                    },
                },
                whatItIs: {
                    title: '{formattedDollarAmount} from you.<br>Paid by us.',
                    body: 'Aven is a fantastic product for any homeowner, but now, when you refer them they get a special sign-up bonus from you. We pay for it, but you get the recognition.',
                },
            },
            testimonials: {
                testimonials: 'TESTIMONIALS',
                title: 'Real Voices.<br/>Real Customers.',
            },
        },
        avenCashV2CreditCardExplainer: {
            title: 'Also get an Unlimited {formattedCashbackPct} cashback Visa<sup>&reg;</sup> credit card',
            subTitle: 'It works like a regular HELOC, but also gives you access to your funds in the form of a Visa<sup>&reg;</sup> Signature card',
            cashBackVisaCardTitle: '{formattedCashbackPct} Unlimited Cashback Visa Card',
            cashBackVisaCardCopy:
                'Get a card that earns you unlimited {formattedCashbackPct} cashback on every single purchase at the same APR as your HELOC. Use what you want, when you want. Yes, even on that coffee and doughnut.',
            noAnnualFeeTitle: '$0 - No Annual Fees',
            noAnnualFeeCopy: 'Unlike most credit cards in the market, the Aven Card does not have annual fees or account closing fees.',
            lowAprTitle: 'Same Low APR as your HELOC. Save up to 50% interest every month',
            lowAprCopy:
                "Compared to other cards, you'll stop paying thousands a year in interest from your high interest debt, like a credit card or personal loan. Other credit cards have an average of 23.44% APR.",
        },
        // Make sure to also update getFaqExtraContent in aven_backend/src/manager/communication/messageTemplates/messageTemplates.ts when changing this
        education: {
            howItWorksTitle: 'How It Works',
            howItWorksSubtitle: 'It works like a regular Visa<sup>&reg;</sup> Credit Card, but taps into your home equity to get you really, really low rates.',
            homeEquityLineOrCreditCardTitle: 'Is this a home equity line or a credit card?',
            homeEquityLineOrCreditCardSubtitle:
                'Aven serves as both a home equity line of credit and a credit card, providing homeowners with flexible financing options for their financial needs. It combines the benefits of tapping into home equity and the convenience of a credit card in a single financial product.',
            whatTitle: 'What is the Aven Home Card?',
            whatSubtitle: 'Aven is a credit card that lets you use your home equity to get really low rates.',
            whatSubtitleAvenCashV2: 'With your home equity, you get really low rates for your Aven Card.',
            howTitle: 'How does it work?',
            howSubtitle:
                'Aven combines the convenience of a credit card with the savings of a home equity line of credit (HELOC). It works like any other credit card where you can make everyday purchases and earn unlimited {cashbackText} cash back.<sup><a href="#footnote_1">1</a></sup>',
            howSubtitleForHomeDepotGiftCard:
                'Aven combines the convenience of a credit card with the savings of a home equity line of credit (HELOC). It works like any other credit card where you can make everyday purchases and earn unlimited {cashbackText} cash back.<sup><a href="#footnote_1">1</a></sup> Plus, earn 5% cash back at The Home Depot<sup>&reg;</sup> on the first $1,000 spent each month.<sup><a href="#footnote_homedepot">*</a></sup>',
            howSubtitleBoostCashBack:
                'Aven combines the convenience of a credit card with the savings of a home equity line of credit (HELOC). It works like any other credit card where you can make everyday purchases and earn {boostCashbackText} Cashback on the first {boostCashbackDollarLimitPerCycle} per month, and {cashbackText} Unlimited Cashback thereafter.<sup><a href="#footnote_1">1</a></sup>',
            rateTitle: 'Do you offer a fixed rate?',
            rateSubtitle:
                'For Cash Outs, you have the option of fixed monthly payments (Aven Simple Loan). Lock in your payments for 5 or 10 years. One time {cashOutFeePercentText} fee per cash out applies. Available right in the app.<sup><a href="#footnote_4">4</a></sup>',
            rateSubtitleForTeaser:
                'During the {teaserPromoAprText} interest offer period, we do not offer fixed monthly payments (Aven Simple Loan). You may setup fixed monthly payments after the {teaserPromoAprText} period ends.',
            paymentTitle: 'What is my monthly payment?',
            paymentSubtitle:
                'Monthly payments work like a standard credit card. It is calculated as 1% of the principal balance, plus monthly interest, plus any fees. <a href="https://www.aven.com/PaymentCalculator" target="_blank" rel="noopener noreferrer">See Payment Calculator</a>.',
            cashTitle: 'Can I get cash too?',
            cashSubtitle:
                'Yes! You can directly deposit cash from your Aven card to your bank account at the same low rate. A {minCashOutFeePercentText} transfer fee applies.<sup><a href="#footnote_1">1</a></sup>',
            cashSubtitleForCOBTHigherFee:
                'Yes! You can directly deposit cash from your Aven card to your bank account at the same low rate. A {minCashOutFeePercentText} transfer fee applies.<sup><a href="#footnote_1">1</a></sup> For certain well-qualified applicants, an option for a lower APR rate for Cash Outs may be available with a {maxCashOutFeePercentText} fee.<sup><a href="#footnote_cobt_higher_fee">‡</a></sup>',
            creditScoreTitle: 'Does this affect my credit score?',
            creditScoreSubtitle: 'Checking your offer does not affect your credit score. Only once you schedule with our notary to finalize will we do a hard credit check.',
            feesTitle: 'Are there any fees?',
            feesSubtitle:
                'We try to reduce as many of the fees as possible. No annual, sign-up, or pre-payment fees. There is a {minCashOutFeePercentText} fee on Cash Outs and Balance Transfers and a $29 late fee if you don’t make the minimum payment.<sup><a href="#footnote_1">1</a></sup>',
            feesSubtitleForPrime:
                'We try to reduce as many of the fees as possible. No annual, sign-up, or pre-payment fees. There is a {minCashOutFeePercentText} fee on Cash Outs and Balance Transfers and a $29 late fee if you don’t make the minimum payment.<sup><a href="#footnote_1">1</a></sup> We offer a $0 annual fee option to every user, and we also offer an option with a lower APR for an annual membership fee of 1.2% of your credit line size.<sup><a href="#footnote_prime">‡</a></sup>',
            feesSubtitleForCOBTHigherFee:
                'We try to reduce as many of the fees as possible. No annual, sign-up, or pre-payment fees. There is a {minCashOutFeePercentText} fee on Cash Outs and Balance Transfers and a $29 late fee if you don’t make the minimum payment.<sup><a href="#footnote_1">1</a></sup> For certain well-qualified applicants, an option for a lower APR rate for Cash Outs may be available with a {maxCashOutFeePercentText} fee.<sup><a href="#footnote_cobt_higher_fee">‡</a></sup>',
            header: 'Education',
            title: 'Become an Expert',
            subtitle: 'Grow your knowledge with our educational articles written by our team of experts.',
            homeEquityTitle: 'Why not get a traditional home equity line of credit instead?',
            homeEquitySubtitle:
                'Home equity lines of credit often come with high fees (usually over $1000), minimum advances, minimum credit limits (often at least $50,000), and take 4-6 weeks to close.<sup><a href="#footnote_3">3</a></sup> With Aven, you can get your card in just a few days in the mail, you can use it any number of times for any amount under your limit, with no origination or annual fees. Best of all, you get cash back on your purchases.<sup><a href="#footnote_1">1</a></sup>',
            homeEquitySubtitleForPrime:
                'Home equity lines of credit often come with high fees (usually over $1000), minimum advances, minimum credit limits (often at least $50,000), and take 4-6 weeks to close.<sup><a href="#footnote_3">3</a></sup> With Aven, you can get your card in just a few days in the mail, you can use it any number of times for any amount under your limit, with no origination fee. Best of all, you get cash back on your purchases.<sup><a href="#footnote_1">1</a></sup>',
            homeEquitySubtitleBoostCashback:
                'Home equity lines of credit often come with high fees (usually over $1000), minimum advances, minimum credit limits (often at least $50,000), and take 4-6 weeks to close.<sup><a href="#footnote_3">3</a></sup> With Aven, you can get your card in just a few days in the mail, you can use it any number of times for any amount under your limit, with no origination or annual fees. Best of all, you get {boostCashbackText} Cashback on the first {boostCashbackDollarLimitPerCycle} per month, and {cashbackText} Unlimited Cashback thereafter.<sup><a href="#footnote_1">1</a></sup>',
        },
        educationV2: {
            whatTitle: 'What is the Aven Card?',
            whatContent: 'Aven Card is a credit card that lets you use your home equity to get really low rates.',
            howTitle: 'How does Aven Card work?',
            howContent:
                'The Aven Card combines the convenience of a credit card with the savings of a home equity line of credit (HELOC). It works like any other credit card where you can make everyday purchases and earn unlimited {cashbackRatio} cash back.<sup><a href="#footnote_1">1</a></sup>',
            rateTitle: 'Do you offer a fixed rate?',
            rateContent: 'For Cash Outs, we offer fixed monthly payments (Aven Simple Loan). Transfer fee may apply. Available right in the app.<sup><a href="#footnote_1">1</a></sup>',
            cashTitle: 'Can I get cash too?',
            cashContent: 'Yes! You can directly deposit cash to your bank account at the same low rate. Transfer fee may apply.<sup><a href="#footnote_1">1</a></sup>',
            creditScoreTitle: 'Does this affect my credit score?',
            creditScoreContent: 'Checking your offer does not affect your credit score. Only once you schedule with our notary to finalize will we do a hard credit check.',
            feesTitle: 'Are there any fees?',
            feesContent:
                'We try to reduce as many of the fees as possible. No annual or pre-payment fees. There may be a fee on Cash Outs and Balance Transfers and a $29 late fee if you don’t make the minimum payment by the due date.<sup><a href="#footnote_1">1</a></sup>',
            variableRateTitle: 'Is the rate variable?',
            variableRateContent:
                'For Cash Outs we may offer fixed monthly payments (Aven Simple Loan).<sup><a href="#footnote_3">3</a></sup> Otherwise, the rate is variable. It will vary based on the FFTR-UL as published in the Wall Street Journal, outside Aven\'s control. As the FFTR-UL shifts up or down, so will the APR on the Aven Card and any other variable-rate credit cards, lines, loans. The FFTR-UL may change several times a year.<sup><a href="#footnote_1">1</a></sup>',
            qualifyTitle: 'What are the criteria to qualify for an Aven Card?',
            qualifyContent:
                '1) Own and have equity in your home. 2) Have an income that can support your monthly bill – we don’t want you to get into debt that you can’t afford. 3) Meet certain credit criteria, including credit score, income, and 4) Property type and value requirements.',
        },
        survey: {
            thankYou: {
                title: 'Thank You',
                subTitle: 'We really appreciate your feedback',
                message: `Your feedback helps us improve our products, so that we can better support our customers.</br></br>If you have time, we'd love to understand more from you. Text ${contact_info.sms} to chat and we'll give you a $15 Amazon gift card.`,
                sendTextMsg: 'Send Us a Message',
            },
            AccountClosureReasonsSurvey: {
                title: 'What is the main reason you are closing your account?',
                thanks: 'Thank you for your feedback. It really helps us build a better product for you.',
            },
            WithdrawOrDeclineReasonsSurvey: {
                title: 'What is the main reason you are no longer proceeding with Aven?',
                thanks: 'Thank you for your feedback. It really helps us build a better product for you.',
            },
        },
        auth: {
            signIn: {
                title: 'Sign in to your account',
                placeholder: 'Enter phone number',
                cta: 'Sign In',
            },
            signInConfirm: {
                title: 'Confirmation code sent to',
                cta: 'Continue',
                placeholder: 'Confirmation code',
            },
            error: {
                notValidPhoneNumber: 'Not a valid phone number',
                codeNotFound: 'Invalid invite code',
                codeExpired: 'Your invite code has expired',
                wrongExperiment: "Looks like you're on the wrong page. Please go to aven.com and try again",
            },
        },
        identity: {
            title: 'Sorry, we could not verify your identity. Please double check the information below.',
        },
        identityQuestions: {
            title: 'In order to fully verify your identity, please answer the following questions',
            titleWithCoApplicant: `{firstName}, please confirm the following information about yourself`,
            titleUcc: 'Before signing and for your security, please confirm the following',
        },
        origination: {
            irsForAll: {
                title: "Let's automatically try to verify your income. We'll make a one-time IRS request to get your latest tax return.",
            },
            checkOffers: {
                title: 'Check your personalized offer with no impact to your credit score.',
            },
            personalInfo: {
                title: 'Great, we just need the basics...<br>This <span class="text-underline">won’t</span> affect your credit score.',
                noInviteTitle: 'Great, we just need the basics...<br>This <span class="text-underline">won’t</span> affect your credit score.',
                noInviteTitleWithCoApplicant: 'Great, we just need the basics...<br>This <span class="text-underline">won’t</span> affect your credit score.',
                uccTitle: 'Welcome! Let’s start with the basics...<br>This <span class="text-underline">won’t</span> affect your credit score.',
                previewTitle: `<br>All set {firstName}, please confirm the following information.</br>`,
                dobPlaceholder: 'Date of Birth MM/DD/YYYY',
                maritalStatus: {
                    question: 'What is your marital status?',
                    married: 'Married',
                    unmarried: 'Unmarried (Single/Divorced/Widowed)',
                    separated: 'Separated',
                },
                missingSecondarySignerRelationship: {
                    notListedHere: 'None of the above',
                },
            },
            personalPropertyAttestation: {
                legal: 'Your asset pledge, secured by a UCC-1 lien, is limited to all of the following (whether currently owned or later purchased): Jewelry (excluding wedding rings), electronic entertainment equipment (excluding one television and one radio), and antiques (items over 100 years old), and any insurance proceeds on these items.',
            },
            propertyVerification: {
                title: 'Please take a photo or upload one of the following documents to verify that you own the home.',
                pickOne: 'PICK ONE',
                cta: 'Continue',
                documentData: 'Be sure the document contains:',
                utilityBill: {
                    button: 'Utility Bill',
                    upload: 'Add Utility Bill',
                    errorSelecting: 'A utility bill file was not selected. Please click on the button to upload a utility bill.',
                    errorDuplicate: 'You have already selected this utility bill to be uploaded. Choose a different file or click continue.',
                    errorText: 'There was a problem uploading your utility bill. Please try again (note: refreshing the page may help).',
                },
                insurance: {
                    button: 'Insurance',
                    upload: 'Add Insurance',
                    errorSelecting: 'An insurance document was not selected. Please click on the button to upload an insurance document.',
                    errorDuplicate: 'You have already selected this insurance document to be uploaded. Choose a different file or click continue.',
                    errorText: 'There was a problem uploading your insurance document. Please try again (note: refreshing the page may help).',
                },
            },
            notaryExpectations: {
                title: "Great! Here's what to expect next:",
                steps: {
                    first: 'Select an offer that works for you.',
                    second: 'Sign online with our free notary.',
                    third: 'Get your card in the mail and activate!',
                },
            },
            floodInsuranceVerification: {
                title0: 'According to the USGS, your home is located in a Special Flood Hazard Zone. To continue we’ll need proof of flood insurance with Coastal Community Bank listed as loss payee.',
                title1: 'According to the USGS, your home is located in a Special Flood Hazard Zone {floodZone}. To continue we’ll need proof of flood insurance with Coastal Community Bank listed as loss payee.',
                title2: 'Upload your adjusted flood insurance with the information below.',
                title3: 'Upload your flood insurance with the information below.',
                proofOfFloodInsurance: 'PROOF OF FLOOD INSURANCE',
                cta: 'Continue',
                mailInfo: 'We also sent you instructions via email and a link to add documentation when you’re ready.',
                coastalInfo: {
                    name: 'Coastal Community Bank',
                    isaoa: 'ISAOA',
                    addressLine1: '548 Market St PMB #99555',
                    addressLine2: 'San Francisco, CA 94104-5401',
                },
                docInstructions: {
                    step1: 'Call your provider and add our issuing bank as loss payee to your policy',
                    step2: 'Check that your insurance covers for at least <strong>{dollarsCoverageAmount}</strong> with <strong>{maxDeductible}</strong> max deductible',
                    step3: {
                        desc: 'Make sure your insurance continues to:',
                        pt1: 'Be valid and renews annually',
                        pt2: 'Is NFIP (National Flood Insurance Protection) compliant',
                    },
                    step4: 'Add your updated flood insurance documentation below',
                    step4StandalonePage: 'Add your updated flood insurance documentation in the next page',
                    condoStep: "If your property is a condo, we'll also need your HOI document",
                },
                primaryUploadBtn: 'Flood Insurance',
                primaryCondoUploadBtn: 'Individual Flood Insurance',
                secondaryCondoUploadBtn: 'Condo Flood Policy (HOA)',
                ctaSubtitle: 'By uploading or clicking continue you agree with ',
                notInAFloodZone: {
                    title: 'Not in a flood zone?',
                    content:
                        'Our automated system uses the United States Geological Survey (USGS) to determine if your home is in a flood zone. Unfortunately, it is out of our control and we require any property they deem to be in a flood hazard area to have flood insurance. Should you disagree that you are not subject to mandatory flood insurance, please <a href="https://www.fema.gov/about/contact">contact Federal Emergency Management Agency (FEMA).</a><br><br>Refer to the <a href="https://msc.fema.gov/portal/home">FEMA flood map</a> and if you have evidence that you are not in a flood zone, send it to support&commat;aven\u002Ecom',
                },
                addAvenToPolicy: {
                    title: 'What will I need to do to add a loss payee to my policy?',
                    content:
                        'Before we can open your account, you will need to contact your insurance and add our issuing bank, Coastal Community Bank as loss payee to the policy. You may find detailed instructions above.',
                },
                providerOfChoice: {
                    title: 'Can I select the flood insurance provider of my choice?',
                    content:
                        'You may obtain insurance required in connection with your loan or extension of credit from any insurance agent, broker, or firm that sells such insurance. Your choice of insurance provider will not affect our credit decision or your credit terms. The replacement cost of the property may be lower than the coverage amount which may reduce your deductible amount.',
                },
                uploadErrors: {
                    errorSelecting: 'An insurance document was not selected. Please click on the button to upload an insurance document.',
                    errorDuplicate: 'You have already selected this insurance document to be uploaded. Choose a different file or click continue.',
                    errorFailedToUpload: 'There was a problem uploading your insurance document. Please try again (note: refreshing the page may help).',
                },
                floodLossPayeeAsContingencyExperiment: {
                    title0: 'According to the USGS, your home is located in a Special Flood Hazard Zone. To continue, we’ll need proof of active flood insurance.',
                    title1: 'According to the USGS, your home is located in a Special Flood Hazard Zone {floodZone}. To continue, we’ll need proof of active flood insurance.',
                    docInstructions: {
                        step3: 'You need to add the below info as a loss payee now, or after you sign. This must be done before activating your card.',
                    },
                    addAvenToPolicy: {
                        content:
                            'Before we can open and activate your account, you will need to contact your provider and add our issuing bank, Coastal Community Bank as loss payee to the policy. You may find detailed instructions above.',
                    },
                },
            },
            employer: {
                title: 'What is your source of income?',
                titleWithCoApplicant: 'What are your sources of income?',
                salariedOption: 'Salaried',
                retiredOption: 'Retired',
                otherOption: 'Other',
                placeholder: 'Employer',
                otherPlaceholder: 'Source Of Income',
                jobTitlePlaceholder: 'Job Title',
                cta: 'Continue',
                legal: 'By clicking Continue, you authorize us to verify your employment and your income through a consumer reporting agency, such as Equifax.',
            },
            statedRentalIncome: {
                title: 'To check your pre-qualified offer, we need your annual rental income across all your properties.',
                sectionLabel: 'YOUR INFORMATION',
                inputPlaceholder: 'Annual Rental Income (Before Taxes)',
                inputSubtext: 'Please add an amount, including 0 if not renting.',
                cta: 'Continue',
            },
            statedIncome: {
                title: 'To check your pre-qualified offer, all we need is your annual income',
                titleUcc: 'To check your pre-qualified offer, all we need is your annual income and total savings.',
                placeholder: 'Annual Income (Before Taxes)',
                loading: 'Submitting...',
                cta: 'Continue',
                legal: 'Alimony, child support, or separate maintenance income need not be revealed if you do not wish to have it considered as a basis for repaying this obligation.',
                spouseIncomeFootnote:
                    'This is for informational purposes and will not be used in underwriting or collection of this debt and by providing this information the spouse is not obligated for the debt unless mandated by state law.',
                statedSavings: {
                    placeholder: 'Total Savings (Bank, Stocks, etc.)',
                },
                ifRental: {
                    title: 'Almost there! We also need your annual income and total savings.',
                    inputSubtext: 'Enter total annual income before tax, incl. all rental earnings',
                },
            },
            statedTotalSavings: {
                title: 'How much do you have in savings? Cash, stocks, retirement, etc...',
                placeholder: 'Total Savings',
                loading: 'Submitting...',
                cta: 'Continue',
            },
            statedPrimarySpouseIncome: {
                placeholder: 'Annual Income (Optional)',
            },
            preQualification: {
                title: '<strong>Congrats, you’re pre-qualified!</strong><br>$0 to get your card. 100% online.',
                apr: 'Variable APR<sup>1</sup>',
                monthlyFee: 'Monthly Fee',
                annualFee: 'Annual Fee',
                signUpCancelFee: 'Sign Up Fee, Cancel Fee',
                recordingFee: 'County Recording Costs',
                cashBack: 'Unlimited Cashback<sup>1</sup>',
                creditLimit: 'Credit Limit',
                qualifyForUpTo: 'You qualify for up to {maxOffer}.',
                qualifyForUpToModal: 'You qualify for up to',
                changeQuestion: 'Change',
                selectPreferredOffer: 'Select your preferred offer',
                modalApr: '{apr} APR',
                modalContinue: 'Continue',
                noAnnualFee: '✓ No Annual Fee',
                noBalanaceTransferFee: '✓ No Balance Transfer Fee',
                cta: 'Continue',
                step1title: 'Really, Really Low APR',
                step1subTitle: 'Stop paying thousands a year in interest on your high-interest debt, like a credit card or personal loans.',
                step2title: 'No Annual or Origination Fees',
                step2subTitle: 'Unlike most home equity products, there is no cost to get the card. We try to avoid as many fees as possible.',
                step3title: 'No Balance Transfer Fees',
                step3subTitle: 'Transfer your high-interest debt without any fees and save big on your monthly payments.',
                legalText1: 'This is calculated using the Aven minimum payment of 1% plus principal & interest.',
                legalText2:
                    'For more information, you can also read our <a href="/privacy" target="_blank" rel="noreferrer noopener">Privacy Policy</a> and <a href="/disclosures" target="_blank" rel="noreferrer noopener">Disclosures</a>.',
                legalText3:
                    'Final approval is subject to: (a) our ability to verify your income; (b) your monthly debt to income ratio meeting our credit requirements; (c) your ability to give us a first or second mortgage lien on your residence; and (d) your property meets eligibility requirements.',
                autopayDiscount: 'Includes <strong>0.25%</strong> AutoPay discount',
                timeRemaining: '{time} Remaining',
                secureYourOffer: 'To secure your offer',
                getYourCard: 'Get Your Card',
                countyRecordingFeeNotice:
                    'County recording fee is a one-time charge by your county that we will automatically add to your balance and bill it in 12 interest-free monthly installments.',
                countyAndAttorneyRecordingFeeNotice:
                    'County & Attorney Cost is a one-time charge from your county and attorney cost, which is required by {stateName} law. It will automatically be added to your balance and billed in 12 interest-free monthly installments.',
                fullLineSizePaymentPerformanceContingency:
                    '*You must have a minimum amount due each of the first six billing periods and pay at least the minimum amount due, by the payment due date.',
                ratesForAdditionalDraws: 'Interest rates for cashouts and balance transfers will be fixed when taken. Rates for additional draws may be different. ',
                helocRefi: {
                    titleBase: 'We’ll transfer your HELOC or HELOAN balance from {lenderName} to an Aven {planYearsLength} year plan. We handle the paperwork.',
                    titleHigherOffer: '<b>Get a higher limit & {planYearsLength} year fixed.</b><br>We’ll refi your HELOC or HELOAN from {lenderName} to Aven. We handle the paperwork.',
                    creditLimit: 'Credit Limit',
                    creditLimitUpgrade: '{deltaAmt} upgrade from current HELOC',
                    transferAmount: 'Transfer Amount',
                    transferFee: 'Transfer Fee',
                    apr: 'APR',
                    cashBack: 'Cash Back',
                    new: 'New',
                    continue: 'Continue',
                },
            },
            secondLienAttestation: {
                body: 'We detected an existing HELOC or HELOAN from <b>{lenderName}</b>. Can you access more cash from it?',
                radioOptions: {
                    no: 'No',
                    yes: 'Yes',
                    noHeloc: "I don't have a HELOC or HELOAN",
                },
                weWillVerifyNote: 'Note: We will need to verify this with the county prior to account opening.',
                currentBalance: 'Current Balance',
                currentMonthlyPayment: 'Current Monthly Payment',
                cta: 'Continue',
                errorFetch: 'An error occurred while processing your request. Please try again later.',
            },
            lineDrawAprReduction: {
                body: 'Good news! You qualify for an APR reduction if you draw {formattedLineDrawAmountDollars} or more when you open your account',
                positiveCta: 'Sounds Good',
                negativeCta: 'No Thanks',
            },
            balanceTransfer: {
                legalText1:
                    '2. Our APR is {aprRangeText}. The average for other cards in the US for people with good credit is {goodCreditApr}. (Source: "{sourceArticleName}") {sourceWebsiteLinkText} on {dateUpdatedString}, showing the average rate for credit cards in the US for people with good credit is {avgGoodCreditApr} APR.',
                legalText2:
                    '3. Based on transferring $45,735 from a 14% APR personal loan (estimated APR from monthly payments on bureau data) to a 5.74% APR Aven Card. Assumes outstanding balances remains the same throughout the year.',
            },
            ssnVerification: {
                title: 'Check Offer',
                legalText: shared.cardIssuedBy,
            },
            setExpectations: {
                title: 'Great! Confirm your income so you can accept and sign the offer.',
                cta: 'Continue',
            },
            statedUsage: {
                inviteExpired: `Your invite code has expired, but that's okay. Answer a few questions to check your offer.`,
                disclaimer: 'Not available for purchase.',
                disclaimerRefinance: 'Not available for purchase.',
                usageHI: StatedUsageType.HOME_IMPROVEMENT,
                usageBT: StatedUsageType.BALANCE_TRANSFER,
                usageOther: StatedUsageType.OTHER,
            },
            statedUrgency: {
                urgencyWeek: StatedUrgencyType.WEEK,
                urgencyMonth: StatedUrgencyType.MONTH,
                urgencyYear: StatedUrgencyType.YEAR,
            },
            offerPreview: {
                title: '{firstName}, congrats on your offer!',
                subtitle: 'Your card is ready - all we need is for you to\xa0sign!',
                whyIsItDifferentIncome: 'We could not confirm your full stated income of {income}.',
                apr: 'Variable APR<sup>1</sup>',
                fixedApr: 'Fixed APR',
                fixedTerm: '{termLength} Year Payback',
                creditLimit: 'Credit Limit',
                initialCreditLimit: 'Initial Limit',
                fullCreditLimit: 'Full Limit',
                cashUpFront: 'Cash Upfront',
                fee: 'Fee',
                whichCashbackCard: 'Which <b>{cashbackPercent} Unlimited Cashback</b> card do you prefer?',
                cashBack: 'Unlimited Cashback<sup>1</sup>',
                signUpCancelFee: 'Sign Up Fee, Cancel Fee',
                monthlyFee: 'Monthly Fee',
                monthly: 'Monthly',
                monthlyPayment: 'Monthly<br>Payment',
                cta: 'Accept & Sign',
                decline: 'Decline Offer',
                uccHardpull:
                    'By clicking {acceptBtnLabel}, you certify that the information you have provided in this application is correct and authorize Aven to obtain your consumer credit report. <span class="tw-font-bold">This hard credit pull may affect your credit score.</span>',
                hardpull:
                    'By clicking {acceptBtnLabel}, you confirm your information is accurate and authorize Aven to perform a hard credit pull, which may affect your credit score. We will hard pull when you choose to sign with our online notary, or schedule an online notary appointment.',
                offerConsent: 'By pressing {acceptBtnLabel}, you certify that the information you have provided in this application is correct.',
                legalDocs: 'Please review the following documents.',
                autopayDiscount: 'Includes <strong>0.25%</strong> AutoPay discount',
                footnoteLegalText1NooV2: `Account activation is contingent on proof of occupancy or property condition, and title insurance for certain rental properties.`,
                footnoteLegalText1:
                    'Account activation is contingent on title insurance, employment verification, and hazard insurance listing Aven / Coastal Community Bank as payee (if we are the 1st lien).',
                footnoteLegalText2: shared.cardIssuedBy,
                footnoteLegalText3: shared.cardsArrangedBy,
                bonusDisclosure:
                    'Limited to a single $50 Sign Up bonus per applicant/account. Offer good for 30 minutes. Aven will provide bonus as statement credit on first billing cycle. Applicant must apply for and open an Aven Card to qualify.',
                PQv3: {
                    cta: 'Continue',
                    creditLimit: 'Credit Limit',
                    apr: 'Var. APR',
                    promoApr: 'Intro APR',
                    cashBack: 'Cashback',
                    unlimitedCashBack: 'Unlimited Cashback',
                    cashOut: 'Cash Out Fee',
                },
                cashOutUpFrontOfferCard: {
                    cashUpfront: 'Cash Upfront',
                    fee: 'Fee',
                    lineSizeTest: 'On a <strong>{formattedLineSize} Credit Card Line</strong>',
                },
                countyRecordingCostNotice:
                    'The one-time {effectiveCost} County Recording Cost will be added to your balance and billed as 12 interest-free monthly payments of ${effectiveCostMonthly}.',
                teaserBannerText: '<b>{teaserPromoApr} Intro APR for {teaserPromoDurationInMonth} months</b>',
                primeV3BannerText: {
                    // Stick means that the language describes a penalty: If you don't draw this much, your APR goes up
                    stick: '<b>Get this APR</b> if you draw {minDrawAmount} or more within {minDrawDays} days of account opening. If not, it will be <b>{nonPromoApr}</b>.',
                    // Carrot means the language describes a reward: If you draw this much, you get a lower APR
                    carrot: '<b>Reduce your APR to {formattedPrimeV3Apr}</b> if you draw {minDrawAmount} or more when you open your account.',
                },
                cashOutUpFrontBannerText: '<b>{drawAmount} draw up front on activation</b>.',
                cashOutUpFrontCreditLineBeardText: 'On a <b>{formattedLineSize} Credit Card Line</b>',
                originationFeeText: ' {originationFee} Origination Fee',
                cashOutFeeText: ' {cashOutFeePercentText} Fee',
                guaranteedLowestRate: 'Guaranteed Lowest Rate',
                boostCashBackBannerText: '<b>{boostCashBack} Cashback on the first {boostCashBackDollarLimitPerCycle} per month</b>, and {regularCashBack} Unlimited Cashback thereafter.',
                additionalLiensNeedToBeCleared: 'You already have additional liens on your home that need to be cleared.',
                initialLimit: 'Start with a <b>{initialLineSize} initial limit</b>, and once the following are removed you can <b>unlock the full limit</b>.',
                getFullLimitLienContingency: '<b>Get full limit</b> after removing the following liens:',
                getFullLimitPaymentPerformanceContingency: '<b>Get full limit</b> after 6 on-time payments.*',
                helocRefi: {
                    creditLimit: 'Credit Limit',
                    transferAmount: 'Refi Transfer Amount',
                    termLength: 'Refi Term Length',
                    apr: 'APR',
                    cashBack: 'Cash Back',
                    years: 'years',
                },
            },
            remedyNameMismatch: {
                title: 'The title of the home has {ownerName} as the owner. Are you <b>{ownerName}</b>?',
                positiveCta: 'Yes, That Is Me',
                negativeCta: 'No, That Is Not Me',
            },
            hmda: {
                title: 'To ensure fair lending, federal law requires us to ask the following. This information is <b>NOT</b> used for our application decision. You can decline to provide this info.',
                skip: "<u>Skip, I Don't Wish To Provide</u>",
                loading: 'Saving...',
                skipCta: "Skip, I Don't Wish To Provide",
                cta: 'Continue',
                dontProvide: "I don't wish to provide",
                info:
                    '<p>' +
                    'The purpose of collecting this information is to help ensure that all applicants are treated fairly and that the housing needs of communities and neighborhoods are being fulfilled. ' +
                    'For residential mortgage lending, Federal law requires that we ask applicants for their demographic information (ethnicity, race, and sex) in order to monitor our compliance with equal ' +
                    'credit opportunity, fair housing, and home mortgage disclosure laws. You are not required to provide this information, but are encouraged to do so. ' +
                    'You may select one or more designations for “Ethnicity” and one or more designations for “Race". The law provides that we may not discriminate on the basis of this information, ' +
                    'or on whether you choose to provide it. However, if you choose not to provide the information and you have made this application in person, Federal regulations require us to note your ethnicity, ' +
                    'race, and sex on the basis of visual observation or surname. If you do not wish to provide some or all of this information, please check below.' +
                    '</p>',
                statedUsageDisclaimer: 'Not available for purchase.',
                statedUsageDisclaimerRefinance: 'Not available for purchase.',
                sex: {
                    placeholder: 'Sex',
                    male: 'Male',
                    female: 'Female',
                },
                usage: {
                    title: 'Usage',
                    homeImprovement: 'Home Improvement',
                    balanceTransfer: 'Balance Transfer',
                    other: 'Other',
                    disclaimer: 'Note: Answer from earlier in the application. Not available for home purchase.',
                },
                ethnicity: {
                    placeholder: 'Ethnicity',
                    maxEthnicitiesError: 'Enter no more than {numMaxEthnicities} ethnicities.',
                    hispanic: {
                        placeholder: 'Hispanic/Latino',
                        mexican: 'Mexican',
                        puertoRican: 'Puerto Rican',
                        cuban: 'Cuban',
                        other: {
                            placeholder: 'Other Hispanic or Latino',
                            hint: 'Ex. Ecuadorian',
                        },
                    },
                    notHispanic: 'Not Hispanic/Latino',
                },
                race: {
                    placeholder: 'Race',
                    maxRacesError: 'Enter no more than {numMaxRaces} races.',
                    americanIndian: {
                        placeholder: 'American Indian or Alaska Native',
                        hint: 'What is your principal tribe?',
                    },
                    asian: {
                        placeholder: 'Asian',
                        asianIndian: 'Asian Indian',
                        chinese: 'Chinese',
                        filipino: 'Filipino',
                        japanese: 'Japanese',
                        korean: 'Korean',
                        vietnamese: 'Vietnamese',
                        other: {
                            placeholder: 'Other Asian',
                            hint: 'Ex. Taiwanese',
                        },
                    },
                    black: 'Black or African American',
                    hawaiian: {
                        placeholder: 'Native Hawaiian/Pacific Islander',
                        native: 'Native Hawaiian',
                        guamanian: 'Guamanian or Chamorro',
                        samoan: 'Samoan',
                        other: {
                            placeholder: 'Other Pacific Islander',
                            hint: 'Ex. Tongan',
                        },
                    },
                    white: 'White',
                },
            },
            coOwner: {
                yourSpouseString: 'Your Spouse',
                withoutCoOwnerNameTitle: 'To know if someone else needs to sign the deed of trust, are you married or single?',
                consentSecondaryCardCreation: 'Add them as an authorized card holder and send a card (optional)',
                smsAuth: "Click 'Continue' to authorize Aven to share with co-owners above any information related to my Aven account and the underlying mortgage.",
                loading: 'Saving...',
                maritalStatus: {
                    placeholder: 'Marital Status',
                    married: 'Married',
                    single: 'Single',
                },
                disputeCoOwnerTitle: 'Let us know what seems incorrect and add supporting documents.',
                disputeCoOwnerSpouseNote: 'Please note that spouses must sign, even if not on the title',
                disputeCoOwnerPlaceholder: 'What seems incorrect...',
                disputeCoOwnerSubtitle:
                    'Upload supporting county-recorded documents showing other signers are not on title. Examples include: quitclaim deed, interspousal transfer deed, or a recent grant or title documents.',
                updateModal: {
                    nameMisspelling: { name: 'Name misspelling', description: 'No worries, when you sign with our online notary, we can fix any spelling mistakes.' },
                    notOnTitle: {
                        name: 'They are not on title',
                        description: 'Title records show them as an owner of the property. You can proceed to sign while we review. Account opening may be delayed if another signer is required.',
                    },
                    onTheListTwice: { name: 'They are on the list twice', description: 'We will remove them as a signer. We will need to verify this mistake before account opening.' },
                    deceased: {
                        name: 'They are deceased',
                        description: 'Sorry to hear that. We will remove them as a signer. You will need to upload a death certificate prior to opening your account.',
                    },
                    widowed: {
                        name: "I'm widowed",
                        description: 'We will update the marital status on your application, you may need to upload a death certificate prior to opening your account.',
                    },
                    marriedQuitClaim: {
                        name: "I'm married and I have a quitclaim deed",
                        description: `Your spouse still needs to sign unless you have a recorded Quitclaim Deed. <b>You will need to provide a recorded Quitclaim Deed before activating your card.</b>`,
                    },
                    separated: {
                        name: "We're separated and I have a quitclaim deed",
                        description: `Since separation is treated as legally married, your spouse still needs to sign unless you have a recorded Quitclaim Deed. <b>You will need to provide a recorded Quitclaim Deed before activating your card.</b>`,
                    },
                    separatedNoQuitclaim: {
                        name: "We're separated but I don't have a quitclaim deed",
                        description:
                            "Since separation is treated as legally married, your spouse still needs to sign. If you'd like to remove your spouse as a required signer, you will need to submit a Quitclaim Deed to your county recorder.",
                    },
                    none: { name: 'None of the above', description: 'Unfortunately this person needs to sign in order for you to open your account.' },
                },
            },
            trustInfo: {
                basicInfo: {
                    title: 'It looks like your home is in a trust.<br/> We just need a few trust details.',
                    trustName: 'Name of Trust',
                    dateOfExecution: 'Original Date (MM/DD/YYYY)',
                    revocable: 'Revocable',
                    irrevocable: 'Irrevocable',
                    settlorsTitle: 'Settlors (Creators Of Trust)',
                    trusteesTitle: 'Trustees',
                    titleToBeTakenAs: 'Title To Be Taken As',
                    beSureIsCorrect: 'Please be sure to accurately enter the correct clause',
                    confirmTakenAs: 'Please confirm the following Title To Be Taken As. If it does not match your documents, please edit it below.',
                    ifUnsure: 'If you are in doubt or unsure what this is, please refer to your trust documents.',
                    showTitleExample: ' <u>See example</u>.',
                    hideTitleExample: ' <u>Hide example</u>.',
                },
                settlors: {
                    title: 'Who are the settlors and can they revoke the trust?',
                    settlorNumber: 'SETTLOR {number}',
                    addSettlor: 'Add Other Settlors',
                },
                trustees: {
                    title: 'Who are the trustees and what are their powers?',
                    trusteeNumber: 'TRUSTEE {number}',
                    addTrustee: 'Add Other Trustees',
                    acquireProperty: 'Can Acquire Additional Property',
                    sellAndExecute: 'Can Sell & Execute Deeds',
                    encumberAndExecute: 'Can Encumber & Execute Deeds of Trust',
                    incurIndebtedness: 'Can Incur Indebtedness',
                    other: 'Other Powers',
                    otherDetails: 'Other Powers Description...',
                    trusteeSMS: 'We’ll have other trustees sign after you sign',
                },
                settlorsAndTrusteesShortcuts: {
                    title: 'CHECK THOSE THAT APPLY',
                    settlorsText: 'All <strong>Settlors</strong> (those who created the trust) have the power to revoke the trust.',
                    trusteesText:
                        'All <strong>Trustees</strong> have the following powers: Must Sign To Execute Powers, Can Acquire Additional Property, Can Sell & Execute Deeds , Can Encumber & Execute Deeds of Trust.',
                },
                sidebar: {
                    accuracyTitle: 'Information Accuracy',
                    accuracyBody:
                        'Please refer to your Trust Formation Documents when filling out this form. It’s important to be accurate in order to not have delays in closing. Please be sure to add yourself as a trustee or settlor if you are one.',
                    whoNeedsToSignTitle: 'Who needs to sign?',
                    whoNeedsToSignBody: 'All Trustees who have the power to exercise will need to sign.',
                },
                consent: 'By clicking continue, you certify that the above information is true and correct, and authorize sending a text to any parties who need to sign',
            },
            disputeProviderData: {
                title: 'Our automated systems were unable to approve your application due to {reasonTitle}.',
                disclaimer: 'If you think this is incorrect, please add a {remedyDocName} that proves {remedyProof}',
                cta: 'Continue',
                reason: {
                    thirdLien: {
                        title: 'you already having 2 other liens on your property',
                        remedyDocName: 'Proof of Lien Released',
                        remedyProof: 'one of the liens has been released.',
                        extendedReason: 'There are currently {numberOfLiens} liens already attached.',
                    },
                },
                uploadErrors: {
                    errorSelecting: 'Error selecting file',
                    errorDuplicate: 'File already exists',
                    errorFailedToUpload: 'Failed to upload file',
                },
                optOutText: 'If the information is correct and there is no documentation to add,',
                optOutCTA: 'click here.',
            },
            productDetails: {
                shared: {
                    fixedMonthlyPaymentsPlan: '5 and 10 year fixed monthly payment plans may be available for Cashouts and Balance transfers',
                    fees: 'No origination, cancellation or annual fee.',
                    noCancellationOrFee: 'No cancellation or annual fee.',
                    getAvenVisaCard: 'Get a Aven Visa Credit Card and earn unlimited {cashback} cash back.',
                    BTCO: 'Cashout to your bank and Balance Transfers are available – {BTCOFee} fee.',
                },
                helocRefi: {
                    transferYourBalance: 'Transfer your HELOC or HELOAN balance to an Aven 15 or 30 year plan. We handle the paperwork.',
                },
                fixedTerm: {
                    fixedMonthlyPaymentsPlan: '5 to 30 year fixed monthly payment plans may be available for Cashouts and Balance transfers.',
                    initialCashup: 'Initial cash upfront will be deposited to your bank, at a {termLength} year fixed rate – 4.9% draw fee.',
                    fixedApr: 'APR for subsequent cashouts and balance transfers will be fixed when taken (may be different than the initial draw’s APR).',
                    noFeesOnSubsequent: 'No fees on subsequent cashouts or balance transfers.',
                },
                cashoutUpFront: {
                    initialCashup: 'Initial cash upfront of {ratio} of your line will be deposited to your bank – {BTCOFee} fee.',
                    additionalCashouts: 'Additional Cashouts to your bank and Balance Transfers available – {BTCOFee} fee.',
                },
                lowAndGrow: {
                    startWith: 'Start with an initial line of $10,000 and upgrade to the full line after 6 on time payments.',
                },
                primeV3: {
                    aprDiscount: 'Utilize {ratio} of your line within the first {effectiveDays} days to get a {aprDiscount} APR discount.',
                },
            },
        },
        coApplicant: {
            coOwner: {
                coApplicantRelationship: '{primaryFirstName}, what is your relationship to {secondaryFirstName}?',
                smsAuth: "We'll text {firstName} documents to sign after you have signed. Click continue to authorize sending them a text.",
                loading: 'Saving...',
                relationshipType: {
                    married: 'Spouse / Domestic Partner',
                    other: 'Other',
                },
            },
        },
        personalInfoMultiStep: {
            nameAndEmail: {
                title: 'Welcome 👋<br/>Tell us a little about yourself.',
            },
            address: {
                title: 'What is the address?',
            },
        },
        dashboard: {
            pulse: {
                title: 'You are on the list!',
                subTitle: "We'll start rolling out early this fall and will text updates as we get closer.",
                spotInLine: 'Spot In Line',
                prequal: 'Prequalified up to',
            },
        },
        avenAutoOrigination: {
            title: `What's next?`,
            bullet1: 'Like the home card, but with your car. Including the {cashBackPercentage} unlimited cashback.',
            bullet2: 'You already did the heavy lifting, just add your car details to see if you prequalify.',
            licensePlate: {
                licensePlateLabel: 'License Plate',
                licensePlatePlaceholder: 'i.e. XYZ1234',
                licensePlateStateLabel: 'State',
                licensePlateStatePlaceholder: 'State',
            },
            vehicleModel: {
                year: 'Year',
                make: 'Make',
                model: 'Model',
                trim: 'Trim',
                mileage: 'Mileage',
            },
            continueButtonLabel: 'Check My Offers',
            errorText: 'There was a problem getting your vehicle details. Please refresh the page and try again.',
        },
        shared: {
            offerInfoCard: {
                title: 'Your PreQual Offer',
            },
            uploadCard: {
                title: 'Upload Document',
            },
            unableToVerifyIdentity: {
                title: 'Sorry, it looks like we could not verify your identity',
                subTitle: 'If you think you entered some information incorrectly, please try again.',
                cta: 'Try Again',
            },
            unapproved: {
                header: 'Our automated system was unable to approve your application',
                headerForAutoScreen: 'Our automated system was unable to approve you for the Home card, but you could be eligible for our new Auto card – like the home card, but using your car.',
                headerForReUnderwrite: 'Thanks for being a great customer. We were unable to upgrade your card today, but you may try again in 6 months.',
                bodyText: `We can't make any exceptions because our systems are automated. Feel free to re-apply in 6 months.`,
                bodyTextWithReasons: `We can't make any exceptions because our systems are automated. Feel free to re-apply in 6 months. Your application was not approved for the following reasons:`,
                nextTitle: `What's Next?`,
                nextTextCreditScore: 'Use our free Aven Advisor app to track your credit score and get tips to improve it.',
                nextTextDTI: 'Use our free Aven Advisor app to track your debt and income so you can best balance what lenders are looking for',
                nextTextHomeValue: 'Use our free Aven Advisor app to track the equity in your home and start strategizing  for future opportunities',
                nextTextOther: 'Use the Aven Advisor app to track your financial health',
                buttonTextCreditScore: 'Track Your Credit Score',
                buttonTextDTI: 'Monitor Your Debt',
                buttonTextHomeValue: 'Track Your Home Value',
                buttonTextOther: 'Unlock Advisor',
                link: 'Download Document',
                contact: 'Contact Us',
            },
            offerAddCoApplicant: {
                header: 'Unfortunately, your individual application was denied. Would you like to apply with a joint applicant?',
                headerNotOnTitle: 'Unfortunately, we cannot proceed with your application because you are not on the title. Please add a name on title as a joint applicant to continue.',
                cta: 'Add Joint Applicant',
            },
            waitlist: {
                title: 'Due to high demand, we currently have a waitlist for the Aven Card.',
                subTitle: 'We are working hard to get through the waitlist and will contact you when your spot is ready for you to apply for an Aven Card.',
                unsupportedState: {
                    title: `{stateName} coming soon!`,
                    subTitle: `Thank you for your interest in Aven! We’re working hard to launch to new states. We’ve added you to our {stateName} waitlist and will notify you once available.`,
                },
                unsupportedCounty: {
                    title: `We are currently unavailable in your county.`,
                    subTitle: `Thank you for your interest in Aven! We’re working hard with your county to give you the best card for homeowners. We’ve added you to our waitlist and will notify you once available.`,
                },
                reopen: {
                    title: '{stateName}, re-opening soon!',
                    subTitle: 'Thank you for your interest in Aven! We love the incredible demand in {stateName} and are going to accept new customers again soon. We will contact you when ready.',
                },
                waitlistFlag: {
                    title: 'Due to high demand, we currently have a waitlist for the Aven Card.',
                    subTitle: 'We are working hard to get through the waitlist and will contact you when your spot is ready for you to apply for an Aven Card.',
                },
                unsupportedFirstLienInState: {
                    title: 'First lien lines coming soon!',
                    subTitle: 'Sorry, we don’t offer first lien lines in {stateName}, but we’re working to include them soon. We’ve added you to our waitlist and will notify you once available.',
                },
            },
            thanks: {
                reasons: {
                    default: {
                        title: 'Unfortunately, there was an unexpected error',
                        subTitle:
                            "We are so sorry, something went wrong. <a href='{link}'>Start a new application</a> to try again. " +
                            "If you have any questions, please email us at <a href='{mailto}'>{displayEmail}</a>",
                        cta: 'Contact Us',
                    },
                    disputeCoOwnerInfo: {
                        title: 'It looks like the information in your home’s title needs review.',
                        subTitle: 'Our team will conduct a manual internal review & get back to you in a few days.',
                    },
                    duplicate: {
                        title: 'It looks like we found a prior application for you',
                        subTitle:
                            'You will need to access your prior application using the link provided to you by text or email. ' +
                            "If you can’t find the link, please email us at <a href='{mailto}'>{displayEmail}</a>",
                        cta: 'Contact Us',
                    },
                    expired: {
                        title: 'Unfortunately, your application expired',
                        subTitle: 'Sorry, it looks like too much time has passed since you began your application. ' + 'Please visit our homepage and apply again.',
                        cta: 'Back To Homepage',
                    },
                    identity: {
                        title: 'Sorry, it looks like we could not verify your identity',
                        subTitle: "If you have any questions, please email us at <a href='{mailto}'>{displayEmail}</a>",
                    },
                    coAppIdentity: {
                        title: `Sorry, it looks like we could not verify your joint applicant's identity`,
                        subTitle: "If you have any questions, please email us at <a href='{mailto}'>{displayEmail}</a>",
                    },
                    geo: {
                        title: 'Sorry, you must be within the US to apply for the Aven Card',
                        subTitle: "If you have any questions, please email us at <a href='{mailto}'>{displayEmail}</a>",
                    },
                    renter: {
                        title: 'Sorry, we are currently only available if you own a home',
                        subTitle: 'Our current product uses your home equity to help lower interest rates, so we are only accepting homeowners at this time.',
                    },
                    trustRIN: {
                        title: 'Sorry, we are not available with trusts in your county',
                        subTitle: "It appears your home is in a trust, and we don't currently support trust ownership in your county.",
                    },
                    trustNOO: {
                        title: 'Unfortunately, we currently do not support non-primary homes that are in a trust or LLC.',
                        subTitle:
                            'This is something we might add in the future. But unfortunately, as of today, this is not something we can support. If anything changes in the future, you can re-apply.',
                    },
                    trustUnsupportedState: {
                        title: 'Sorry, we are not yet available with trusts in your state',
                        subTitle: "It appears your home is in a trust, and we don't currently support trust ownership in your state.",
                    },
                    homeNotFound: {
                        title: 'Our automated title search system could not find your home',
                        subTitle: 'We could not locate your property in third-party databases. Sometimes this is because of recent changes to your property profile. Please try again in a few months.',
                    },
                    homeOwnership: {
                        title: 'Our automated system was unable to verify home ownership through county-recorded title documents. We will manually review them and provide an update as soon as possible.',
                        subTitle: "In the meantime, you may check your property records through your local county recorder's office to fix any name accuracy or spelling issues.",
                    },
                    invalidFloodZone: {
                        title: 'Sorry, we do not currently offer our card in special flood hazard areas',
                        subTitle:
                            'Our systems use title providers to validate flood zone risk. If you believe the information is incorrect and would like us to investigate, please contact us. We will review it and get back to you within the next week.',
                    },
                    unknownFloodZone: {
                        title: "Sorry, we could not confirm your home's current flood zone",
                        subTitle:
                            'Thank you for applying for an Aven card. Our systems use 3rd party automated title providers to determine and validate flood zone risk. Because we cannot complete a flood zone determination, we cannot move forward with your application. We appreciate your time, and thank you for giving Aven a chance!',
                    },
                    noFile: {
                        title: 'Sorry, it looks like we could not verify your credit history',
                        subTitle: "If you have any questions, please email us at <a href='{mailto}'>{displayEmail}</a>",
                    },
                    homeIsTrustOrLLC: {
                        title: 'It looks like your home is owned by an LLC',
                        subTitle:
                            'Unfortunately, we do not support homes owned by an LLC at this time. We are working on adding support for this in the future. If you think this is a mistake, please give us a call, and we can work with you to resolve it.',
                    },
                    review: {
                        title: 'Thank you for submitting your information. It looks like we need to review it manually, and we will contact you within a few days.',
                        cta: 'Questions? Send Us A Message',
                        footer: "You can also email us at <a href='{mailto}'>{displayEmail}</a>",
                    },
                    involuntaryLien: {
                        title: 'Thank you for submitting your information. It looks like we need to review it manually, and we will contact you within a few days.',
                        cta: 'Questions? Send Us A Message',
                        footer: "You can also email us at <a href='{mailto}'>{displayEmail}</a>",
                    },
                    uploadedDocs: {
                        title: 'Thank you for submitting your documents. We will review and contact you within a few days.',
                        cta: 'Questions? Send Us A Message',
                        footer: "You can also email us at <a href='{mailto}'>{displayEmail}</a>",
                    },
                    resourceNotFound: {
                        title: 'Thank you for submitting your information. It looks like we need to review it manually, and we will contact you within a few days.',
                        cta: 'Questions? Send Us A Message',
                        footer: "You can also email us at <a href='{mailto}'>{displayEmail}</a>",
                    },
                    closed: {
                        title: 'It looks like this application was closed',
                        subTitle:
                            "If you have an ongoing application, be sure to use the latest link that we sent you. If you have any questions, please email us at <a href='{mailto}'>{displayEmail}</a>",
                    },
                    authorization: {
                        title: 'For your security, since you have not been back recently or have accessed your application in another browser tab, we logged you out.',
                        cta: 'Continue application here',
                    },
                    declinedOffer: {
                        title: 'Sorry to see you go',
                        subTitle: "If you have any feedback or comments, we would love to hear from you. Please email us at <a href='{mailto}'>{displayEmail}</a>.",
                        cta: 'Send Feedback',
                    },
                    privateBrowsing: {
                        title: 'It looks like you are browsing in private mode',
                        subTitle:
                            "Unfortunately, our website does not work while in private mode. Please turn it off or switch to a different browser. If you have any questions, please email us at <a href='{mailto}'>{displayEmail}</a>",
                    },
                    blockedApplication: {
                        title: 'Thank you for submitting your information. It looks like we are not able to create an application for you.',
                        subTitle: 'This application has been blocked at this time. If you believe this is an error, please email <a href="{mailto}">{displayEmail}</a>.',
                    },
                    hmdaFinished: {
                        title: 'Thank you for submitting your information.',
                        subTitle: 'This is all for now.',
                        cta: 'Questions? Send Us A Message',
                        footer: "You can also email us at <a href='{mailto}'>{displayEmail}</a>",
                    },
                    jodlDocument: {
                        title: "Sorry, we couldn't find the document you were looking for.",
                        subTitle:
                            "If you have an ongoing application, be sure to use the latest link that we sent you. If you have any questions, please email us at <a href='{mailto}'>{displayEmail}</a>",
                    },
                    uploadedFloodInsurance: {
                        title: 'Your flood insurance document was sent to our team. We’ll review it and get back to you in a few days.',
                        footer: "If you have any questions you can email us at <a href='{mailto}'>{displayEmail}</a>",
                    },
                    uploadedHomeOwnersInsurance: {
                        title: 'Your homeowners insurance document was sent to our team. We’ll review it and get back to you in a few days.',
                        footer: "If you have any questions you can email us at <a href='{mailto}'>{displayEmail}</a>",
                    },
                    acknowledgedFloodDisclosure: {
                        title: "Thank you for acknowledging Aven's flood disclosure. You should be all set.",
                        footer: "If you have any questions you can email us at <a href='{mailto}'>{displayEmail}</a>",
                    },
                    unsupportedCounty: {
                        title: 'Sorry, we are not available in your county',
                        subTitle: 'Unfortunately, we are not available in your county at this time, we are working on expanding to your area.',
                    },
                    uploadedCompetitorAgreement: {
                        title: 'Thank you. We are reviewing your submission. If we conclude that your APR is lower than ours, we’ll send you $25.',
                        cta: 'Continue Application',
                    },
                    uploadedCompetitorAgreementHeloc: {
                        title: 'Thank you. We are reviewing your submission. If we conclude that your HELOC cost is lower than ours, we’ll beat the offer or send you $100.',
                        cta: 'Continue Application',
                    },
                    uccSuccess: {
                        title: 'All set– we’ve verified your income.<br>You can now activate your card.',
                        cta: 'Continue',
                    },
                    insufficientIncome: {
                        title: "Sorry, it looks like we couldn't verify your stated income",
                        cta: 'Try Again',
                        subTitle: 'If you have any questions, please email us at <a href="{mailto}">{displayEmail}</a>',
                    },
                },
            },
            incomeVerification: {
                verificationInProgress:
                    "You've already submitted {uploadedDocumentDescriptor} for review. Our team should get back to you within 1-2 days. You can upload additional files here, if you need&nbsp;to.",
                verificationInProgressIrsForm: "You've already signed the IRS 4506-C form",
            },
        },
        mlo: {
            applicationConsent: {
                title: 'To check offers for your client, we just need to confirm with them that you will doing that on their behalf.',
                firstNamePlaceholder: 'Client First Name',
                lastNamePlaceholder: 'Client Last Name',
                emailPlaceholder: 'Client Email Address',
                validNameFromIDOrPassport: `Use your client's legal name from a valid state ID or passport`,
                continueButtonLabel: 'Send Confirmation Email',
            },
            applicationConsentEmailSent: {
                title: 'Email Sent',
                body: 'We sent an email to {email} for your client to confirm. Once they confirm, you will be able to check offers for them. Please let them know to check their email and we will let you know as soon as they confirm.',
                continueButtonLabel: 'Got It',
            },
            applicationConsentReceived: {
                titleAccept: 'All Set!',
                titleDecline: 'No Problem!',
                bodyAccept: '{mloName} can now check to see if you qualify for the Aven Card. They will reach out to you shortly.',
                bodyDecline: 'No problem, we will NOT allow {mloName} to check for Aven offers on your behalf. ',
                consentAlreadyReceivedError: 'It looks like you have already confirmed this request.',
                genericError: 'Something went wrong. Please try again later.',
            },
            applicationPiiConfirm: {
                title: 'Great! Let’s start by confirming all your information is correct.',
                primaryApplicant: 'PRIMARY APPLICANT',
                jointApplicant: 'JOINT APPLICANT',
                firstName: 'First Name:',
                lastName: 'Last Name:',
                email: 'Email:',
                mobileNumber: 'Mobile Number:',
                lastFourOfSsn: 'Last 4 of SSN:',
                dateOfBirth: 'Date of Birth:',
                annualIncome: 'Annual Income:',
                marriageStatus: 'Marriage Status:',
                addressTitle: 'Address',
                address: 'Address:',
                addressConfirmationQuestion: 'Is the address above on your ID, paystub, or taxes?',
                confirmPrimaryAddressDisclaimer: 'By continuing, I confirm that this address is on my ID, paystub, or taxes.',
                yes: 'Yes',
                no: 'No',
                continue: 'Continue<sub>†</sub>',
                startOver: 'Start Over',
                genericError: 'Something went wrong. Please try again later.',
                footer: {
                    contactUs: '<p>If you did not ask {brokerName} to initiate an Aven application for you, <a href="mailto:support@aven.com">please contact us</a>.</p>',
                },
            },
            personalInfo: {
                title: 'To check if your client qualifies, enter the following information',
                primaryHeading: 'Primary Applicant',
                secondaryHeading: 'Secondary Applicant',
                propertyAddressHeading: 'Address of Property',
                residenceAddressHeading: 'Address of Primary Residency',
                residenceTypeCheckbox:
                    'I confirm that this address is on the customer’s ID, paystub or taxes. Failure to provide address verification will block account opening and may lead to account closure.',
                statedUsageHeading: 'What are they looking to use Aven for?',
                statedUsageAmountPlaceholder: 'How much do they need?',
                reportsFeeLabel: 'Identity & Credit Fee',
                reportsFeeAndCreditDisclosure:
                    'This will be charged to your account and will be deducted from your future payouts. This is not charged to your client. We use Idology for identity verification and Experian for the credit report. This is just a soft credit check and will not affect your clients credit score.',
                continueButtonLabel: 'Order Credit Report & Continue',
                continueDisclosure: `<p>Continuing will void any other in progress application for this primary applicant.</p>
                    <p>${shared.cardIssuedBy}</p>
                    <p>${shared.cardsArrangedBy}</p>
                    <p>Applicants, if married, may apply for a separate account.</p>
                    <p>Applicant has 30 days from the date you submit personal information to complete the application.</p>`,
                consentCheckboxLabel:
                    'By checking this box, you certify that you have obtained consent from the borrower(s) to use consumer reporting agencies, like Experian and Equifax, to make a “soft” credit inquiry and verify their employment and income. This check will not affect the borrower’s credit score. However, if the borrower continues and completes the application, we will request a “hard” credit pull, which may affect their credit.',
                brokerAgreementCheckboxLabel: 'By checking this box, you are signing your broker agreement document with this customer. The customer will sign during the notary session.',
            },
            dti: {
                title: 'Great! We were able to validate your clients identity and credit.',
                disputeTitle:
                    'These reports come directly from credit bureaus, government agencies, or 3rd party valuation systems. If you think there is an error, contact us at <a href="mailto:broker@aven.com?subject=Incorrect info for my client">broker@aven.com<a>',
                statedIncome: 'Stated Income',
                currentDebt: 'Current Debt',
                debtToIncome: 'Debt To Income',
                continueButtonLabel: 'Continue',
                disputeButtonLabel: 'Dispute',
                disputeContinueButtonLabel: 'Continue With Application',
                disputeCloseApplication: 'Close Application',
            },
            uwValuation: {
                title: 'Order an automated valuation to get the current the value of the home.',
                chargeName: 'Valuation Report Fee',
                chargeNoticeText:
                    'This will be charged to your account and will be deducted from your future payouts. This is not charged to your client. The properties valuation is generated by CoreLogic AVM (Automated Valuation Model).',
                ctaText: 'Order Automated Valuation',
            },
            floodDetermination: {
                title: 'Run a flood zone check to see if proof of flood insurance is required.',
                chargeName: 'Flood Report Fee',
                chargeNoticeText: 'This will be charged to your account and will be deducted from your future payouts. This is not charged to your client.',
                ctaText: 'Order Flood Zone Check',
            },
            applicationReports: {
                title: 'Great! We were able to run all the reports for your client. Share with your client and submit for underwriting.',
                creditReport: {
                    title: 'Credit Report',
                    ficoLabel: 'FICO',
                },
                debtToIncomeReport: {
                    title: 'Debt to Income',
                },
                valuationReport: {
                    title: 'Valuation Report',
                },
                floodReport: {
                    title: 'Flood Report',
                    noFloodNoticeText: 'Good news, the property is not in a flood zone. The client will not need proof of flood insurance.',
                    floodNoticeText:
                        'According to the USGS, the home is located in flood zone AE. Please share with your client that they will need proof of flood insurance to finalize their application',
                },
                primaryCtaText: 'Continue',
                secondaryCtaText: 'Dispute',
            },
            applicationAAN: {
                title: 'Sorry, we were unable to approve the application. Please assist your client in understanding the issues and send the Adverse Action Notice.',
                subtitle: `Unfortunately the client was unable to get an offer today for the following reasons:`,
                primaryCtaText: 'Send Adverse Action To Client',
                primaryCtaSubtext: 'If it is not sent to the client, it will automatically be emailed to the client within 24 hours.',
                genericError: 'Something went wrong. Please try again later.',
            },
            applicationDenied: {
                title: 'Unfortunately the client was unable to get an offer today for the following reasons:',
                denialReasonDefault: 'Unfortunately, there was an unexpected error. Please try again later.',
                denialReasonReview: 'It looks like we need to review the application manually, and we will contact your client within a few days.',
                denialReasonIdentity: `Unfortunately, we could not verify your client's identity.`,
                denialReasonIdentityCreditReportFrozen: `Unfortunately, we could not verify your client's identity because their credit report is frozen. Please ask them to unfreeze their credit report and try again.`,
                denialReasonJointApplicantIdentity: `Unfortunately, we could not verify the joint applicant's identity.`,
                denialReasonJointApplicantCreditReportFrozen: `Unfortunately, we could not verify the joint applicant's identity because their credit report is frozen. Please ask them to unfreeze their credit report and try again.`,
                denialReasonGeo: `Unfortunately, your client must be within the US to apply for the Aven Card`,
                denialReasonRenter: `Unfortunately, we are currently only available if your client owns a home. Our current product uses your home equity to help lower interest rates, so we are only accepting homeowners at this time.`,
                denialReasonTrustRIN: `It appears your clients' home is in a trust, and we don't currently support trust ownership in your client's county.`,
                denialReasonTrustNOO: `Unfortunately, we currently do not support non-primary homes that are in a trust or LLC.`,
                denialReasonTrustUnsupportedState: `It appears your client's home is in a trust, and we don't currently support trust ownership in your state.`,
                denialReasonHomeNotFound: `We could not locate your client's property in third-party databases. Sometimes this is because of recent changes to your client's property profile. Please try again in a few months.`,
                denialReasonHomeOwnership: `Our automated system was unable to verify home ownership through county-recorded title documents. We will manually review them and provide an update as soon as possible. In the meantime, you may check your client's property records through your local county recorder's office to fix any name accuracy or spelling issues.`,
                denialReasonInvalidFloodZone: `Sorry, we do not currently offer our card in special flood hazard areas.`,
                denialReasonUnknownFloodZone: `Sorry, we could not confirm your client's home's current flood zone`,
                denialReasonHomeIsTrustOrLLC: `It looks like your client's home is owned by an LLC`,
                denialReasonUnsupportedCounty: `Sorry, we are not available in your client's county`,
            },
            statusPage: {
                milestones: {
                    OFFERS_SENT: 'Sent Offers',
                    SELECTED_OFFER: 'Selected Offer',
                    INCOME_VERIFIED: 'Income Verified',
                    ACCEPTED_FINAL_OFFER: 'Accepted Final Offer',
                    SCHEDULED_NOTARY: 'Scheduled Notary',
                    SIGNED_AND_CLOSED: 'Signed & Closed',
                },
            },
        },
        pifForAll: {
            senderInfo: {
                title: 'Great! We just need the basics to create your link.',
                sectionHeader: 'YOUR INFORMATION',
                firstNameLabel: 'First Name',
                lastNameLabel: 'Last Name',
                emailLabel: 'Email Address',
                businessNameLabel: 'Business Name (Optional)',
                cta: 'Create My Link',
                ctaDisclaimerHtml:
                    'By continuing, you agree to our <a href="/docs/TermsOfUse.pdf" target="_blank" rel="noopener noreferrer">Terms of Service</a>, <a href="/privacy" target="_blank" rel="noopener noreferrer">Privacy Policy</a> and <a href="/public/docs/PifForAllTerms" target="_blank" rel="noopener noreferrer">Pay It Forward Terms</a>',
            },
            link: {
                title: 'All ready! Copy your link and start sharing with your network.',
                inviteLinkLabel: 'YOUR INVITE LINK',
                cta: 'Copy Link To Share',
                ctaNativeShare: 'Copy or Share Link',
                nativeShareText: 'Get the Aven HELOC card with my code for {maxAmount} credit! Visit {pifForAllLink}',
                ctaSuccess: 'Copied ✓',
                copyError: `Couldn't copy your link. Try highlighting and manually copying it.`,
                additionalInfo: 'We also sent an email and SMS with your exclusive link',
                howToUse: {
                    title: 'How to use my invite link?',
                    body: 'You can send it to friends and family, share it with your followers on social media, or put it on your website. Really, anywhere you want!',
                },
                howWillBannerAppear: {
                    title: 'How will my banner appear?',
                },
            },
        },
        advisor: {
            landing: {
                phoneLegalText: `<sup class="sup-md">†</sup>By providing your number, you consent to automated or manual marketing messages & to <a class="h-blue highlighted" href="/docs/TermsOfUse.pdf" target="_blank">Terms of Service</a>. Consent to marketing messages is not required for any purchase. Text 'stop' to ${optOutPhoneNumber} to opt out after starting your application.`,
                topSection: {
                    title: 'Access your credit report and financial profile',
                    subtitle: 'Check in 30 secs!',
                    checkWeekly: 'Check your weekly credit score',
                    findHiddenSubscriptions: 'Find your hidden subscriptions and save',
                    neighborhoodHomePrices: 'Follow your neighborhood home prices',
                    trackCarAndHomePrices: 'Track your car price and your home prices',
                    congrats: 'Congrats! You’ll receive a message from our team soon on next steps.',
                    phoneNumberLabel: 'Enter your number to get access now',
                },
                weeklyCreditScoreSection: {
                    title: 'Free weekly credit score',
                    text: 'Get your free credit score & report every 7 days. We’ll notify you any time there is a change on your credit score.',
                },
                subscriptionsSection: {
                    title: 'Save money by finding hidden subscriptions',
                    text: 'View all your recurring expenses – from gym memberships, streaming services, to rent. Aven Advisor will help you stay on top of your monthly transactions.',
                },
                summarySection: {
                    title: 'Track your net worth, home value, car, and more...',
                    text: 'We make it easy for you to track what goes in and out of your pocket. From home valuation to income tracking, to providing your top monthly merchants. We have it all.',
                },
                neighborhoodPricesSection: {
                    title: 'Follow neighborhood home prices',
                    text: 'Gain a better understanding of your neighborhood and view the latests stats in your immediate market.',
                },
                howItWorksSection: {
                    title: 'How it works',
                    text: 'In 30 seconds or less get your own report. Get your credit report and track your financial health.',
                    aboutYourselfTitle: 'Tell us about yourself',
                    aboutYourselfText: 'Answer a few simple questions to quickly confirm your identity and home. <b>This does <span class="text-underline">not</span> affect your credit score.</b>',
                    advisorToolsTitle: 'Benefit from the Aven Advisor tools',
                    advisorToolsText: 'Have visibility into your credit score, home valuation and track your finances. All in 1 spot.',
                    weeklyUpdatesTitle: 'Weekly Updates',
                    weeklyUpdatesText: 'Receive free weekly scores and reports to keep track of your investments.',
                },
                getAccessSection: {
                    label: 'Enter your number and get access to Aven Advisor',
                    subLabel: 'Get your credit report in 30 seconds!',
                    ctaLabel: 'Continue',
                },
            },
        },
        churnRetention: {
            statedIncome: {
                title: 'To check in just one step, what is your latest annual income?',
            },
        },
        nameMismatchRemedyTokenMatch: {
            title: 'Which name on the title is yours? Minor differences are ok.',
            noneOfTheAbove: 'None of the above',
            cta: 'Continue',
        },
    },
    bankConnect: {
        loading: 'Loading...',
        loadingContent: 'We are currently processing your data to get you the best rate. This typically takes 30 seconds, but can take up to a few minutes.',
        title: 'We need to connect your bank account to verify your income and enable quick cash advances.',
        paragraphOne: '<strong>Secure Income Verification</strong>',
        subParagraphOne: 'By connecting your bank account we can instantly verify your income, no pay stubs needed.',
        paragraphTwo: '<strong>Quick No Fee Cash Advances</strong>',
        subParagraphTwo: 'When we launch, if you’re approved, use your connected bank account to get cash when you need it, with $0 fees.',
        connect: 'Connect Bank',
        tryAgain: 'Unable to link bank account. Please try again, or try another bank account or other form of income verification.',
        insufficientIncome: 'Sorry, we were unable to verify your income using your selected bank. Please try connecting a different bank or uploading a photo of a document to continue.',
        insufficientAssets: 'We were unable to verify sufficient net worth. Please connect additional bank accounts.',
        cta: 'Connect bank',
        uploadPayStub: 'Upload Latest Pay Stub Instead',
        uploadTaxReturn: 'Upload Latest Tax Return Instead',
    },
    selectIncomeVerificationMethod: {
        title: '<span class="fw-bold">{firstName}</span>, let’s quickly verify your income. Which is easiest for you?',
        titleWithCoApplicant: `Which is the easiest way for you to verify <span class="fw-bold">{firstName}'s</span> income?`,
    },
    payStubUpload: {
        title: '<span class="fw-bold">{firstName}</span>, please add a photo or PDF of your latest pay stub with your name.',
        titleWithCoApplicant: `Please add a photo or PDF of <span class="fw-bold">{firstName}'s</span> latest pay stub with your name.`,
        errorSelecting: 'A pay stub file was not selected. Please click on the button to upload a pay stub.',
        errorDuplicate: 'You have already selected this pay stub to be uploaded. Choose a different file or click continue.',
        errorText: 'There was a problem uploading your pay stub. Please try again (note: refreshing the page may help).',
    },
    form1099Upload: {
        title: '<span class="fw-bold">{firstName}</span>, please add a photo or PDF of your 1099’s from last year.',
        titleWithCoApplicant: `Please add a photo or PDF of <span class="fw-bold">{firstName}'s</span> 1099’s from last year.`,
        errorSelecting: 'A 1099 form file was not selected. Please click on the button to upload a 1099 form.',
        errorDuplicate: 'You have already selected this 1099 form to be uploaded. Choose a different file or click continue.',
        errorText: 'There was a problem uploading your 1099 form. Please try again (note: refreshing the page may help).',
    },
    otherIncomeUpload: {
        title: 'Which document will you upload?',
        errorSelecting: "A document file was not selected. Please click the 'Add' button to upload all documents before continuing.",
        errorText: 'There was a problem uploading your document. Please try again (note: refreshing the page may help).',
        errorAlreadyUploaded: 'That statement was uploaded already. Please upload a different one',
    },
    bankStatementsUpload: {
        title: 'Which document will you upload?',
        errorSelecting: "A document file was not selected. Please click the 'Add' button to upload all documents before continuing.",
        errorText: 'There was a problem uploading your document. Please try again (note: refreshing the page may help).',
        errorAlreadyUploaded: 'That statement was uploaded already. Please upload a different one',
    },
    profitAndLossUpload: {
        title: 'Which document will you upload?',
        errorSelecting: "A document file was not selected. Please click the 'Add' button to upload all documents before continuing.",
        errorText: 'There was a problem uploading your document. Please try again (note: refreshing the page may help).',
        errorAlreadyUploaded: 'That statement was uploaded already. Please upload a different one',
    },
    socialSecurityUpload: {
        title: 'Which document will you upload?',
        errorSelecting: "A document file was not selected. Please click the 'Add' button to upload all documents before continuing.",
        errorText: 'There was a problem uploading your document. Please try again (note: refreshing the page may help).',
        errorAlreadyUploaded: 'That statement was uploaded already. Please upload a different one',
    },
    pensionUpload: {
        title: 'Which document will you upload?',
        errorSelecting: "A document file was not selected. Please click the 'Add' button to upload all documents before continuing.",
        errorText: 'There was a problem uploading your document. Please try again (note: refreshing the page may help).',
        errorAlreadyUploaded: 'That statement was uploaded already. Please upload a different one',
    },
    retirementUpload: {
        title: 'Which document will you upload?',
        errorSelecting: "A document file was not selected. Please click the 'Add' button to upload all documents before continuing.",
        errorText: 'There was a problem uploading your document. Please try again (note: refreshing the page may help).',
        errorAlreadyUploaded: 'That statement was uploaded already. Please upload a different one',
    },
    fortifiedIncomeUpload: {
        title: 'Which document will you use?',
        errorSelecting: "A document file was not selected. Please click the 'Add' button to upload all documents before continuing.",
        errorText: 'There was a problem uploading your document. Please try again (note: refreshing the page may help).',
        errorAlreadyUploaded: 'That statement was uploaded already. Please upload a different one',
    },
    irsForm: {
        title: 'We just need your authorization for a one-time request to get your most recent tax return from the IRS.',
    },
    rentalIncomeUpload: {
        taxReturn: `Let's quickly verify your rental income. Upload a photo or PDF of your most recent tax return.`,
    },
    currentRentVerificationSelection: {
        title: 'Next, please provide proof of your current rent for {propertyAddress}.',
    },
    currentRentUpload: {
        title: 'Which documents will you use?',
        subtitleForUpload: 'Be sure this is from your account with most of your rental income, and include all pages.',
        errorText: 'There was a problem uploading your document. Please try again.',
    },
    taxReturnUpload: {
        title: 'Take a photo of the first page of your {latestTaxYear} tax return',
        titleWithCoApplicant: 'Take a photo of the first page of {firstName}’s {latestTaxYear} tax return',
        imageText: 'Be sure to include the entire page in the photo, including line 11 (adjusted gross income)',
        errorSelecting: 'A tax return file was not selected. Please click on the button to upload a tax return.',
        errorDuplicate: 'You have already selected this tax return to be uploaded. Choose a different file or click continue.',
        errorText: 'There was a problem uploading your tax return. Please try again (note: refreshing the page may help).',
    },
    incomeVerificationInProgress: {
        title: `Hey <span class="fw-bold">{firstName}</span>, we were unable to verify your income documents. Please connect your bank account or upload new documents to verify.`,
        titleInProgress: `Hey <span class="fw-bold">{firstName}</span>, you've already submitted income documents. Our team is reviewing and will get back to you in 1-2 days.`,
    },
    IncomeDocumentUploadPortal: {
        titlePending: `Let’s verify your income in minutes`,
        titleAwaitingReview: `Hey {firstName}, we are reviewing your documents and will get back to you in 1 business day.`,
        titleInvalid: `Hello {firstName}, we were unable to read your {docType}. Let’s try again.`,
        titleInvalidWithReason: `Hello {firstName}, we were unable to read your {docType} because {invalidReason}. Let’s try again.`,
        titleInsufficientSingle: `Hello {firstName}, the income on your {docType} was less then what you stated. Let’s try again.`,
        titleInsufficientJoint: `Hello {firstName}, the income on your {docType} was less then what you stated. Try a different document or add your joint applicants income.`,
        titleDocumentSelectPrompt: `Please select the document to upload.`,
        titleDocumentSelectPromptForApplicant: `Please select the document to upload for {applicant}.`,
        errorSelecting: `A document file was not selected. Please click the 'Add' button to upload all documents before continuing.`,
        errorDuplicate: 'You have already selected this document to be uploaded. Choose a different file or click continue.',
        errorText: 'There was a problem uploading your file. Please try again (note: refreshing the page may help).',
    },
    supportingDocumentUpload: {
        title: '<span class="fw-bold">{firstName}</span>, please add any additional documents below.',
        titleFlood: '<span class="fw-bold">{firstName}</span>, please add your flood documents below.',
        titleHomeOwnersInsurance: '<span class="fw-bold">{firstName}</span>, please add your homeowners insurance documents below.',
        uploadBoxText: 'Other Document',
        competitorAgreement: {
            title: 'Upload your active credit card agreement with your current APR lower than our lowest offered rate.',
            titleHeloc: 'Upload a HELOC agreement or offer from within the last 30 days.',
            subtitleContent: `
                <p>Be sure the document contains</p>
                <ul>
                <li><strong>{fullName}</strong></li>
                <li><strong>{propertyAddress}</strong></li>
                <li><strong>Rates and Fees</strong></li>
                </ul>
            `,
            uploadBoxText: 'Add Agreement',
            propertyAddress: 'Your property address',
            disclaimer: 'Document should be an active agreement with a lower APR that is not an introductory, temporary, or promotional rate.',
            disclaimerHeloc: 'Document should be an active HELOC agreement or offer that is not an introductory, temporary, or promotional rate.',
        },
        mortgageStatement: {
            title: 'We were unable to automatically get your mortgage amount. Please upload your latest statement.',
            uploadBoxText: 'Latest Mortgage Statement',
        },
        errorSelecting: 'A file was not selected. Please click on the button to upload a file.',
        errorDuplicate: 'You have already selected this file to be uploaded. Choose a different file or click continue.',
        errorText: 'There was a problem uploading your file. Please try again (note: refreshing the page may help).',
        priorHelocStatement: {
            title: 'Second Lien Refinance',
            subtitleContent: "Start your HELOC/HELOAN transfer by uploading your latest statement. Be sure it includes the servicer's name and phone number.",
            uploadBoxText: 'Recent Statement',
            uploadBoxDisclaimer: 'Be sure this includes the servicer name and phone number.',
            disclaimer: 'By clicking continue, I provide consent to Aven to pay off my second lien mortgage.',
        },
        firstLienMortgageStatement: {
            title: 'Upload Your Mortgage Statement',
            subtitleContent: 'Please upload the latest statement of the mortgage you stated previously on the website.',
            uploadBoxText: 'Latest Mortgage Statement',
        },
        allLienInfoUpload: {
            title: 'Upload Your Lien Status Document',
            subtitleContent:
                'Please upload the Lien Status Document to verify the current status of any liens on the property. Acceptable documents include the latest Mortgage Statement, a Lien Reconveyance Letter, or any other official documentation that confirms the existence or absence of liens.',
            uploadBoxText: 'Lien Status Document',
        },
    },
    disputeCoOwnerDocumentUpload: {
        errorDuplicate: 'You have already selected this file to be uploaded. Choose a different file or click continue.',
        errorText: 'There was a problem uploading your file. Please try again (note: refreshing the page may help).',
    },
    awaitingManualDocumentVerification: {
        title: 'We are currently reviewing your information. We will text you a link to finalize when we are done.',
    },
    offerPreview: {
        header: 'Hi 👋 Here are your offers! <br/> Select the best one for you.',
        revisedOfferHeader: 'Based on our calculations here is your updated offer.',
        loanOffer: 'Loan Offer',
        carSecured: '— CAR SECURED —',
        unsecured: '— UNSECURED —',
        loanAprAndTerm: '{0} Months  •  {1}% APR*',
        securedLoanContent: 'Using your car as collateral, we are able to significantly increase the amount and lower the APR.',
        unsecuredLoanContent: 'If you don’t have a car, or don’t want to use your car as collateral.',
        loading: 'Crunching Numbers...',
        saveloan: 'Saving...',
        legalOffer: 'The APRs above are based on having AutoPay turned on for your monthly payments. Final loan approval will requires income verification and vehicle information.',
        legal: "*APR (Annual Percentage Rate) is the cost of credit as a yearly rate and reflects both your interest rate and any origination fee. We don't charge an origination fee. The APRs above are based on having AutoPay turned on for your monthly payments. Final loan approval requires income verification and vehicle information.",
        faqTitle: 'Frequently Asked<br/>Questions',
        faq1q: 'How does a car secured loan work, and why is it a good option?',
        faq1aHtml:
            'By using your car as collateral, we get cheaper capital. We pass those savings on to you. So the benefit is that the monthly payments will be significantly lower than a traditional loan.',
        faq2q: 'Does it matter if I have a loan on my car?',
        faq2aHtml: 'We will work with you to refinance your car. Our goal is to lower your currently monthly payment, but at the very least we will match your current monthly payment. ',
    },
    bankSelect: {
        loading: 'Processing...',
        loadingContent: 'We are currently processing your data to get you the best rate. This typically takes 30 seconds.',
        header: 'Where should we deposit your money, if approved?',
        goHome: 'Go Home',
        tryAgain: 'Try Again',
        error: {
            productNotSupported: 'We apologize, your bank is not supported at this time. We will notify when support is available.Thanks.',
            invalidCredentials: 'Sorry, the credentials you provided is not valid. Please try again. thanks',
            somethingWentWrong: 'Sorry, we experienced some problems. Would you like to try again? if you need assistance, click the HELP button',
        },
    },
    conversation: {
        adBlockMessage: 'We understand your need for privacy. However, ad blockers will interfere with our application. Please disable it and refresh the page.',
        pleaseRefresh: 'Refresh the page if you have turned off the ad blocker',
        adBlockOff: "👍 Great! Let's get started.",
        helpWithLinkingBank: 'Having trouble linking your bank? Click HELP on the top right corner to get in touch with us.',
        noDepositoryAccount: 'Sorry, you do not have a checking or savings account with {bankName}. Please select another bank.',
        intro: 'Welcome! 👋 Just a little about you to instantly check for offers',
        whatIsYourAddress: 'Nice to meet you, {name}! What is your primary address?',
        foundAddress: 'Found it! Does this look right?',
        changeAddress: "Ok {name}, let's update your address.",
        mustLiveInCalifornia: 'I apologize. Currently, we can only accept California resident',
        linkBankMessage: 'Great! Just a few steps for final approval. Connect your bank to instantly verify your income.',
        selectBankMessage: 'In which account do you receive your primary income?',
        somethingWentWrong: `Sorry, there seems to be some network issues. Please try again. Thanks!`,
        verifyIdentity: 'Great, to verify your identity, what is the last 4 of your SSN?',
        successfullyQualified: 'Congrats, you qualified 🙌' + '<br/>Get the lowest APR by securing the loan with your car. Which option is right for you?',
        licensePlate: "What is your vehicle's license plate and state of registration?",
        doYouHaveInsurance: 'Is your car insured? We will ask for a photo later if you accept an offer.',
        checkSsn: 'Sorry, we could not verify your SSN. Please double check and try again. Thanks!',
        genericApplicantInfoError: 'Sorry, we could not verify your identity. Please double check the information below.',
        helocDeedOfTrust: 'Please review and sign the <br/> Deed of Trust',
    },
    bankReconnect: {
        title: 'Connect your bank',
        subTitle: 'In order to continue, we need to connect your bank.',
        cta: 'Reconnect bank',
        loading: 'Finding your account...',
    },
    bankSelectAccount: {
        title: 'What is your primary account?',
    },
    bankConnectionSuccess: {
        title: 'Great, we were able to reconnect your bank.',
        subTitle: 'Print your documents below for your records.',
    },
    components: {
        onboarding: {
            pifBonus: {
                title: '{formattedPifBonus} Sign Up Bonus',
                body: 'Once you finalize you are all set to get {formattedPifBonus} in statement credit, thanks to {pifSenderName}.',
                button: '{formattedPifBonus} Bonus',
                travelRewardsV0: {
                    title: 'Two Free Tickets to Vegas',
                    subtitle: 'Continue to get 2 free round-trip tickets to Vegas upon card activation.',
                },
                travelTicketsBahamas: {
                    title: 'Two Free Tickets to Bahamas',
                    subtitle: 'Continue to get 2 free round-trip tickets to Bahamas upon card activation.',
                },
            },
            pifForAllBanner: {
                title: 'Exclusive Bonus',
                body: 'Get {formattedDollarAmount} in statement credit, thanks to <b style="color: #2dd173">{sourceName}</b>',
            },
            helpListItem: {
                title: 'Questions or thoughts?',
                altSubTitle: 'Thank you for your feedback. It really helps us build a better product for you. We will reach back shortly.',
            },
            otherSignersListItem: {
                title: 'Why do others need to sign?',
                subTitle: "If there are other people listed on your home's title (Deed of Trust), they are legally required to sign. Spouses also need to sign, even if not on title.",
            },
            feedbackListItem: {
                title: 'Questions?',
                altSubTitle: 'Thank you for your feedback. It really helps us build a better product for you. We will reach back shortly.',
            },
            questionsListItem: {
                title: 'Questions?',
                altSubTitle: '<p class="text-muted"> Text Us: 1 (415) 582-6613<br> Call Us: 1 (888) 966-4655 </p>',
            },
            rateComparisonGraphListItem: {
                lowRateTitle: 'Your {rate}%  Aven rate is better than your expected rate elsewhere',
                lowRateContent: 'Your rate is better than the expected rate for your debt-to-income level at large online HELOC lenders. At Aven, what you see is what you get. No bait and switch.',
                lowRateSource:
                    'Source: <a target="_blank" rel="noopener" href="https://ffiec.cfpb.gov/data-browser/data/2020?category=states&items=CA&leis=549300PIL8LFAQ04XC20,5493003GQDUH26DNNH17,254900UL88QFG0E40516&actions_taken=1&lien_statuses=2">"HMDA Dataset Filtering"</a>',
                title: 'Average Personal Loan APRs',
                content: 'Your rate is better than the expected rate for your credit scores, at the best online personal loan. Congratulations on using your home equity to lower your interest rates.',
                source: 'Source: <a target="_blank" rel="noopener" href="https://www.nerdwallet.com/best/loans/personal-loans/personal-loan-interest-rates">"Nerdwallet (Sep 15, 2022)"</a>',
            },
            jointListItem: {
                title: 'Why apply joint?',
                subTitle: 'Adding a joint applicant and having a joint account can possibly increase the likelihood of credit approval.',
            },
            noFeesListItem: {
                title: 'No application or origination fees',
                subTitle: 'Most home equity products will cost you thousands to close. Not here. No costs to get a card and unlock your home equity. That’s right, $0.',
            },
            noCreditCardCostListItem: {
                title: 'Is there really NO cost to get a card?',
                bodyWithBalanceTransfer:
                    'Yep, $0 to start. $0 to keep your card unless you pick a monthly fee. You only pay low interest if you use it, a fee if your payment is late, and a simple 2% fee if you choose to use our cashout or balance transfer features.',
                bodyWithNoBalanceTransferFee:
                    'Yep, $0 to start. $0 to keep your card unless you pick a monthly fee. You only pay low interest if you use it, a fee if your payment is late, and a simple 2% fee if you choose to use our cashout feature.',
            },
            cashBackListItem: {
                title: '{cashBackPercentage} Unlimited Cashback',
                subTitle: 'Earn unlimited {cashBackPercentage} cashback on every single purchase on your Aven card.',
            },
            homeImprovementListItem: {
                title: 'Home improvement made easy',
                subTitle:
                    'Simplest & most affordable way to unlock your home equity. Plus home improvements could be tax deductible. Consult a tax advisor regarding the deductibility of interest and charges.',
            },
            lowAprListItem: {
                title: 'Absurdly low APR<sup>1</sup>',
                subTitle: 'Stop paying thousands a year in interest on your high-interest debt, like a credit card or personal loans.',
            },
            cashOutListItem: {
                title: 'Cash Out directly to your bank',
                subTitle: 'Directly deposit cash from your Aven card to your bank account at the same low rate. Some fees may apply.<sup>1</sup>',
            },
            noBalanceFeeListItem: {
                title: '$0 balance transfers fee<sup>1</sup>',
                subTitle: 'Transfer your high-interest debt without no balance transfer fees and save big on your monthly payment.',
            },
            howLongListItem: {
                title: 'How long will it take to finalize?',
                subTitle: 'After this step, we only need you to quickly verify your income and sign. This usually takes 5-10 minutes.',
            },
            howFixedTermWorksListItem: {
                title: 'How does this work?',
                subTitle: 'This is a line of credit where you get your available funds upfront. We’ll also send you an Aven card.',
                superScriptPq: `<p class="small text-muted text-start">
                2. For offers above {revolvingShadowLimitCap}, your Aven Card will allow you to access up to {revolvingShadowLimitCap} of your available line as you pay down your balance. For offers below and including {revolvingShadowLimitCap}, your
                Aven Card will allow you to access your full line as you pay down your balance.
                </p>
                <p class="small text-muted text-start">
                    ${shared.purchasesPlacedOnRevolvingBalance}
                </p>`,
                superScriptOffer: `<p class="small text-muted text-start">
                2. ${shared.cardAllowAccessUpTo}
                </p>
                <p class="small text-muted text-start">
                    ${shared.purchasesPlacedOnRevolvingBalance}
                </p>`,
            },
            noPrepaymentFeesListItem: {
                title: 'No Prepayment Fees',
                subTitle: 'Enjoy the freedom to pay off your loan at any time– no prepayment fees.',
            },
            canRedrawListItem: {
                title: 'Can I Redraw?',
                subTitle: 'Yes! You can easily redraw with no fees. Interest rates for cashouts and balance transfers will be fixed when taken. Rates for additional draws may be different.',
            },
            ssnListItem: {
                title: 'Why do you need my SSN?',
                subTitle: 'We only need the last 4 digits to securely confirm your identity to get you the best rates.',
            },
            lastStepListItem: {
                title: 'Last step!',
                subTitle: 'Quickly verify your income before you can sign your documents and join the Aven family!',
            },
            safeInfoListItem: {
                title: 'Is my information safe?',
                subTitle: 'Your data is encrypted end-to-end, and verification is done in a secure and compliant manner.',
            },
            verifyEmploymentListItem: {
                title: 'Instantly verify employment',
                subTitle: 'We send a link to your work email to instantly verify your employment.',
            },
            skipListItem: {
                title: 'Can I skip this?',
                subTitle: 'Yes, absolutely.',
            },
            whyHmdaListItem: {
                title: 'Why are you asking for this?',
                subTitle: 'By law – the 1975 Mortgage Disclosure Act – we are required to ask this question to ensure fair lending.',
            },
            hmdaAppImpactListItem: {
                title: 'Does this impact my application?',
                subTitle: 'No. This information is not used by us at all. The government collects this information to ensure fair lending.',
            },
            shortProcessAssuranceListItem: {
                title: 'Almost there!',
                subTitle: 'Just a couple of things <br/><ul class="mb-0"><li>Confirm co-owners if needed</li><li>Sign with our online notary team</li></ul>',
                titleEmploymentInsurance: '<ul class="mb-0"><li>We confirm your title position (1st or 2nd), employment, and insurance. Usually this is automatic.</li></ul>',
                floodInsurance: '<ul class="mb-0"><li>For homes in a FEMA flood zone, we require proof of flood insurance with Coastal Community Bank listed as loss payee.</li></ul>',
            },
            saveUpToListIten: {
                title: 'Save up to 50% on monthly interest',
                subTitle: 'Stop paying thousands a year in interest from your high-interest debt, like a credit card or personal loan.',
            },
            useWhatYouWantListItem: {
                title: 'Use What You Want',
                subTitle: 'Use what you want, when you want – like a credit card. You can also send cash to your bank or balance transfer.',
            },
            zeroToGetListItem: {
                title: 'Starting at $0 to Get',
                subTitle: 'Typical home equity products can cost thousands of dollars to originate on top of the weeks-long process.',
            },
            noAnnualFeeListItem: {
                title: 'No Annual Fee',
                subTitle: 'Don’t worry about having to pay an annual fee. ',
            },
            compareToOtherCards: {
                title: 'Compare to Other Cards',
            },
            guaranteedLowestRate: {
                title: 'Guaranteed Lowest Rate',
                subTitle: "We guarantee the lowest APR, or we'll send you $25. {submitProof} if you have a credit card with a lower APR. Introductory, temporary & promotional APRs don't apply.",
                submitProof: 'Submit Proof',
            },
            helocGuaranteedLowestCost: {
                title: 'Guaranteed Lowest Cost',
                subTitle:
                    'We guarantee the lowest cost HELOC. {submitProof} if you have a HELOC offer with a lower cost. {strong} or send you $100. Introductory, temporary & promotional offers don’t apply.',
            },
            monthlyPaymentListItem: {
                title: `What's My Monthly Payment?`,
                subTitle: `You have two options for monthly payments - standard credit card minimum or fixed monthly payments. <b>You will be able to choose your payment once your acount is activated.</b>`,
            },
        },
        footer: {
            availabilityDisclosure: `
                <p>Subject to credit approval. Certain terms and conditions may apply. Terms may vary by applicant and are subject to change. Availability limited to certain states. See <a href="/licenses">aven.com/licenses</a> for more details. Not available for multi-unit homes. Flood insurance may be required if your property is located in a flood zone. Not available for home purchase.</p>
            `,
            availabilityDisclosureRefinance: `
                <p>Subject to credit approval. Certain terms and conditions may apply. Terms may vary by applicant and are subject to change. Availability limited to certain states. See <a href="/licenses">aven.com/licenses</a> for more details. Not available for multi-unit homes. Flood insurance may be required if your property is located in a flood zone. Not available for home purchase.</p>
            `,
            extended: `
                  <p>Note for customers in Alabama, Alaska, Arkansas, Idaho, Iowa, Kansas, Louisiana, Nebraska, New Hampshire, New Mexico, North Dakota, Oklahoma, Oregon, South Dakota and Wyoming: We currently only offer lines up to $100,000. Check back soon for higher line sizes.</p>
                  <p><a class="text-decoration-underline" target="_blank" rel="noopener" href="/docs/SpanishDisclosure.pdf">Notice to Consumers about all Languages. Para Español, consulte este documento.</a></p>
                  <p>${shared.cardsArrangedBy}</p>
                  <p>${shared.cardIssuedBy}</p>
                  <p>${shared.forLicensingInformation}</p>
            `,
            press: 'Press',
            privacyPolicy: 'Privacy',
            licenses: 'Licenses',
            education: 'Credit Education',
            tos: 'Terms of Service',
            pif: 'Pay It Forward',
            pricing: 'Pricing & Terms',
            privacyNotice: 'Privacy Notice',
            earlyHelocDisclosure: 'Early HELOC Disclosure',
            waitlistTerms: 'Waitlist Terms',
            eSign: 'E-Sign Consent',
            disclosures: 'Disclosures',
            company: 'Company',
            support: 'Support',
            about: 'About Us',
            help: 'Help',
            careers: 'Careers',
            pressMedia: 'Press & Media',
            paymentCalculator:
                '*Calculations are estimates based on information you entered, and are not guaranteed. Estimates are for illustrative and educational purposes only. Estimates assume no additional transactions are made; adding additional balances will increase your monthly payment and length of repayment.',
            appDownload:
                'Apple and the Apple logo are trademarks of Apple Inc., registered in the U.S. and other countries. App Store is a service mark of Apple Inc., registered in the U.S. and other countries. Google Play and the Google Play logo are trademarks of Google Inc.',
        },
        navbarMarketing: {
            cta: 'Join the waitlist',
            signin: 'Sign In',
            careers: 'Careers',
            about: 'About Us',
            whoWeAre: 'Who We Are',
            home: 'Card',
            support: 'Support',
            forgotCode: 'Forgot Code',
            checkOffers: 'Check Offers',
            dashboard: 'Dashboard',
            app: 'App',
            resources: 'Resources',
            education: 'How It Works',
            contact: 'Contact Us',
            reviews: 'Reviews',
            testimonials: 'Testimonials',
        },
        faq: {
            fiftyPercentGuarantee: {
                question: 'How will you guarantee that my credit card interest will be cut in half?',
                answerHtml:
                    "Aven's low rates are set to beat leading credit cards' non-promotional interest rates by at least 50% if not more.  If your Aven Card offer does not cut it by at least 50%, please take a picture of your credit card statement showing interest rate and send it to us.<sup>6</sup>  We will make good on our promise.",
            },
            mortgage: {
                question: 'I just went through my mortgage process. Will this take that long?',
                answerHtml:
                    'No. Most Aven Customers get through our process in 15 minutes to set up a Notary Appointment by connecting your bank account and allowing Aven to verify income. For other forms of verification, you can schedule Notary Appointments in a day or two. After Notarization, there is a mandatory three day rescission period required by law. You can start using the card or draw funds from the credit line shortly thereafter.',
            },
            saveMore: {
                question: 'How can I save on interest even more?',
                answerHtml:
                    'Make Aven your primary credit card. Transfer as many balances as many times as you want under your credit limit. There is no balance transfer fee. We find our best customers maximizing the benefit of super-low rates on their Aven Card by transferring balances multiple times.',
            },
            helocOrCc: {
                question: 'Is this a HELOC or a Credit Card?',
                answerHtml:
                    'It’s actually both! The Aven Card is a HELOC that you can use just like a credit card - anywhere you can use a credit card. Also, like a HELOC, you can use Aven’s CashOut feature to conveniently deposit cash directly to your current bank account. It’s the low rates of a HELOC, with the convenience and simplicity of a credit card.',
            },
            homeImpact: {
                question: 'If I miss a payment, does this impact my home?',
                answerHtml:
                    'No. Paying late or a few missed payments will not put your home at risk or in default. If you find you can’t make your payments, let us know and we can work with you on alternative payment arrangements. We don’t have high late fees and we won’t increase your interest rate for late or missed payments. As a lienholder we do have remedies available if you default, but foreclosure is our last option. Keep in mind that with our low APRs, your monthly bill could be much smaller than what you pay today.',
            },
            homeValue: {
                question: 'Does it matter how much my house is worth?',
                answerHtml:
                    'There is no minimum house value to apply. We will use the estimated value of your home, your outstanding mortgage balance, and your income to determine your credit limit.',
            },
            homeEquityLoan: {
                question: 'Why not get a home equity line of credit instead?',
                answerHtml:
                    'Home equity lines of credit often come with high fees (usually over $1000), minimum advances, minimum credit limits (often at least $50,000), and take 4-6 weeks to close.<sup>4</sup> <br/> <br/> With Aven, you can get your card in just a few days in the mail, and there are no minimums so you can use what you want, when you want it.',
            },
            lostJob: {
                question: 'What happens if I lose my job?',
                answerHtml:
                    'We believe in long term relationships. For cases where our loyal customers have had a medical emergency or have lost their jobs, we have a special Aven-Hardships program to help.  We believe that helping you today  helps us tomorrow. Reach out to learn more!',
            },
            minCredit: {
                question: 'What is the minimum credit score?',
                answerHtml: '650 and above.',
            },
            howLong: {
                question: 'How long does it take to get my card?',
                answerHtml: `The application process and approval process can take as little as 15 minutes. Because this card is backed by your home, there is also a 3 day rescission period where you can cancel without any questions. Your card typically arrives within ${ESTIMATED_CARD_MAIL_ARRIVAL_BUSINESS_DAYS} days.`,
            },
            fees: {
                question: 'Are there any fees?',
                answerHtml:
                    'Unlike most HELOCs, we don’t charge an annual fee, recording fee, non-use fee, or origination fee. There is also no fee for your annual balance transfer. We do have a small fee for late payments ($29) and NSF payments ($15). Our Cash Out (draw to bank account) fee is 2% of amount drawn to bank account',
            },
            fees99: {
                question: 'Are there any fees?',
                answerHtml:
                    'Unlike most HELOCs, we don’t charge an annual fee, recording fee or non-use fee, and our origination fee is only $99. There is also no fee for your annual balance transfer. We do have small fees for Cash Out (draw to bank account) 2% of amount drawn to bank account, late payments ($29) and returned payments ($15).',
            },
            criteria: {
                question: 'What are the criteria to qualify for this credit card?',
                answerHtml:
                    '1) Own and have equity in your home. <br/> 2) Have an income that can support your monthly bill – we don’t want you to get into debt that you can’t afford. <br/> 3) Meet certain credit criteria, including credit score, income, and property value requirements.',
            },
        },
        formSelectAutocomplete: {
            noOptions: 'No results found for',
            startSearching: 'Start typing to search',
        },
        formFieldAddress: {
            placeholder: {
                completeAddress: 'Street Address, City, State',
                street: 'Street Address',
                unit: 'Apt #',
                city: 'City',
                state: 'State',
                postalCode: 'Zip',
            },
            enterAddressManually: 'Enter address manually',
            validation: {
                addressNotFound: 'Address Not Found. Please check your input.',
                CAResidentOnly: 'Must be a resident of California',
            },
            googleError: 'Could not find valid location, please try again',
        },
        formFieldSsn: {
            placeholder: 'Full Social Security Number',
            placeholderLast4: 'Last 4 of Social Security',
        },
        formFieldDob: {
            placeholder: 'Date of Birth',
            focusPlaceholder: 'DD/MM/YYYY',
        },
        formFieldPhone: {
            placeholder: 'Date of Birth',
            focusPlaceholder: 'DD/MM/YYYY',
        },
        applicationForm: {
            ambiguousResidenceType: {
                question: 'Is this address on your ID, pay stub, or taxes?',
                primary: 'Yes',
                nonPrimary: 'No',
            },
            residenceType: {
                question: 'Do you live here?',
                primary: 'Yes (at least 2 weeks a year)',
                nonPrimary: 'No (investment or rental property)',
            },
            sectionHeader: {
                income: 'Monthly Income',
                identity: 'Identity Verification',
                personal: 'Personal Details',
            },
            legal: {
                validNameFromIDOrPassport: 'Use your legal name from a valid state ID or passport',
                statedIncome: 'Verified later by connecting your bank account.',
                doesNotAffectFicoHtml: 'This will NOT affect your credit score.',
                softPullAndFloodAgreementsHtml:
                    "By continuing, you consent to our using of consumer reporting agencies, like Experian and Equifax, to make a soft credit inquiry and to verify your employment and income. You also agree to our <a href='/docs/ESIGNConsent.pdf' target='_blank'>E-SIGN Consent</a>, <a href='/privacy' target='_blank'>Privacy Policy</a>, and <a href='/docs/TermsOfUse.pdf' target='_blank'>Terms of Use</a>.",
                termsAndCondition:
                    "By continuing, you agree to our <a href='/docs/ESIGNConsent.pdf' target='_blank'>E-SIGN Consent</a>, <a href='/docs/PrivacyPolicy.html' target='_blank'>Privacy Policy</a>, and <a href='/docs/TermsOfUse.pdf' target='_blank'>Terms of Use</a>.",
                jointDisclaimer: 'Applicants, if married, may apply for a separate account.',
                wisconsinDisclaimer:
                    'No provision of a marital property agreement (including a Statutory Individual Property Classification Agreement pursuant to Wis. Stat. Ann. §766.587), a unilateral statement under Wis. Stat. Ann. §766.59 or a court decree under Wis. Stat. Ann. §766.70 adversely affects the interest of the creditor unless the creditor, prior to the time the credit is granted, is furnished a copy of the agreement, statement or decree or has actual knowledge of the adverse provision when the obligation to the creditor is incurred.',
                incomeDisclaimer:
                    'By clicking "{ctaText}," you agree that your income as stated above is accurate, sufficient to make their mortgage payments, stable, and unlikely to be discontinued or reduced.',
                serviceMark: shared.cardsArrangedBy,
                warning: 'You have 30 days from the date you submit personal information to complete your application.',
                avenAssetCard:
                    'The Aven Asset Card is a secured card. When you use the card, you are automatically providing Coastal Community Bank with a security interest in each good you purchase as defined in the UCC Section 9-102.',
            },
            placeholder: {
                firstName: 'First Name',
                lastName: 'Last Name',
                statedIncome: 'Monthly Income (After Tax)',
                dateOfBirth: 'Date of Birth (MM/DD/YYYY)',
                dob: 'Date of Birth (MM/DD/YYYY)',
                email: 'Email Address',
                phone: 'Mobile Number',
            },
            validation: {
                invalidAddress: 'Invalid address',
            },
            defaultCta: 'Instantly Check Offers',
            proveDisclosure:
                "You authorize your wireless carrier to use/disclose info about your account & device to Aven Financial, Inc. and its affiliates & service providers. See <a href='/docs/PrivacyPolicy.html' target='_blank'>Privacy Policy</a> for details.",
        },
        personalLoanBalanceTransferForm: {
            placeholder: {
                fullName: 'Full Name',
                billingAddressData: 'Billing Address',
                accountNumber: 'Account Number',
                bankName: 'Bank Name',
                transferAmount: 'Transfer Amount',
            },
            balanceInfo: '{amount} balance as of {date}',
            cta: 'Schedule Transfer',
            confirmOwnership: 'I certify that I own this account',
            error: {
                ownership: 'Please certify that you own this account.',
            },
        },
        balanceTransferModal: {
            error: {
                genericError:
                    'An error came up while processing your balance transfer request. Please refresh the page and try again. If this problem persists, please reach out to our support team for assistance with your balance transfer.',
                invalidAccountNumberFail:
                    'Sorry, that account number or bank name does not match our records. Please try again. If this problem persists, please reach out to our support team for assistance with your balance transfer.',
            },
            loadingTitle: 'Submitting...',
            intro: {
                title: 'Transfer From...',
                potentialSavings: 'Save {amount}%',
                footnote:
                    'Savings represent the difference in APR. APR savings are calculated based on the difference between the estimated APR for your current loan or credit card compared to your Aven Card. Note that your total credit limit is {creditLimit}.',
                otherAccountTitle: 'New Account',
            },
            iframe: {
                titleOther: 'New Account',
                ccOption: 'Credit Card',
                plOption: 'Personal Loan',
                footnote: "We won't process your balance transfer until after your 3 business-day cancellation period has expired. Note that your total credit limit is {creditLimit}.",
            },
            confirmation: {
                title: 'Transfer Scheduled',
                subTitle: "Although your transfer is scheduled, we won't start the transfer until your 3 business-day cancellation period has expired.",
                additionalAccountsCta: 'Transfer from other Accounts',
                doneCta: 'Done',
            },
        },
        emailVerificationHelpModal: {
            title: 'Help',
            subTitle: 'Verifying your work email allows us to verify employment, saving you the trouble of further income verification.',
            tryAgain: 'Try Again',
            goBack: 'Go Back',
        },
        feedbackBox: {
            placeholder: 'Feedback or Comments?',
            emailPlaceholder: 'Email Address',
            cta: 'Send Message',
            successMessageTitle: 'Message Sent',
            successMessageBody: 'Thank you for your feedback. It really helps us build a better product for you. We will reach back shortly.',
        },
        fixedTermPaymentOffer: {
            monthly: 'Monthly Payment',
        },
        incomeDocumentVerificationPlaidPrompt: {
            instantVerification: 'Instant Verification',
            tryNow: 'Try now.',
            titleNotInVerification: 'Don’t want the hassle of uploading documents? Try securely signing into your bank account.',
            titleInVerification: 'Our team is reviewing your documents and should get back to you within 1-2 days. If you don’t want to wait, try securely signing into your bank account.',
        },
        trustPilotWidget: {
            rating: '{score}/5',
            readReviews: 'Read {numReviews} Reviews',
        },
        trustPilotScore: {
            reviewsScore: '<span class="tw-font-bold">{score}</span> out of 5 Stars based on <span class="tw-font-bold">{numberOfReviews} reviews</span>',
        },
    },
    global: {
        // Page not found
        pageNotFound: {
            sectionTitle: 'Page not found',
            title: "Sorry, looks like we sent you the wrong way. Let's fix that.",
            message: 'Head to the {homePageLink} to get started and learn more about the Aven Credit Card.',
        },
        legal: {
            documentsHtml:
                "By continuing, you authorize us to make a soft pull credit inquiry to a credit reporting agency, like Experian, and you agree to our <a href='/docs/ESIGNConsent.pdf' target='_blank'>E-SIGN Consent</a>, <a href='/docs/PrivacyPolicy.html' target='_blank'>Privacy Policy</a>, <a href='/docs/TermsOfUse.pdf' target='_blank'>Terms of Use</a> and <a href='/docs/AvenWaitlistTerms.pdf' target='_blank'>Waitlist Terms</a>",
            doesNotEffectFico: 'This won’t affect your credit score.',
            experian: 'By continuing, you authorize us to make a soft pull credit inquiry to a credit reporting agency, like Experian.',
        },
        loadingMessage: {
            loading: 'Loading...',
            verifying: 'Verifying...',
            saving: 'Saving...',
        },
        cta: {
            continue: 'Continue',
            next: 'Next',
            submit: 'Submit',
        },
        errors: {
            generic: 'Sorry, there was a problem with the network. Please refresh the page and try again. Thanks!',
            badRequest: 'An error came up while processing your information! Please review that the information you submitted is correct. You could also try refreshing the page. Thanks!',
            confirmAddress: 'Our system noticed an issue with your {addressType} address. Dont worry, we fixed it. Please confirm the updated address and continue.',
            unsupportedFileError: 'Sorry, we could not determine the type of file you uploaded. Please try again.',
            priorDeniedApplication:
                'You have an existing application from {date}, that we could not approve. You can apply again in 6 months after the date listed above to be reconsidered for credit approval.',
            priorDeniedApplicationNoDate: 'Our records indicate you previously applied and your application was denied. You can apply again in 6 months to be reconsidered for credit approval.',
        },
    },
    customValidationRules: {
        stateNotSupported: "We currently don't offer the Aven card in the state of {state}",
        CAResidentOnly: 'Must be a resident of California',
        twilioCode: 'Code must be exactly 4 digits',
        ssnLast4: 'Last 4 SSN digits are required',
        ssnFull9: 'Full 9 digit SSN is required',
        // Purposefully vague to avoid telling a malicious party to keep trying with different SSN values.
        ssnCompletionError: 'Please confirm all of your information is correct and try again',
        duplicateSsnError: 'It looks like you entered your information twice. Please provide the information for your joint applicant.',
        priorApplicationFoundCoApplicant: 'It looks like we found a previous application for your joint applicant. Please withdraw the previous application or proceed without a joint applicant.',
        incompleteAddress: 'Incomplete address',
        underAge: 'You must be over 18 years old',
        coApplicantUnderAge: 'The joint applicant must be over 18 years old',
        notValidPhoneNumber: 'Not a valid phone number',
        homeNotFound: 'We are unable to find your home',
        invalidHomeLocationDueToFloodZone: 'Sorry, we do not currently offer our card in special flood hazard areas',
        unknownFloodZone: 'We could not determine your home flood zone, please contact us',
        prohibitedCounty:
            "Unfortunately, we have paused recordings in your county because of county procedures.<br />We are working with your county recorder's office to continue as soon as possible.<br />We realize this is inconvenient and appreciate your patience.",
        emailAlreadyExists: 'An account already exists for this email address',
        phoneNumberAlreadyExists: 'An account already exists for this phone number',
        invalidCurrency: 'Please enter a valid amount',
        currencyTooSmall: 'Minimum allowed amount is {minAmount}',
        currencyTooLarge: 'Maximum allowed amount is {maxAmount}',
        aprInvalid: 'Invalid APR. E.g, 5.49%',
        aprTooSmall: 'Minimum allowed APR is {minApr}%',
        aprTooLarge: 'Maximum allowed APR is {maxApr}%',
        phoneNumberBelongsToPrimary: 'This phone number belongs to the primary applicant',
        phoneNumberBelongsToCoapplicant: 'This phone number belongs to the joint applicant',
        invalidEmailOrPassword: 'Invalid email or password, please verify your information and try again',
        licensePlateTooLong: 'License plate number must be 8 characters or less',
        licensePlateTooShort: 'License plate number must be at least 4 characters',
        invalidLicensePlate: 'License plate number must only contain letters, numbers and spaces',
        propertyMissingUnitNumber: 'Property address is missing unit number',
        personalMissingUnitNumber: 'Personal address is missing unit number',
    },
    validation: {
        alpha: 'May only contain alphabetic characters',
        alpha_num: 'May only contain alpha-numeric characters',
        alpha_dash: 'May contain alpha-numeric characters as well as dashes and underscores',
        alpha_spaces: 'May only contain alphabetic characters as well as spaces',
        between: 'Must be between {min} and {max}',
        confirmed: 'Confirmation does not match',
        digits: 'Must be numeric and exactly contain {length} digits',
        dimensions: 'Must be {width} pixels by {height} pixels',
        email: 'Must be a valid email',
        excluded: 'Is not a valid value',
        ext: 'Is not a valid file',
        image: 'Must be an image',
        invalidDateFormat: 'Format must be MM/DD/YYYY',
        integer: 'Must be an integer',
        length: 'Must be {length} characters long',
        max_value: 'Must be {max} or less',
        max: 'May not be greater than {length} characters',
        mimes: 'Must have a valid file type',
        min_value: 'Must be greater than {min}',
        min: 'Must be at least {length} characters',
        numeric: 'May only contain numeric characters',
        oneOf: 'Is not a valid value',
        regex: 'Format is invalid',
        required_if: 'This field is required',
        required: 'This field is required',
        size: 'Size must be less than {size}KB',
    },
    education: {
        articleTitles: {
            homeEquityLineOfCreditHelocCardHowItWorks: 'Home Equity Line of Credit (HELOC) Card: How it Works',
            homeEquityLineOfCreditHelocCardWhatIsIt: 'Home Equity Line of Credit (HELOC) Card: What is it?',
            theFastestWayToGetAHeloc: 'The Fastest Way to get a HELOC',
            homeEquityCreditCardHowToGetOne: 'Home Equity Credit Card: How to Get One',
            whenAreHelocsHomeEquityLinesOfCreditAGoodIdea: 'When are HELOCs (Home Equity Lines of Credit) a Good Idea?',
            whatIsAHomeEquityLineOfCreditHelocABeginnersGuide: 'What is a Home Equity Line of Credit (HELOC)? A Beginner’s Guide',
            homeEquityLinesCreditHelocsVsMortgagesSimilaritiesDifferences: 'Home Equity Lines of Credit (HELOCs) vs. Mortgages: Similarities & Differences',
            howAreHelocRatesDetermined: 'How are HELOC rates determined?',
            refinancingAHeloc: 'Refinancing a HELOC, how does it work?',
            howToGetLowestRate: 'How to get the lowest HELOC (Home Equity Line of Credit) rates?',
            fixedOrVariable: 'HELOC Rates: Are They Fixed or Variable?',
            helocOnRentalProperties: 'How to Get a HELOC on a Rental or Investment Property',
            securityTips: 'Mobile Banking Security Tips',
            homeDepotVsAven: 'Home Depot Credit Card vs Aven Home Equity Credit Card',
            whatIsAnAvenCard: 'What is an Aven Card?',
            whatCreditScoreIsNeeded: 'What credit score is needed for the Aven card?',
            canYouGetCashFromAvenCard: 'Can you get cash from Aven card?',
        },
        articleSubtitles: {
            homeDepotVsAven: 'Which card is best for you?',
            whatIsAnAvenCard: 'The best credit card for homeowners',
            whatCreditScoreIsNeeded: 'The Aven card is for responsible homeowners',
            canYouGetCashFromAvenCard: 'Yes! See the various options to get cash from Aven card',
        },
        articleDescriptions: {
            homeEquityLineOfCreditHelocCardHowItWorks:
                'Once a HELOC card arrives in the mail, you can start making purchases with it right away. You can use your card at any merchant that accepts major credit cards, like Visa and MasterCard.',
            homeEquityLineOfCreditHelocCardWhatIsIt: 'A HELOC Card allows you to access funds immediately to pay for items and services when you need them. This card acts just like a credit card.',
            theFastestWayToGetAHeloc: 'A HELOC Card is backed by your home’s equity, just like a traditional HELOC, but it can take as little as 15 minutes to qualify.',
            homeEquityCreditCardHowToGetOne:
                'To apply for a HELOC Card, you will need to meet a few requirements like having a stable income, a credit score of around 650 and around 20% equity in your home.',
            whenAreHelocsHomeEquityLinesOfCreditAGoodIdea:
                'A HELOC may be a good idea if you are looking to use the funds for home improvements or renovations, which will increase the value of your home.',
            whatIsAHomeEquityLineOfCreditHelocABeginnersGuide:
                'A HELOC is a line of credit that is backed by the equity you have in your home, meaning your house is used as collateral to secure the loan.',
            homeEquityLinesCreditHelocsVsMortgagesSimilaritiesDifferences: 'Mortgages and HELOCs (Home Equity Lines of Credit) are two types of loans that are both backed by your home.',
            howAreHelocRatesDetermined:
                'Have you decided a Home Equity Line of Credit (HELOC) is the best type of loan for you? If so, it’s time to shop around for the best interest rate, which can vary from lender to lender.',
            refinancingAHeloc: 'It is possible to refinance a HELOC. If you are thinking about it, you have a few options to choose from, and you may be able to keep your HELOC.',
            howToGetLowestRate: 'Are you trying to determine whether or not a HELOC (Home Equity Line of Credit) is suitable for you and looking for the best deal?',
            fixedOrVariable:
                'As with all loans, this type of transaction isn’t free and it’s important to shop around for a competitive interest rate. But you may be wondering: Are HELOC rates fixed or variable? Well, they can be both. Let us explain.',
            helocOnRentalProperties: 'What if you’re a real estate investor and want to access extra funds for a property you rent out?',
            securityTips: 'Security tips and tools to help keep you safe.',
            homeDepotVsAven: 'Which card is best for you?',
            whatIsAnAvenCard: 'The best credit card for homeowners',
            whatCreditScoreIsNeeded: 'The Aven card is for responsible homeowners',
            canYouGetCashFromAvenCard: 'Yes! See the various options to get cash from Aven card',
        },
        content: {
            helocOnRentalProperties:
                '<p>Getting a home equity line of credit (HELOC) to help pay for renovations on your personal property is fairly straightforward, but what if you’re a real estate investor and want to access extra funds for a property you rent out? Keep reading to learn how HELOCs on rental properties work, how to get one and what it can be used for. </p>' +
                '<p><strong>Key Takeaways</strong></p>' +
                '<ul><li>It is possible to open a HELOC on a rental property, just like you can on your primary residence.</li>' +
                '<li>However, not all lenders offer HELOCs on investment properties because, in the event of financial hardship, the owner is more likely to prioritize their primary home over a rental property. </li>' +
                '<li>As a result, qualifying for a HELOC on an investment property may be more challenging and requires a higher credit score than for a HELOC on your primary residence.</li></ul>' +
                '<p><strong>How Do HELOCs Work?</strong></p>' +
                '<p>A HELOC allows you to tap into the equity you’ve acquired in your home to help pay for home improvements and renovations. Mathematically, the amount is the current market value of the property minus the outstanding balance on the primary mortgage. A HELOC carries less risk for lenders than other types of loans because the home is used as collateral in the case of a default and, therefore, comes with competitive interest rates for the borrower. </p>' +
                '<p>A HELOC is made up of two phases: A draw period that typically lasts five to 10 years and a repayment period that lasts between 10 and 20 years. During the draw period, you can use the line of credit to borrow cash, repay it, and borrow more, much like an ordinary credit card. You only have to pay back what you spend, plus interest. During the repayment phase, you can no longer withdraw funds and start paying down debt in the form of monthly principal and interest payments. To learn more about how HELOCs work, take a look at <a href="https://www.aven.com/education/what-is-a-home-equity-line-of-credit-heloc-a-beginners-guide">this article</a>.</p>' +
                "<p>If you're a real estate investor, you may be wondering if it's possible to take out a HELOC on a rental property. The short answer is yes. However, there's a lot to consider before applying.</p>" +
                '<p><strong>HELOCs on an Investment Property</strong></p>' +
                '<p>A HELOC on an investment property functions in the exact same way as a HELOC on a personal property, but the process of acquiring one can prove much more challenging due to the increased risk for the lender. </p>' +
                '<p class="section-header">Finding a Lender</p>' +
                '<p>Not all lenders offer HELOCs on rental properties because the risk of default is much higher than with personal properties. In the event of a financial hardship, the borrower is much more likely to prioritize paying the loan on their primary property before paying down debt on a secondary property, whether that’s in the form of a HELOC or mortgage. In the case of a foreclosure, the sale proceeds would first go toward paying off the mortgage before any other liens on the property, such as a HELOC.</p>' +
                '<p>The best way to find a lender that offers investment property HELOCs is to leverage your existing network of real estate investors who have already qualified for a HELOC. Online forums and real estate investing groups may also be helpful.</p>' +
                '<p class="section-header">Requirements for Rental Property HELOCs</p>' +
                '<p>Due to the increased risks discussed above, rental property HELOCs are more expensive and come with stricter requirements than for HELOCs on primary residences. The interest rate is typically higher and the borrower must shell out cash for an additional appraisal and title search fees, among other costs. </p>' +
                '<p>To qualify, you’ll need a FICO credit score of 720 or above, versus just 620 for a HELOC on a personal property. The lender will also consider your combined loan-to-value ratio (CLTV) and debt-to-income ratio (DTI), both of which will need to be low. </p>' +
                '<p>Unlike a HELOC on a personal property, lenders oftentimes require the borrower to wait a full year after purchasing the rental property before being eligible for a HELOC so they can prove that they have trustworthy tenants that will make payments on time and provide sufficient cash flow. The lender may also require you to have a large cash reserve on hand that’s equivalent to 18 to 24 months of payments.</p>' +
                '<p><strong>What Can an Investment Property HELOC be Used For?</strong></p>' +
                "<p>A HELOC on an investment property can be used in the exact same way as a HELOC on a personal property. They are most commonly used to pay for renovations and improvements to the property, but can also be used to consolidate high-interest debt, purchase a new property, or anything else you'd like. However, experts advise that HELOCs be used for investments that will grow your wealth over time, rather than for purchases that will not, such as cars or vacation trips.</p>" +
                "<p>Currently Aven’s product is not available for rental or investment properties. Are you considering applying for a HELOC for your primary residence? With Aven's HELOC Card, you can get approved in as fast as 15 minutes and have the card in your hands in a few days. <a href=\"http://aven.com\">Read more</a> to learn whether it's what you're looking for!</p>",
            howToGetLowestRate:
                "<p>Are you trying to determine whether or not a HELOC (Home Equity Line of Credit) is suitable for you and looking for the best deal? In that case, it's essential to understand what HELOC interest rates are based on, what affects them, and how to find the lowest ones. This article will explain how you can secure the best HELOC rates.</p>" +
                '<p><strong>Key Takeaways</strong></p>' +
                '<ul><li>Your HELOC rate is based on two main factors: the prime rate used by banks and a margin determined by your financial situation and history.</li>' +
                '<li>When determining your margin or markup, the lender will look at your credit score and CLTV, among other things.</li>' +
                '<li>To improve your chances of scoring a low HELOC interest rate, stay on top of your credit score and make sure you have enough home equity.</li>' +
                '<li>Keep in mind that different lenders will offer different rates, so make sure you shop around and consider all your options to find the best rates.</li></ul>' +
                '<p><strong>A quick look at how HELOC rates work</strong></p>' +
                '<p>The typical HELOC has an adjustable interest rate, which means it can rise and fall over the loan term. There are two main factors that determine the interest on your credit line – the prime rate used by your lender, which is variable, and a margin determined by your financial situation and history, which remains constant.</p>' +
                '<p>The prime rate, which is the interest commercial banks charge their biggest, most creditworthy clients, is largely dependent on the federal funds rate set by the monetary-policy setting committee at the Federal Reserve. So when the Fed decides to raise or lower the federal funds rate, your bank’s benchmark prime rate follows in the same direction, and so does your HELOC rate. </p>' +
                "<p>As for the margin, your lender will look at your payment history, CLTV (Combined Loan-to-Value ratio), and FICO credit score because they want to make sure that you will be able to keep up with payments when the time comes to repay what you've borrowed. Therefore, the riskier the lender finds it to lend you money, the higher the rates you will get. Lenders often look for a credit score of at least 620 and a CLTV under 80%, although this will vary by lender.</p>" +
                '<p>The amount of money you want to borrow and the duration of your HELOC can affect your rates as well. You can learn more about what HELOC rates are based upon in <a href="http://aven.com/education/how-are-heloc-rates-determined">this article</a>.</p>' +
                '<p><strong>How do I secure the lowest rate?</strong></p>' +
                "<p>Now that you know how HELOC rates generally work let's look at some strategies for securing a low rate.</p>" +
                '<p class="section-header">Shop around and compare</p>' +
                '<p>When trying to find the best HELOC rate, the first thing you need to do is check with several lenders and compare what they offer. Rates can vary greatly depending on the lender, so make sure to consider all your options. Look at big national banks, smaller local banks, credit unions, and online lenders to find the best one. Keep in mind that you need to look at more than the initial rate to get a good sense of the most beneficial offer. Check how long the initial rate will last, what will happen after it expires, and the maximum rate. Also, look up any closing costs, balloon payments, inactivity fees, annual fees, and other hidden fees. Consider all these factors when comparing lenders. Keep in mind that due to the COVID-19 pandemic, some lenders have halted HELOC applications, and others have significantly raised their requirements. </p>' +
                '<p class="Stay on top of your Credit Score"></p>' +
                "<p>Another crucial step to securing a low HELOC rate is making sure your FICO credit score is the best it can be. Whether your score is excellent or below average can significantly affect the margin your lender will add on top of the prime rate, so before applying for a HELOC, you will want to do what you can to maintain good credit. Check that you don't have any old debt weighing your score down, and make sure to make payments on time. Accounts being sent to collections and late payments affect your score; the later, the worse. Don't take on any new debt right before your HELOC application, as this can negatively affect your credit as well. Also, don't close any old credit accounts right before applying, even if they are fully paid off, since this would reduce the total amount of revolving credit available to you. If your credit utilization ratio, or the total debt you're carrying divided by the total credit available to you, increases, it may negatively impact your credit score.</p>" +
                '<p class="section-header">Have enough home equity</p>' +
                '<p>Make sure you have enough equity in your home before applying if you want your rate to be as low as possible. The more equity you have, the less risky you will look to lenders. High equity means a low CLTV or Combined Loan-to-Value ratio. Your CLTV is calculated by adding up your loan balances and dividing by the appraised value of your home. Many lenders want you to have around 20% equity and a CLTV of under 80%. However, it varies, and some may accept as low as 10-15% equity. If you have very little equity, you may want to consider waiting and trying to pay off a bit more of your mortgage before applying for a HELOC.</p>' +
                '<p><strong>Consider an alternative</strong></p>' +
                '<p>One alternative to a traditional HELOC is a HELOC Card. A HELOC Card works just like any other credit card on the market and allows you to access funds immediately to pay for items and services when you need them. However, unlike other credit cards, it is backed by your home equity, just like a HELOC loan. This can lead to significantly lower interest rates compared to other credit cards.</p>' +
                '<p>If you are looking for a more affordable alternative to a HELOC, a HELOC Card could be beneficial as it may come with significantly lower fees. For example, you could be avoiding closing costs, appraisal fees, annual fees, and inactivity fees. Also, if you use your HELOC Card to pay for home improvements, the interest on your HELOC Card may be tax-deductible.</p>' +
                '<p>The Aven HELOC Card is a credit card in the front, home equity in the back. Aven is the world\'s first home equity backed credit card and combines the low interest rates of a home equity line of credit with the flexibility of a credit card. <a href="http://aven.com">Find out more!</a></p>',
            fixedOrVariable:
                '<p>A Home Equity Line of Credit (HELOC) is a great option to free up cash for home improvements or renovations by allowing you to use your home equity as a line of credit. As with all loans, this type of transaction isn’t free and it’s important to shop around for a competitive interest rate. But you may be wondering: Are HELOC rates fixed or variable? Well, they can be both. Let us explain.</p>' +
                '<p><strong>Key Takeaways</strong></p>' +
                '<ul><li>The interest rate on a HELOC is generally variable, which means it moves up and down in accordance with the benchmark rate it is tied to. </li>' +
                '<li>The prime rate is the most commonly used benchmark rate for HELOCs. The prime rate is largely based on the federal funds rate, which is set by members of the Federal Reserve.</li>' +
                '<li>Some lenders may provide an option to convert a variable rate to a fixed rate, which can help you budget and keep monthly payments stable for an agreed-upon term.</li>' +
                '<li>Converting your HELOC to a fixed rate may come with fees or minimum withdrawal requirements, so check the conditions with your lender before deciding whether or not to convert.</li></ul>' +
                '<p><strong>Variable-Rate HELOCs</strong></p>' +
                "<p>The interest rate on a traditional HELOC is usually variable, meaning it won't necessarily be the same every month. To understand how and why HELOC rates vary, we first need to understand how the index rate works.</p>" +
                '<p><strong>Variable interest rates</strong> are tied to an index, or benchmark, rate and increase or decrease based on moves in that rate. The index most commonly used for HELOCs is the <strong>prime rate</strong>, which the big banks charge their most creditworthy clients, who are generally large corporations. The prime rate changes over time because it is based primarily on the federal funds rate, which is set by the Federal Reserve’s Federal Open Market Committee (FOMC). The FOMC meets eight times a year to decide whether to raise or lower the federal funds rate based on current economic conditions. When the FOMC decides to change its rate, it affects the prime rate and, in turn, your HELOC rate.</p>' +
                '<p>In addition to the prime rate, the lender adds a markup based on your credit score, combined loan-to-value ratio (CLTV) and payment history. Generally, the lower your CLTV ratio and higher your credit score, the better your rate will be. </p>' +
                '<p>When shopping around for a HELOC, you may see offers like "prime+0%" or  "prime-1%". These are introductory or limited-time offers, so your rate will go up after a predetermined amount of time. Also keep in mind that, even if your lender offers you a low initial rate, the FOMC could increase the prime rate several times in a row, leading to an increase in your HELOC rate.</p>' +
                '<p><strong>Fixed-Rate Option</strong></p>' +
                '<p>Some lenders offer the option to convert all or part of your loan to a fixed interest rate, which means that your rate will stay the same for a determined term, no matter what direction the prime rate and federal funds rate moves. With a fixed rate, you can stabilize your monthly payments and avoid any uncertainty. Converting your HELOC can usually be done at closing or any time during the draw period. </p>' +
                '<p>The fixed-rate term could last anywhere from one to 30 years, depending on the lender. Some lenders only offer limited terms, or may require minimum withdrawals. You could also be asked to pay a small fee to convert your variable-rate HELOC  to a fixed interest rate. The longer it takes to pay off your debt, the more interest you will pay in the long run. \n</p>' +
                '<p>It may be worth it to convert  a variable-rate HELOC to a fixed rate if you think the prime rate will increase, or want more control over your monthly payments. However, if you choose to convert and the prime rate falls, you lose out on a lower rate. Ultimately, the decision depends on your personal situation and how comfortable you are with current economic conditions.</p>' +
                '<p>Are you considering a HELOC, but worried about the variable interest rate rising? With the Aven HELOC Card, your rate will never exceed 18%, no matter how high the prime rate goes. <a href="http://aven.com">Read about it here</a> and decide if it\'s right for you!</p>',
            refinancingAHeloc:
                '<p>When your HELOC draw period is coming to a close, and the repayment phase is upon you, you may find yourself struggling to keep up with payments. If you have opted only to pay interest during the draw period, you may be shocked at the new, higher monthly payments of interest and principal that are now required, and you may wonder, can a HELOC be refinanced? It is possible to refinance a HELOC. If you are thinking about it, you have a few options to choose from, and you may be able to keep your HELOC.</p>' +
                '<p><strong>Key Takeaways</strong></p>' +
                '<li>There are a few ways that a HELOC can be refinanced and payments decreased. Your options are to request a loan modification to your current HELOC, get a new HELOC, refinance your HELOC and mortgage into a new first mortgage, or get a home equity loan to pay off your HELOC.</li>' +
                "<li>You may want to refinance your HELOC if you're having a hard time making the monthly payments and want better loan terms. Refinancing could also help you lock in a low fixed interest rate. Another reason to refinance is that you may need more time and funds to make further home improvements. \n</li>" +
                '<li>Each refinance option comes with its own set of pros and cons. Carefully consider each option and your situation before deciding which alternative suits your needs the best.</li>' +
                '<p><strong>Reasons to Refinance a HELOC</strong></p>' +
                '<p>A HELOC is a type of revolving credit line backed by the equity you have in your home, which means your property is used as collateral and secures the line of credit. HELOCs are divided into two phases: the draw period, during which you can borrow money from the credit line, repay, and borrow again, and the repayment period, during which you pay back any outstanding balance. </p>' +
                "<p>During the draw period of your HELOC you are only required to pay interest on what you borrow. During the repayment period you can no longer withdraw funds, and you have to make monthly payments of principal and interest. If you've opted to pay only the interest during the draw period, you may be shocked at the increased monthly payments once you reach the repayment phase. Refinancing your HELOC may help make payments more affordable and could also allow you to take advantage of interest rates trending lower.</p>" +
                "Another reason to refinance is that you may need more time and funds to make further home improvements. If your home's value has increased, you may be able to get more money from your home equity to complete the additional projects. " +
                '<p><strong>Ways to Refinance a HELOC</strong></p>' +
                '<p>There are a few ways to refinance your HELOC. Consider your situation and your reason for refinancing, and make sure to check your options with several lenders before settling on a solution.</p>' +
                '<p class="section-header">Apply for a Loan Modification</p>' +
                "<p>The first option when you're having difficulties making payments is to request a loan modification. Your lender may lower the interest rate or increase the repayment term to make your monthly payments more affordable and help you avoid defaulting. </p>" +
                '<p>To get your loan modification request approved, you will have to prove to your lender that you will be able to make payments under the new, negotiated terms. Keep in mind that not all lenders offer loan modifications.</p>' +
                '<p class="section-header">Get a New HELOC</p>' +
                '<p>If you want to buy time before entering the repayment phase, you may choose to open a new HELOC. This allows you to extend the draw period and its interest-only payment requirement and avoid principal payments for a while longer. This option may also be good if your home value has increased and you want to make a few more adjustments or improvements to your home and need more time and funds to do so.</p>' +
                '<p>Getting a new HELOC and draw period may tempt you to borrow more, so make sure not to borrow beyond your means. Remember that you will eventually need to pay back your loan, and the longer you put it off, the higher your interest and your monthly payments will be. Also, keep in mind that because HELOC rates are adjustable, they may keep going up. </p>' +
                '<p class="section-header">Refinance Your Mortgage and HELOC Together</p>' +
                '<p>Another option is to refinance your mortgage and HELOC together into a new first mortgage. This way, you may be able to secure a significantly lower interest rate. First mortgage rates are generally lower than both HELOC rates and home equity loan rates, as the first mortgage lender is first in line to profit if you were to default on payments and your house was to get foreclosed on.</p>' +
                '<p>A new first mortgage usually has a fixed interest rate, making your monthly payments more predictable and plannable, but keep in mind that closing costs may be high.</p>' +
                '<p class="section-header">Get a Home Equity Loan to Pay Off Your HELOC</p>' +
                "<p>Your fourth option is to get a home equity loan, also called a second mortgage, to pay off your HELOC. Since a home equity loan is paid out in a lump sum, you can use it to pay off your HELOC, and you won't be tempted to keep borrowing, as can happen with a revolving credit line. You will also get the stability of a fixed interest rate and steady monthly payments that don't change. The downside can be closing costs and fees and the fact that a long, drawn-out payment period can increase the total interest you will have to pay.</p>" +
                '<p>Are you considering getting a HELOC? Aven can get you approved in as fast as 15 minutes. <a href="http://aven.com">Find out more</a> and decide if it is the right choice for you!</p>',
            howAreHelocRatesDetermined:
                '<p>Have you decided a Home Equity Line of Credit (HELOC) is the best type of loan for you? If so, it’s time to shop around for the best interest rate, which can vary from lender to lender. Keep reading to learn how HELOC interest rates are determined and what external factors affect them.</p>' +
                '<p><b>Key Takeaways</b></p>' +
                '<ul><li>Interest rates on HELOCs are determined by the prime lending rate plus a margin set by the lender. The prime rate is the lowest interest rate at which money can be borrowed, and is based on the federal funds rate, which is the overnight rate banks use to lend to one another.</li>' +
                '<li>The federal funds rate is set by members of  the Federal Reserve, the central banking system of the United States. When the Fed adjusts the federal funds rate, based on current economic conditions, the prime rate moves in accordance and affects HELOC rates.</li>' +
                '<li>The margin set by the bank depends on your personal financial situation, including your credit score and Combined Loan-to-Value ratio (CLTV). The higher your credit score and lower your CLTV, the lower your margin will typically be.</li></ul>' +
                '<p>Unlike the interest rate on mortgages or home equity loans, HELOC rates are adjustable rather than fixed. That means the rate will move up or down overtime based on the benchmark index it is tied to.</p>' +
                '<p>HELOC rates are based on two factors: A benchmark rate and your personal financial situation. Interest rates vary by lender, so make sure to do your research and shop around for the best rates before settling on a lender.</p>' +
                '<p><b>What is the benchmark rate?</b></p>' +
                '<p>Generally, lenders set HELOC rates based on the <b>prime lending rate</b>, which is the lowest interest rate at which banks will lend money to their most creditworthy clients, typically large corporations.  While the prime rate varies from lender to lender, the one published daily by the Wall Street Journal is the most commonly used. </p>' +
                '<p>So, how is the prime rate determined? It is largely influenced by the <b>federal funds rate</b>, which is the overnight rate banks use to lend to one another. The federal funds rate is set by the Federal Open Market Committee (FOMC), which is composed of 12 members of the Federal Reserve, the central banking system of the United States. When the FOMC raises the federal funds rate, the prime rate moves with it, which also affects your HELOC rate.</p>' +
                '<p>The FOMC meets eight times a year to review current economic and financial conditions, and decide whether or not to adjust the federal funds rate and, if so, in which direction. If the committee decides to increase the rate several times in a row, your HELOC rate will increase significantly along with it. For that reason, it’s important to check what the maximum interest rate is for your line of credit before settling on a HELOC from any lender. </p>' +
                '<p><b>What is the markup or margin? </b></p>' +
                '<p>The primary factor banks use to determine the markup is your credit score. The higher your credit score, the lower the added margin and, therefore, the lower your HELOC rate will be. However, your Combined Loan-to-Value ratio (CLTV) also matters. The CLTV is the ratio of all secured loans against your property to its value. The lower your CLTV, the lower your rate will typically be.</p>' +
                '<p>The second factor influencing your HELOC rate is your personal financial situation, which determines the markup percentage, or margin, that your lender will add on top of the benchmark rate. For example, if the prime rate is 3.25% and your markup is 2%, your HELOC rate would be 5.25%.</p>' +
                '<p>The amount of money you want to borrow and the duration of your HELOC can also affect your markup. Lower amounts can lead to lower rates, as can shorter drawing and repayment periods.</p>' +
                '<p><b>HELOC rate discounts</b></p>' +
                '<p>Occasionally, you may see offers such as "prime+0%," or even negative markup offers like "prime-1%." This is typically an introductory rate, or limited-time offer, meaning your markup will increase after the discount period agreed upon by the lender. Before settling on an offer, make sure to check what the rate will be after this introductory offer ends, or you may be in for a hefty unexpected increase.</p>' +
                '<p><b>Fixed-rate option</b></p>' +
                "<p>Some lenders may allow you to convert part, or all, of what you've borrowed into a fixed interest rate during the closing or draw period. This may come with certain limitations, such as a time limit or minimum withdrawal limit. Make sure to talk to your lender about the opportunity of switching between adjustable and fixed interest rates.</p>" +
                '<p>Are you considering a HELOC but worried about increasing rates? The interest on the Aven HELOC Card never exceeds 18%, no matter how high the prime rate goes. <a href="https://aven.com">Find out more</a> and decide if it`s right for you!</p>',
            homeEquityLineOfCreditHelocCardHowItWorks:
                '<p>Are you looking to free up some cash? If so, you have probably thought about getting a second mortgage in the form of a loan or home equity line of credit (HELOC). But did you know it is possible to access your funds instantly using a credit card? This article explains the concept of a HELOC Card, how it’s different from a traditional HELOC, as well as the benefits and disadvantages.</p>' +
                '<p><b>What is a HELOC?</b></p>' +
                '<p>Let’s first explore what a HELOC is. With this type of loan you borrow funds against the equity you’ve built in your home: the value minus the outstanding balance on the primary mortgage. You can use the funds whenever you need cash, and you do not pay interest on unused funds. Because your house is used as collateral in exchange for funds, HELOCs typically come with competitive interest rates versus other types of loans. However, HELOC interest rates are generally variable, meaning they move up or down based on a benchmark rate, like the federal funds rate. For that reason, it’s important to consider the maximum rate for your HELOC when deciding whether or not to open one.</p>' +
                '<p>There are two phases of a HELOC: The draw period, which typically lasts five to 10 years, and the repayment period, which usually lasts 10 to 20 years. During the draw period, you can use the line of credit much like an ordinary credit card: borrow cash, repay debt, and borrow more cash. You’re only required to pay for what you spend, plus interest. During the repayment phase, borrowing stops and the focus shifts to paying down debt in the form of monthly principal and interest payments.</p>' +
                '<p><b>What can a HELOC be used for?</b></p>' +
                '<p>HELOCs are oftentimes used to pay for home improvements and remodeling, which can help increase the value of the home. In addition, the interest on a HELOC may be tax-deductible if used to purchase, build or make significant improvements to a home.</p>' +
                '<p>However, there are risks to consider. Because the interest rate is variable, an increase could make it more difficult to keep up with monthly payments and, consequently, increase the risk of foreclosure.</p>' +
                '<p><b>What is a HELOC Card?</b></p>' +
                "<p>Now that you understand what a HELOC is, let's talk about HELOC Cards. A HELOC Card allows you to access funds immediately to pay for items and services when you need them. This card acts just like a credit card and can be used anywhere credit cards are accepted, but typically has a much lower interest rate because it is secured by your home equity. HELOC cards can also be used to transfer balances from other credit cards and personal loans.</p>" +
                '<p><b>Benefits of HELOC Cards</b></p>' +
                '<p>There are several benefits to a HELOC Card. You can use it anytime, anywhere, just like any other credit card, and get instant access to your line of credit. The interest rate on your card is usually lower than other credit cards because it uses your home as collateral. If you use your HELOC Card to pay for home improvements, the interest on your HELOC Card may be tax-deductible, and you never pay interest on unused funds.</p>' +
                '<p>Compared with a regular HELOC, a HELOC Card may have significantly lower fees, and you may be avoiding the high credit minimums that ordinary HELOCs require. It can also be much faster to receive a HELOC Card than to get a traditional HELOC.</p>' +
                '<p><b>Key takeaways</b></p>' +
                '<ul>' +
                '<li>A HELOC is a line of credit backed by your home equity. This type of loan is typically suitable for purchases that help build wealth, such as home improvements or remodelings, but can also be used for other purposes, such as paying off high-interest credit card debt or personal loans.</li>' +
                '<li>HELOCs commonly have low interest rates, though they’re variable and move up or down based on a benchmark rate.</li>' +
                '<li>A HELOC Card allows you to access your funds immediately when you need them and works just like any other credit card. It may be faster to acquire than a traditional HELOC and may also incur significantly lower fees.</li>' +
                '</ul>' +
                '<p>Are you looking for a HELOC Card? Aven is a credit card in the front, home equity in the back. Aven is the world\'s first home equity backed credit card and combines the low interest rates of a home equity line of credit with the flexibility of a credit card. <a href="https://aven.com">Click here</a> to learn more!</p>',
            homeEquityLineOfCreditHelocCardWhatIsIt:
                '<p>Now that you understand what a HELOC Card is, let’s dive into how it works. This article also explains where you can use your card and how it differs from the credit cards in your wallet.</p>' +
                '<p><b>How to get a HELOC Card</b></p>' +
                '<p>To use a traditional HELOC, you typically need to transfer funds to a checking account before making purchases or use special checks issued to you by the lender. This process can be complicated, time-consuming and frustrating. Some lenders will instead give you a HELOC Card to use at your convenience so you can access your funds immediately.</p>' +
                '<p>However, there are several requirements to be eligible to apply, including: proving you have a steady income, being listed on your home’s title, having a certain credit score and possessing a valid ID. If you meet the requirements, the application process typically looks something like this:</p>' +
                '<ol>' +
                '<li>Complete the online application, which asks questions about yourself and your home</li>' +
                '<li>Verify your income by providing pay stubs or a company verification letter</li>' +
                '<li>Meet with a notary to sign required documents</li>' +
                '<li>Receive your card within a few business days</li>' +
                '</ol>' +
                '<b>Where can HELOC funds be used?</b></p>' +
                '<p>Once your card arrives in the mail, you can start making purchases with it right away. You can use your card at any merchant that accepts major credit cards, like Visa and MasterCard.</p>' +
                '<p>Experts recommend using a HELOC Card for purchases that help increase wealth over the long run. For example, many homeowners tend to use their funds on home improvements or remodeling, which can significantly increase the property’s value. You can also use the card to transfer balances from personal loans or other credit cards.</p>' +
                '<p>While there are no restrictions on what products or services you can purchase with a HELOC Card, financial advisors typically advise against using your card on frivolous items or experiences, such as vacations or a new car, which are unlikely to increase your wealth. These types of purchases could even make it harder to pay off your HELOC balance in the future.</p>' +
                '<p><b>How to pay for charges made on a HELOC Card</b></p>' +
                '<p>Every month, you will receive a statement with your charges, much like you do with a traditional credit card. The monthly bill for a HELOC Card from Aven, for example, consists of 1% of the principal balance plus finance and interest charges on the average daily balance during your last billing cycle.</p>' +
                "<p>If you choose to pay only your minimum balance each month, it can take you a very long time to pay your balance back. You can choose to pay more than the minimum or to make payments more often than once a month if you'd like to pay down your debt sooner. If you can't pay your monthly bill on time, you will likely incur a late fee.</p>" +
                '<p><b>How is a HELOC Card different from a credit card?</b></p>' +
                '<p>While a HELOC Card is used in a similar way as a regular credit card, they are not the same. The main difference is that a HELOC Card uses your home’s equity to secure the loan, while a credit card doesn’t require any collateral. Consequently, HELOC Cards have much lower interest rates than credit cards. However, unlike with a credit card, the rate is usually variable, meaning it moves up and down based on a benchmark rate.</p>' +
                '<p>Are you looking for a HELOC Card? Aven is a credit card in the front, home equity in the back. Aven is the world\'s first home equity-backed credit card and combines the low interest rates of a home equity line of credit with the flexibility of a credit card. <a href="https://aven.com">Click here</a> to learn more!</p>',
            theFastestWayToGetAHeloc:
                '<p>A Home Equity Line of Credit can be a great way to obtain extra cash when you want to invest in home improvements and remodeling, or want to pay off high-interest personal loans and credit cards. But how do you apply, and what is the fastest way to get approved and get access to those funds?</p>' +
                '<p><b>Getting a HELOC the Traditional Way</b></p>' +
                "<p>To qualify for a traditional HELOC, you will need to meet a few requirements in regards to your income, credit and percentage of equity in your home. The exact requirements vary by lender, but let's take a look at the most common conditions.</p>" +
                '<p>The first and, arguably, most important metric lenders look at when you apply for a HELOC is your credit score. Most lenders require a FICO® score of at least 620 to qualify for a HELOC, but the minimum could be set as high as 680. Some will approve you with a score under 620 if you have a low debt-to-income ratio or a high percentage of equity in your home. </p>' +
                '<p>As mentioned above, your debt-to-income ratio also matters. This ratio tells a lender whether or not you can afford to make payments on your line of credit, and is calculated by totaling your monthly debt payments, as well as other financial obligations, and dividing that number by your total monthly income. The resulting percentage is your debt-to-income ratio. Most lenders abide by guidelines set by the Consumer Financial Protection Bureau, which recommends a debt-to-income ratio below 43%. Regardless, the lower this percentage, the easier it will be to qualify for a HELOC. Lenders will also evaluate your payment history on other loans to ensure you make payments on time and determine how risky it will be to lend you money.</p>' +
                '<p>You will also need to prove that you have a steady income so that lenders can be confident you’ll pay back your line of credit. Typically, providing pay stubs or your most recent W-2 is sufficient. </p>' +
                '<p>Next, you need to have enough equity in your home. Lenders usually look for at least 15 to 20 percent of equity compared to your home’s value. To determine your equity, subtract the amount you owe on your mortgage from the current value of your home. For example, if you owe $200,000 on your mortgage and your home is valued at $350,000, your home equity is $150,000 (or 43%).</p>' +
                '<p>Once the lender has received and evaluated the above information, you’ll receive an initial decision within a few days. However, rather than taking your word for it, many lenders will perform a home appraisal of their own to confirm the equity amount. This process involves a professional visiting your property to assess its value based on location, age, recent home improvements, curb appeal, nearby attractions and the current housing market.</p>' +
                '<p>The above steps can take a few weeks to complete before you receive a final decision on whether or not you have qualified for a HELOC, and you will likely pay fees associated with the service. Then, once you get a green light from the lender, you’ll need to visit the office to sign paperwork and then wait about three days before accessing your funds through online transfer or issued checks. The entire process can take a month or longer, and typically involves closing costs of 2% to 5% of the loan amount, as well as fees for origination, appraisal, inactivity and early termination.</p>' +
                '<p><b>Getting a HELOC Card</b></p>' +
                '<p>Don’t have a month to wait? A HELOC Card might be a better option. A HELOC Card is backed by your home’s equity, just like a traditional HELOC, but it can take as little as <u>15 minutes</u> to qualify. To apply, you will still need a credit score in the mid-600s, depending on your equity and debt-to-income ratio, proof of a steady income and enough equity in your home.</p>' +
                `<p>However, you may be allowed to skip the home appraisal (eliminating any appraisal fees) and sign the closing documents via an online notarization session, which substantially shortens the approval process. The card typically arrives in the mail within ${ESTIMATED_CARD_MAIL_ARRIVAL_BUSINESS_DAYS} days, and after the three-day waiting period, you can use it anywhere credit cards are accepted. </p>` +
                '<p>A HELOC Card also has significantly lower fees than a traditional HELOC. Skipping the appraisal means you’ll only incur charges for late payments, insufficient funds, and taking out cash. </p>' +
                '<p><b>Deciding between a HELOC Card and traditional HELOC</b></p>' +
                "<p>When considering whether a traditional HELOC or a HELOC Card is the right choice for you, keep the similarities and differences discussed above in mind. Some people may feel more comfortable signing papers in person or may want to give their favorite bank their business. If this sounds like you, a traditional HELOC may be the way to go. However, if you're looking for the fastest and most affordable way to make your home equity work for you, a HELOC Card may be the best option.</p>" +
                '<p>Are you considering applying for a HELOC and looking for the fastest way to do it? Aven can have you approved in as little as 15 minutes. <a href="http://aven.com">Find out more!</a></p>',
            homeEquityCreditCardHowToGetOne:
                '<p>If you have a mortgage and want to free up cash to pay for home improvements, remodeling or other investments, taking out a home equity loan, a home equity line of credit (HELOC) or a reverse mortgage are among options to make your home equity work for you. But what is home equity, what can you do with it and how do you tap into it? Keep reading to find out.</p>' +
                '<p><b>What is Home Equity?</b></p>' +
                '<p>Home equity is the portion of your home that you have already paid off. It is calculated by subtracting your outstanding mortgage and loan balances from the current market value of your home. For example, if you put 20% down on a house that is worth $200,000 and take out a mortgage to cover the remaining $160,000, then you would have $40,000 of equity in your home. As you pay off the mortgage, your home equity increases until you own your home in its entirety.</p>' +
                '<p><b>How can I use my Home Equity to access funds?</b></p>' +
                '<p>When you have home equity, you can use that equity as collateral to borrow money in the form of a home equity loan, home equity line of credit (HELOC) or a reverse mortgage. Interest rates on these types of loans tend to be lower than on other types of loans because they are secured by the portion of your home that you own. Choosing an option depends on what you wish to do with the extra cash.</p>' +
                '<p class="section-header">Home Equity Loan</p>' +
                '<p>A Home Equity Loan, otherwise known as a second mortgage, is one way to borrow against your home equity and receive all the money in one lump sum at a fixed interest rate. You then make monthly principal and interest payments to pay it off. This type of loan can be useful if you need cash to make a large, one-time payment. A home renovation is a good example. It could also be used to consolidate high-interest debt.</p>' +
                '<p class="section-header">Home Equity Line Of Credit (HELOC)</p>' +
                '<p>Another option is a home equity line of credit or HELOC. A HELOC also uses your home equity as collateral. However, rather than receiving all your money at once, you get access to a line of credit from which you can withdraw money as you need it. The beauty of a HELOC is that you only pay interest on what you use.</p>' +
                "<p>There are two stages of a HELOC. The first is the draw period, during which you can withdraw as much money as you need up to the credit limit. The second is the repayment period, which is when  you pay off the debt. Interest rates on HELOCs are usually adjustable, and your monthly payments will vary. A HELOC is a good option if you want to make many smaller improvements to your home over an extended period of time, or if you're not exactly sure how much money you will need.</p>" +
                '<p class="section-header">Reverse Mortgage</p>' +
                '<p>Lastly, if you are at least 62 years old, a reverse mortgage is another choice to consider. As the name suggests, a reverse mortgage is the opposite of a traditional mortgage. Instead of making payments to a lender to earn equity in your home, the lender makes payments to you. Your debt to the lender increases, and your equity decreases as the lender buys it from you. You remain the title holder of your home, but when you sell it, pass away, or move out for more than a year, the loan becomes due. The lender will then sell the house to recover the funds you borrowed, and any remaining equity falls to you or your heirs.' +
                "<p>Reverse mortgages are most commonly used by elderly adults since you need to be at least 62 years old to apply for one. They can be a good alternative when you want to retire but don't have enough savings and assets to do so. If you are considering a reverse mortgage, make sure to do your research, read the fine print and consult an attorney because scams are common.</p>" +
                '<p><b>What is a Home Equity Line of Credit Card?</b></p>' +
                '<p>While there are advantages to each of the options mentioned above, the application and approval process can take a few weeks, and getting access to your funds can be a hassle. Perhaps the easiest and fastest way to access your funds is with a HELOC Card, which is similar to a traditional HELOC, but without the need for a checkbook or online transfers. A HELOC Card works just like a credit card and can be used anywhere credit cards are accepted. There are lenders, like <a href="http://aven.com">Aven</a>, that specialize in these cards and in speeding up the approval process.</p>' +
                '<p>To apply for a HELOC Card, you will need to meet a few requirements like having a stable income, a credit score of around 650 and around 20% equity in your home. Unlike with a traditional HELOC, the entire process can be completed online and doesn’t usually require a home appraisal. All you need to do is answer a few questions about yourself and your home, confirm your income by uploading pay stubs or logging into your bank account and complete an online notarization session to sign paperwork. To learn more about the requirements and steps to getting a HELOC Card as quickly as possible, check out <a href="https://www.aven.com/education/home-equity-line-of-credit-heloc-card-how-it-works">this article</a>.</p>' +
                '<p><b>Which option is right for me?</b></p>' +
                "<p>Deciding which option is best depends on many factors, including what you need the money for and how quickly you need access to it. A home equity loan may be best for well-thought out projects with a known cost, while a HELOC is better for ongoing projects in which the costs are variable. However, if you need your funds quickly and want a convenient way to access them, a HELOC Card may be what you're looking for.</p>" +
                '<p>Have you decided a HELOC Card is worth looking into? Aven can get you approved in as little as 15 minutes. <a href="http://aven.com">Read more</a> and find out if it\'s for you!</p>',
            whenAreHelocsHomeEquityLinesOfCreditAGoodIdea:
                '<p>If you’re looking for a way to consolidate personal loan debt or want to free up some cash for a large purchase or home improvements, you may have come across the term  HELOC. But what is it, what can it be used for and when is a HELOC a good idea?</p>' +
                '<h2>Key Takeaways\n</h2>' +
                "<ul><li>A HELOC is a line of credit backed by your home equity and can be a good idea when you're looking for a way to free up cash for home improvements.</li>" +
                '<li>There are other options to consider, depending on your situation, and what you want to use the funds for.</li>' +
                '<li>If a HELOC sounds like a good idea, consider a HELOC Card for a more efficient way to access your funds.</li></ul>' +
                '<h2>What is a HELOC?</h2>' +
                '<p>A HELOC, or Home Equity Line of Credit, is a type of loan that uses your home equity as collateral. If you own at least 20% of your  home, you may be able to use that equity to secure a HELOC. A HELOC works like a credit card in the sense that you can use the money when and where you need it. However, interest rates on HELOCs tend to be more competitive because the loan is backed by your home equity. In addition, rates are adjustable and move up or down based on a benchmark rate, such as the federal funds rate. During the initial draw period, which typically lasts five to 10 years, you can pay off your debt and borrow again as much as you’d like. You’ll only pay interest on the amount you withdraw. Then comes the repayment period, during which you pay down your debt by making monthly principal and interest payments.</p>' +
                '<h2>What are the alternatives to a HELOC?</h2>' +
                "<p>Before settling on a HELOC, you may want to consider other options. Depending on your situation, and what you want to use the funds for, another type of loan may suit your situation  better. Let's take a look at some of the alternatives so that you can decide whether a HELOC is a good idea for you.</p>" +
                '<h3>Home Equity Loan</h3>' +
                '<p>A Home Equity Loan, also known as a Second Mortgage, is backed by your home equity, just like a HELOC. The main difference is that this loan is paid out as a lump sum, and once it’s taken out, that’s it. You can’t access additional funds, and when the debt is repaid, the loan is closed. The interest rate for this type of loan is typically fixed. A Home Equity loan can be used to consolidate high-interest debt or to fund home renovations when you have a plan and already know more or less how much you will spend.</p>' +
                '<h3>Cash-Out Mortgage Refinance</h3>' +
                "<p>A Cash-Out Mortgage Refinance may also be a good option if you're looking to make some home improvements. This type of loan replaces your existing mortgage with a loan for more money than you currently owe on your house, and the difference is paid out to you in cash. Your monthly mortgage payments will be higher as a result. Like the Home Equity Loan above, once you’re finished originating the loan, it’s done; you cannot draw additional funds like you would on a HELOC.</p>" +
                '<h3>Reverse Mortgage</h3> ' +
                "<p>If you are older than 62, a Reverse Mortgage is another choice. As the name suggests, this type of loan works in the opposite way of a traditional mortgage: The lender makes payments to you in exchange for equity in your home, and your equity decreases as your debt increases. The loan balance doesn’t become due until you sell the property or pass away. This type of loan can be a good alternative when you want to retire but don't have enough savings and assets to do so. Scams are common, so do your research and be careful.</p>" +
                '<h3>Personal Loan</h3>' +
                '<p>Another option to look into is a Personal Loan, which could be unsecured or secured against something valuable - like a car, for example. Personal Loans are often used to consolidate credit card debt or other high-interest debt, but keep in mind that the interest rates for personal loans can also be high compared with a HELOC.</p>' +
                '<h2>Advantages of a HELOC</h2>' +
                '<p>There are a few advantages of choosing a HELOC over the types of loans described above. First, the interest rates are usually lower than for other kinds of loans. Secondly, some lenders offer low or no closing cost and some may not charge fees for withdrawing cash. Finally, if you use your funds for home improvements or renovations, the interest on your HELOC may be tax-deductible.</p>' +
                '<h2>When is a HELOC a Good Idea?</h2>' +
                '<p>A HELOC may be the best choice if you are looking to use the funds for home improvements or renovations, which will increase the value of your home. It’s an especially good option if you’re unsure how much money you will need for the project because you’re able to withdraw funds as you go, while only paying interest on what you use. On the other hand, if you’re looking to free up cash for something like a car, which will not increase your wealth, another type of loan may be less risky. As discussed, a HELOC is backed by your home equity, which puts your home at risk should you not be able to pay back your debt.</p>' +
                '<h2>What is a HELOC Card?</h2>' +
                "<p>If you've determined a HELOC is a good idea for you, consider getting a HELOC Card. It can take weeks to receive approval for a traditional HELOC, while a HELOC Card takes as little as 15 minutes and allows you to skip the home appraisal process. Once approved, the card arrives in the mail in a few days. After a three-day waiting period, you'll be free to use the card anywhere credit cards are accepted. With a traditional HELOC, you will usually need to use a special checkbook or online transfers to access your funds.</p>" +
                '<p>Does a HELOC seem like a good idea? If you\'re looking for a HELOC Card, Aven can get you approved in as fast as 15 minutes. <a href="https://aven.com">Find out more!</a></p>',
            whatIsAHomeEquityLineOfCreditHelocABeginnersGuide:
                '<p>There are many options to consider when you need to free up cash for a project or purchase, such as credit card cash advances, personal loans, reverse mortgages and home equity loans. But have you ever considered a Home Equity Line of Credit (HELOC), which allows you to tap into your home equity to fund home improvements or renovations? If you haven’t, read on to discover how a HELOC works and why it may be a good option for you.</p>' +
                '<h2>Key Takeaways</h2>' +
                '<ul>' +
                '<li>A HELOC is a line of credit that is backed by the equity you have in your home, meaning your house is used as collateral to secure the loan.</li>' +
                "<li>A HELOC is recommended for investments that will  increase your wealth, such as home improvements. It's not recommended for day-to-day living expenses or expensive purchases like cars or vacations.</li> " +
                '<li>HELOC rates are determined using a baseline rate, typically the prime rate, and your credit profile. Typically, the higher your credit score, the lower the rate.</li> ' +
                "<li>HELOC rates are variable, meaning they move up or down in accordance with the benchmark rate. Remember to check your HELOC's maximum rate when applying to avoid a costly surprise.</li>" +
                '</ul>' +
                '<h2>What is a HELOC?</h2>' +
                '<p>A HELOC is a line of credit backed by the equity you have in your home: the value of your home minus what you owe on your mortgage. A HELOC uses your home equity as collateral and lets you borrow against it.</p>' +
                "<p>For example, let's say you make a down payment of 20% on a house worth $200,000 and take out a mortgage to cover the rest. That means you initially have $40,000 worth of equity in your home, which increases as you make monthly payments on your mortgage. If you have at least 20% equity in your home (and meet a few other requirements - read more here), you may qualify for a HELOC.</p>" +
                "<p>A HELOC consists of two phases. The first is the draw period, which usually lasts five to 10 years, and the repayment period, which can last 10 to 20 years. During the draw period, you are free to use your line of credit at your discretion, up to your limit. It functions much like a credit card: borrow cash, repay it and borrow again. You also only pay interest on what you use. When the draw period is over, the repayment phase begins. This is where you make monthly principal and interest payments to pay back what you've borrowed during the draw phase.</p>" +
                '<h2>How are HELOC rates determined?</h2>' +
                '<p>HELOC interest rates are determined based on a benchmark interest rate, typically the prime rate, as well as your credit profile. Depending on your credit score, the lender will add a markup to the benchmark rate. Typically, the lower your credit score, the higher the interest you’ll have to pay. If you have an excellent credit history, your rate could be as low as 3%, while if your credit is below average, it could be as high as 9-10%.</p>' +
                '<p>Because HELOCs are secured by home equity, the interest rates tend to be lower than with other types of loans. However, they are variable, meaning your rate will move up and down over time with the benchmark rate it is tied to. This means your monthly payments may increase, making it harder to keep up with payments. It is critical  to consider this and check the maximum rate for your HELOC when deciding if it’s the right choice for you.</p>' +
                '<h2>How do homeowners benefit from HELOCs?</h2>' +
                '<p>The biggest benefit of a HELOC is the ability to withdraw funds as you go and only pay interest on what you use, unlike other loans that require you to take out a lump sum and pay interest on the entire balance from the get-go. In addition, if the HELOC funds are used to pay for home improvement projects and renovations, which boost the value of the property over time, the interest may be tax-deductible. To learn more, <a href="/education/when-are-helocs-home-equity-lines-of-credit-a-good-idea">read here</a> about when a HELOC may (or may not) be a good idea.</p>' +
                '<h2>How do lenders benefit from offering HELOCs?</h2>' +
                "<p>For lenders, HELOCs offer protection if things go downhill because the borrower’s property serves as collateral. If a borrower defaults on payments, the bank could ultimately take ownership of the home and sell it to pay off the loan. For this reason, it's best not to use the funds to pay for vacations, cars, groceries, or anything that will make it more difficult to pay off your monthly principal and interest payments.</p>" +
                "<p>Are you considering applying for a HELOC? With Aven's HELOC Card, you can get approved in as fast as 15 minutes and have the card in your hands in a few days. <a href=\"https://aven.com\">Read more<a> to learn whether it's what you're looking for!</p>",
            homeEquityLinesCreditHelocsVsMortgagesSimilaritiesDifferences:
                "<p>Mortgages and HELOCs (Home Equity Lines of Credit) are two types of loans that are both backed by your home. When trying to decide what kind of loan is best suited to you and your situation, it's essential to know the differences between the two and the benefits and drawbacks of each. This article will explain how each type of loan works and explore what the key similarities and differences are.</p>" +
                '<h2>Key Takeaways</h2>' +
                '<ul>' +
                '<li>Mortgages and HELOCs are two types of loans that both use your property as collateral. This means that if you end up defaulting on your payments, you can end up losing your home.</li>' +
                '<li>A mortgage is a fixed amount used to purchase property, while a HELOC is a revolving line of credit typically tapped for home improvements or renovations after a home is already bought.</li> ' +
                '<li>A mortgage can have a fixed or variable interest rate. Most HELOCs have variable interest rates.</li> ' +
                '<li>Mortgages and HELOCs share a lot of the same requirements for eligibility, such as a good credit score, Debt-to-Income Ratio at 43% or lower, and a steady income.</li>' +
                '<li>Getting a HELOC is usually quicker than getting a mortgage. However, the timeline varies a lot and depends on your lender and location, among other things.</li>' +
                '</ul>' +
                '<h2>How Does a Mortgage Work?</h2>' +
                "<p>A mortgage is a loan used to purchase property when you don’t wish to buy it outright using cash. Instead of paying the whole amount that the home is worth upfront, you make a down payment (the general rule of thumb is at least 20% of the total price), and get a mortgage to cover the rest. The home you are buying serves as collateral for the loan. The mortgage, which includes a principal amount and interest, is paid back in monthly installments over a determined period of time. Once you've paid off the whole amount you owe, you will own the property in its entirety. Mortgage rates can be either adjustable or fixed.</p>" +
                '<p>To be eligible for a mortgage, you need to meet certain requirements. The lender will typically run a credit check, and the higher your credit score, the lower your interest rate and mortgage payment will generally be. For a conventional mortgage, a score of 620 or higher is often required.</p>' +
                '<p>You will need to prove to the lender that you have a steady income. This can be done by showing proof of employment, pay stubs, tax returns, and other documents. The lender also looks at your mortgage reserves – cash or any assets you have that can be easily converted into cash if you were to find yourself facing financial hardship and struggling to make payments. Investments in stocks, bonds, and funds, and money in checkings and savings accounts are some of the liquid assets that may be considered reserves.</p>' +
                '<p>The lender will also look at your DTI or Debt-to-Income Ratio. This is calculated by dividing your monthly debt payments by your gross monthly income. The Consumer Financial Protection Bureau (CFPB) recommends a DTI of 43% or less.</p>' +
                "<p>The process of getting a mortgage begins with you applying to a mortgage lender. The lender will first verify if you meet the requirements and will be able to pay back the loan. If you are approved, they will come back with an offer of a loan at a specific interest rate to be repaid over a set amount of time. If you accept the lender's offer, you will go through a closing process, where you meet with the seller of the property to pay the down payment, have them sign over the property to you, and sign documents.</p>" +
                '<p>Filling out the paperwork for the mortgage application won’t take long, but getting approved, having the appraisal done, receiving a final offer and finalizing the closing procedure can be a lengthy process. After researching mortgages and finding a property you want to purchase (which can also take quite some time), you’re looking at a timeline of approximately two months before you have the keys in your hand. According to Ellie Mae, a software company that processes mortgage applications, the average time to close purchase mortgage loans was 51 days in March 2021. Because there are so many steps in the process, however, there is no guaranteed timeline and the amount of time it takes to get a mortgage varies greatly.</p>' +
                '<p>The mortgage process also comes with a closing cost, which can include origination and appraisal fees, real estate commissions, taxes, insurance fees, title fees and more. The average closing cost is around 3-6% of the purchase price, but it varies depending on your mortgage lender, location and loan type.</p>' +
                '<h2>How Does a HELOC Work?</h2>' +
                '<p>A Home Equity Line of Credit (HELOC) is a type of loan that lets you borrow against home equity, typically to spend on repairs and improvements of the property. It uses your home as collateral, just like a mortgage. However, unlike a mortgage, a HELOC gives you access to a revolving line of credit from which you can borrow money, pay it back, and borrow again during the first phase of the loan called the draw period. During this time, you usually only pay interest on what funds you actually borrow. The draw period generally lasts five to 10 years and is followed by the repayment period, where you pay back your remaining balance through monthly payments of principal and interest. HELOC rates are typically adjustable and vary over time.</p>' +
                '<p>To be eligible for a HELOC, you need to meet a lot of the same requirements as for a mortgage, except for the mortgage reserves.</p>' +
                '<p>Just like with a mortgage, the lender will look at your Debt-to-Income Ratio, which needs to be 43% or lower. You will also still need a credit score of around 620, depending on your DTI and the amount of equity you have in your home.</p>' +
                '<p>The home equity you own is the current price of your property minus any balance you owe on mortgages and loans against it, calculated as a percentage. To be eligible to apply for a HELOC, the minimum equity required is typically about 15-20%.</p>' +
                '<p>HELOC lenders will also want to know that you can make payments and will therefore require you to prove employment and a steady income as well. Read more about the HELOC application process <a href="https://www.aven.com/education/the-fastest-way-to-get-a-heloc">here</a>.' +
                '<p>When you apply for a HELOC, the lender evaluates the above-mentioned information to determine if you are a good candidate for a line of credit. If you meet the requirements, the lender will give you an initial offer. Following this, they will likely want to do an appraisal of your home to determine its value and then come back with a final offer.</p>' +
                '<p>Completing the steps described above and receiving a final offer typically takes between two and six weeks. The average processing time for HELOCs, from application to underwriter final decision, was 21 days in 2019, according to the Mortgage Bankers Association. The process can be shorter or longer depending on your situation. The last step is for you to visit the office to sign the paperwork, and then, after about a three-day waiting period, you can access your funds through online transfers or issued checks.</p>' +
                '<p>Getting a HELOC also comes with closing costs, in most cases of around 2-5% of the total loan amount, but it varies greatly depending on the lender. The closing cost can include application/origination fee, appraisal fee, title and escrow fees and fees for notaries and attorneys, among others.</p>' +
                '<p>Some lenders can get you through the process of getting a HELOC faster. Applying for a HELOC Card, rather than a traditional HELOC can help you speed things along, and it may also come with fewer fees. Learn more about traditional HELOCs versus HELOC Cards <a href="https://www.aven.com/education/home-equity-credit-card-how-to-get-one">here</a>.</p>' +
                '<h2>Comparing Mortgages and HELOCs</h2>' +
                '<div class="table-responsive">' +
                '<table class="table table-striped">' +
                '        <thead class="align-bottom">' +
                '            <tr>' +
                '                <th>' +
                '                    <p>Mortgages</p>' +
                '                </th>' +
                '                <th>' +
                '                    <p>Home Equity Line of Credit (HELOC)</p>' +
                '                </th>' +
                '            </tr>' +
                '            </thead>' +
                '        <tbody>' +
                '            <tr>' +
                '                <td>' +
                '                    <p>Uses your home as collateral for the loan</p>' +
                '                </td>' +
                '                <td>' +
                '                    <p>Uses your home as collateral for the loan</p>' +
                '                </td>' +
                '            </tr>' +
                '            <tr>' +
                '                <td>' +
                '                    <p>Used to purchase a new property</p>' +
                '                </td>' +
                '                <td>' +
                '                    <p>Used for investments, such as home improvements and repairs, when you already have at least 15-20% equity in your property</p>' +
                '                </td>' +
                '            </tr>' +
                '            <tr>' +
                '                <td>' +
                '                    <p>Can have adjustable or fixed interest rate</p>' +
                '                </td>' +
                '                <td>' +
                '                    <p>Typically has adjustable interest rate</p>' +
                '                </td>' +
                '            </tr>' +
                '            <tr>' +
                '                <td>' +
                '                    <p>Requires down payment, a good credit score, low DTI, steady income and liquid reserves</p>' +
                '                </td>' +
                '                <td>' +
                '                    <p>Requires a good credit score, low DTI and steady income</p>' +
                '                </td>' +
                '            </tr>' +
                '            <tr>' +
                '                <td>' +
                '                    <p>You receive a lump sum and once you pay it off you become the owner of the property. You can regain access to funds with a cash-out refinancing</p>' +
                '                </td>' +
                '                <td>' +
                '                    <p>Allows for borrowing, repaying and borrowing again during the draw period upto a credit limit&nbsp;</p>' +
                '                </td>' +
                '            </tr>' +
                '            <tr>' +
                '                <td>' +
                '                    <p>Average time to close is about two months</p>' +
                '                </td>' +
                '                <td>' +
                '                    <p>Average time to close is less than one month</p>' +
                '                </td>' +
                '            </tr>' +
                '            <tr>' +
                '                <td>' +
                '                    <p>Closing costs of around 3-6%</p>' +
                '                </td>' +
                '                <td>' +
                '                    <p>Closing costs of around 2-5%</p>' +
                '                </td>' +
                '            </tr>' +
                '        </tbody>' +
                '    </table>' +
                '</div>' +
                '<p>Has this article piqued your interest in applying for a HELOC? Aven can get you approved in as little as 15 minutes, and lets you access your funds through a convenient HELOC Card. <a href="https://www.aven.com/">Find out more and decide if it’s for you!</a></p>',
            securityTips:
                '<h1 class="subtitle">Identity Theft</h1>' +
                '<h2 class="mb-0">What to Look For</h2>' +
                '<p>Identity Theft (“ID Theft”) can take many forms. Once thieves have your personal information, they can run up charges on your Aven Card, open an account in your name, or use your information for other illegal purposes.</p>' +
                '<p class="mb-0">To help determine if someone has stolen your identity, you should look for the following clues:</p>' +
                '<ul>' +
                '<li class="mb-0">You receive messaging from Aven despite never applying for an Aven Card</li>' +
                '<li class="mb-0">You receive an Aven Card in the mail despite never applying for an Aven Card</li>' +
                '<li class="mb-0">You receive official recording documents from your local county about liens filed against your property</li>' +
                '<li class="mb-0">You see an unfamiliar account or charge on your credit report</li>' +
                '<li class="mb-0">You receive a billing statement for an account you didn’t open</li>' +
                '<li class="mb-0">You receive a billing statement containing transactions you didn’t conduct</li>' +
                '<li class="mb-0">You get notice that your information was compromised by a data breach</li>' +
                '</ul>' +
                '<h2 class="mb-0">What to Do</h2>' +
                '<p class="mb-0">You will want to immediately take the following steps with Aven to ensure your account is protected moving forward.</p>' +
                '<ul>' +
                '<li class="mb-0">Contact the Aven Support team at</li>' +
                '<ul>' +
                '<li class="mb-0">Email: <a href="mailto:support@aven.com">Support@aven.com</a></li>' +
                '<li class="mb-0">Call: 844-428-3107</li>' +
                '</ul>' +
                '<li class="mb-0">Ask Aven Support team to freeze your account, freeze your card, and issue a new card to your home address</li>' +
                '<li class="mb-0">Reset/Change your login and password</li>' +
                '</ul>' +
                '<p class="mb-0">You may also want to do the following:</p>' +
                '<ul>' +
                '<li class="mb-0">Place a fraud alert on your credit profile with the following agencies:</li>' +
                '<ul>' +
                '<li class="mb-0">Experian. <a target="_blank" rel="noopener" href="https://experian.com/help">Experian.com/help</a> or call 888-EXPERIAN (888-397-3742)</li>' +
                '<li class="mb-0">Equifax. <a target="_blank" rel="noopener" href="https://transUnion.com/credit-help">TransUnion.com/credit-help</a> or call 888-909-8872</li>' +
                '<li class="mb-0">TransUnion. <a target="_blank" rel="noopener" href="https://equifax.com/personal/credit-report-services">Equifax.com/personal/credit-report-services</a> or call 800-685-1111</li>' +
                '</ul>' +
                '<li class="mb-0">Report the ID Theft to the FTC. You can complete the <a target="_blank" rel="noopener" href="https://www.identitytheft.gov/#/assistant">online form</a> or call 1-877-438-4338.</li>' +
                '<li class="mb-0">Report the ID Theft to your local police department.</li>' +
                '</ul>' +
                '<p class="mb-6">For detailed information or additional recommendations, please visit the FTC’s <a target="_blank" rel="noopener" href="https://www.identitytheft.gov/#/Steps">website</a> for ID Theft.</p>' +
                '<h1 class="subtitle">Information Security</h1>' +
                '<p>Aven employs the following information security measures to protect you, your personal information, and your account. For more details, you can also view the <a target="_blank" rel="noopener" href="/docs/ConsumerPrivacyPolicyNotice.pdf">Aven Privacy Policy</a>.</p>' +
                '<h2 class="mb-0">Password Security</h2>' +
                '<p>Aven requires that you setup an account password that is at least 8 characters in length, containing at least 1 letter, 1 number, and 1 special character. Additionally, you are required to complete Two-Factor Authentication prior to accessing your account for the first time and any time you access your account from a new device thereafter.</p>' +
                '<h2 class="mb-0">Failed Login Attempts</h2>' +
                '<p class="mb-6">Aven limits the number of times you may fail an attempt to login to your account.</p>' +
                '<h1 class="subtitle">Customer Contact for Credentials</h1>' +
                '<p>Aven will never proactively reach out to you and ask for personal information.</p>' +
                '<p class="mb-0">Aven will only request that you verify your identity in the following situations:</p>' +
                '<ol>' +
                '<li>You actively contact the Aven Support team via phone.</li>' +
                '<li>You contact or schedule time with the Aven Support team, and they reach out to you in response to your initial request.\n</li>' +
                '</ol>' +
                '<p class="mb-6">If you receive any requests for your personal information outside of these scenarios, please contact Aven Support immediately to report the suspicious activity.</p>' +
                '<h1 class="subtitle">Contact List for Reporting Suspicious Activity</h1>' +
                '<p>If you ever experience or witness any suspicious activity associated with your Aven account, you can contact the following groups to file a report:</p>' +
                '<p class="mb-0">At Aven. Contact the Aven Support team at</p>' +
                '<ul>' +
                '<li class="mb-0">Email: <a href="mailto:support@aven.com">Support@aven.com</a></li>' +
                '<li class="mb-0">Call: 844-428-3107</li>' +
                '</ul>' +
                '<p class="mb-0">External Authorities. You may contact one of the following authorities to report suspicious activity:</p>' +
                '<ul class="mb-6">' +
                '<li class="mb-0">The Federal Trade Commission (“FTC”). You can complete the <a target="_blank" rel="noopener" href="https://www.identitytheft.gov/#/assistant">online form</a> or call 1-877-438-4338.</li>' +
                '<li class="mb-0">Your Local Police Department.</li>' +
                '</ul>',
            homeDepotVsAven:
                '<p class="mb-6">Are you considering a credit card to fund your home renovation project or seeking a smart solution for managing balance transfers? Look no further! In this comprehensive article, we\'ll examine two popular credit cards—the Home Depot Credit Card and the Aven Home EquityCredit Card—that cater to individuals like you, aiming to streamline their home improvement expenses or optimize their balance transfer strategies.</p>' +
                '<div class="table-responsive">' +
                '<table class="table fixed">' +
                '        <thead class="align-bottom">' +
                '            <tr>' +
                '                <th></th>' +
                '                <th>' +
                '                    <p class="m-0"><b>Home Depot<sup><a href="#footnote_2"><b>2</b></a></sup></b></p>' +
                '                </th>' +
                '                <th>' +
                '                    <p class="m-0"><b>Aven</b></p>' +
                '                </th>' +
                '            </tr>' +
                '        </thead>' +
                '        <tbody>' +
                '            <tr>' +
                '                <td>' +
                '                    <p class="m-0"><b>Card Type</b></p>' +
                '                </td>' +
                '                <td>' +
                '                    <p class="m-0">Store Specific</p>' +
                '                </td>' +
                '                <td>' +
                '                    <p class="m-0">General Purpose</p>' +
                '                </td>' +
                '            </tr>' +
                '            <tr>' +
                '                <td>' +
                '                    <p class="m-0"><b>Rewards</b></p>' +
                '                </td>' +
                '                <td>' +
                '                    <p class="m-0">None</p>' +
                '                </td>' +
                '                <td>' +
                '                    <p class="m-0">2% Cashback<sup><a href="#footnote_1">1</a></sup></p>' +
                '                </td>' +
                '            </tr>' +
                '            <tr>' +
                '                <td>' +
                '                    <p class="m-0"><b>Annual Fee</b></p>' +
                '                </td>' +
                '                <td>' +
                '                    <p class="m-0">None</p>' +
                '                </td>' +
                '                <td>' +
                '                    <p class="m-0">None</p>' +
                '                </td>' +
                '            </tr>' +
                '            <tr>' +
                '                <td>' +
                '                    <p class="m-0"><b>APR</b></p>' +
                '                </td>' +
                '                <td>' +
                '                    <p class="m-0">17.99% - 26.99</p>' +
                '                </td>' +
                '                <td>' +
                '                    <p class="m-0">7.99% - 14.99%<sup><a href="#footnote_1">1</a></sup></p>' +
                '                </td>' +
                '            </tr>' +
                '            <tr>' +
                '                <td>' +
                '                    <p class="m-0"><b>Credit Limit</b></p>' +
                '                </td>' +
                '                <td>' +
                '                    <p class="m-0">Not Specified</p>' +
                '                </td>' +
                '                <td>' +
                '                    <p class="m-0">Up to $250,000</p>' +
                '                </td>' +
                '            </tr>' +
                '            <tr>' +
                '                <td>' +
                '                    <p class="m-0"><b>Process</b></p>' +
                '                </td>' +
                '                <td>' +
                '                    <p class="m-0">Less stringent</p>' +
                '                </td>' +
                '                <td>' +
                '                    <p class="m-0">Stricter criteria</p>' +
                '                </td>' +
                '            </tr>' +
                '            <tr>' +
                '                <td>' +
                '                    <p class="m-0"><b>Benefits</b></p>' +
                '                </td>' +
                '                <td>' +
                '                    <p class="m-0">Special financing options, extended return windows</p>' +
                '                </td>' +
                '                <td>' +
                '                    <p class="m-0">Large lines, low rates, option to cash out, cash back</p>' +
                '                </td>' +
                '            </tr>' +
                '            <tr style="border-color: white">' +
                '                <td></td>' +
                '                <td>' +
                '                    <p class="m-0"><b><a href="https://www.homedepot.com/c/Credit_Center#ConsumerCard" target="_blank">Apply Now</a></b></p>' +
                '                </td>' +
                '                <td>' +
                '                    <p class="m-0"><b><a href="/homeimprovement" target="_blank">Apply Now</a></b></p>' +
                '                </td>' +
                '            </tr>' +
                '        </tbody>' +
                '    </table>' +
                '</div>' +
                '<a href="/homeimprovement" class="btn nav-link text-white fw-bold bg-dark rounded-pill ps-3 pe-3 mt-6" target="_blank">Apply Now</a>' +
                '<p class="mt-5">When it comes to revamping your living space or dealing with existing credit card debt, having the right credit card can be a game-changer. That\'s why we\'re here to break down the unique features, rewards, fees, and benefits of these two credit cards, empowering you to make an informed decision based on your specific needs and financial goals.</p>' +
                '<h2>Card Features and Rewards:</h2>' +
                '<p>The Aven Home Equity Credit Card is a general-purpose credit card that can be used anywhere VISA is accepted. It offers cash back rewards on everyday purchases. These rewards can be redeemed for statement credits.</p>' +
                '<p>On the other hand, the Home Depot Credit Card offers various benefits such as special financing options and access to exclusive cardholder events. The card provides a range of promotional financing offers, including deferred interest and fixed monthly payments, depending on the purchase amount.</p>' +
                '<h2>Annual Fees and Interest Rates:</h2>' +
                '<p>The Aven Home Equity Credit Card has no annual fee or sign up fee. It has an extremely low interest rate, making it an attractive choice for those looking to save on interest charges.</p>' +
                "<p>The Home Depot Credit Card does not charge an annual fee. However, it's important to note that the card has a relatively high APR (Annual Percentage Rate), which can be significant if you carry a balance from month to month.</p>" +
                '<h2>Credit Limit and Approval Process:</h2>' +
                '<p>The Home Depot Credit Card typically offers a lower credit limit compared to the Aven Home Equity Credit Card. This is because it is primarily designed for Home Depot purchases and not intended for extensive everyday use. The approval process for the Home Depot Credit Card may be less stringent, making it more accessible to a wider range of credit profiles.</p>' +
                '<p>The Aven Home Equity Credit Card, being a general-purpose credit card, generally offers higher credit limits. However, the approval process may involve stricter criteria, including credit history and income verification, to qualify for higher credit limits.</p>' +
                '<h2>Conclusion:</h2>' +
                '<p>Both the Home Depot Credit Card and the Aven Home Equity Credit Card have their own strengths and cater to different consumer needs. If you are a regular Home Depot shopper, the Home Depot Credit Card can provide you with attractive financing options. However, if you prefer a more versatile credit card with rewards on everyday spending and a wider range of perks, the Aven Home Equity Credit Card may be a better fit.</p>' +
                '<p>Consider your spending habits, credit profile, and specific needs before making a decision. Ultimately, choosing the right credit card depends on your personal preferences, financial goals, and the benefits that align with your lifestyle.</p>' +
                '<a href="/homeimprovement" class="btn nav-link text-white fw-bold bg-dark rounded-pill ps-3 pe-3 my-6" target="_blank">Apply Now</a>',
        },
    },
    press: {
        seriesD: {
            title: 'Series D',
            description: 'Aven Reaches Unicorn Status with $142 Million Series D Investment',
        },
    },
    footnotes: {
        aprIs: 'The annual percentage rate (“APR”) is the cost of credit as a yearly rate and does not include costs other than interest.',
        yourInitialApr: 'Your initial APR is based on a margin, determined by your creditworthiness when you open your account, plus the Index at the time of application.',
        aprSubjectToChange: 'Your APR is subject to change as allowed by applicable law.',
        bestRatesToBestBorrowers: 'Best rates available only to the highest-qualified borrowers.',
        maxApr: 'The maximum APR will not exceed {maxApr} during the life of your account.',
        coBtFee: {
            balanceTransfer: 'Balance Transfer fee is {feePercentage} of amount transferred, this fee is subject to change.',
            cashOut: 'Cash Out (draw to bank account) fee is {feePercentage} of amount transferred, this fee is subject to change.',
            both: 'Cash Out (draw to bank account) fee and Balance Transfer fee is {feePercentage} of amount transferred, this fee is subject to change.',
        },
        rateIndex: {
            wsj: 'The APR is a variable rate based on the prime rate (“Index”) published by the Wall Street Journal in its Money Rates section. The WSJ Prime as of {rateIndexEffectiveDate} is {rateValuePercentage}.',
            fftrul: 'The APR is a variable rate based on the Federal Funds Target Rate – Upper Limit (“FFTR-UL”) index (“Index”). The FFTR – UL is determined by the Federal Reserve’s Federal Open Market Committee and published periodically by the Wall Street Journal in its Money Rates section. The FFTR – UL as of {rateIndexEffectiveDate} is {rateValuePercentage}.',
        },
        // If you update this language, be sure to update landingPageDisclosureAutopayDiscountRegex in aven_e2e_tests/playwright/avenTests_prod/origination.spec.ts
        autoPayDiscount:
            'AutoPay Discount of 0.25% is offered to new cardholders only. You are not required to sign-up for Autopay. To keep the discounted rate, you must enroll in AutoPay by the end of your first billing cycle, or APR will increase by 0.25%. Unenrolling from AutoPay will increase your APR. Aven reserves the right to terminate the AutoPay Discount program for unenrolled cardholders at anytime and without notice.',
        cashBackAvailableTo:
            'Cashback available to those who enroll in AutoPay, refer to <a class="text-decoration-underline" href="/docs/AutoPayAgreement.pdf" target="_blank">AutoPay Terms & Conditions</a>.',
        hotelsAndFlightsCashBack: {
            hotels: 'Hotels must be booked in the Aven Travel Portal to receive {cashBack} cash back.',
            flights: 'Flights must be booked in the Aven Travel Portal to receive {cashBack} cash back.',
        },
        cashbackAppliesTo: ({ named }: { named: (key: string) => any }) =>
            `Cash back applies to valid purchases (not on refunds or returns) made using the Aven card, and does not apply to non-card transactions, including balance transfers, ${
                named('isUcc') ? '' : 'cash-outs,'
            } reward redemptions and redemptions through the Aven Travel portal.`,
        cashBackIsAccumulated: 'Cashback is accumulated as points and redeemable as statement credit or in the travel portal.',
        referToRewardsTermsAndConditions: 'Refer to <a class="text-decoration-underline" href="{rewardsTermsDoc}" target="_blank">Rewards Terms & Conditions</a>.',
        lowerRateCompensation: 'If you use and have an active credit card agreement from a current account that demonstrates a lower APR than ours, we’ll send you $25.00.',
        guaranteeExclusion: 'Introductory, temporary, and promotional APRs do not apply toward our guarantee.',
        guaranteeOnlyValidTo: 'Only valid for new customers who received this specific offer and use the assigned invitation code.',
        reservedRightToChangeGuarantee: 'We reserve the right to change the terms of the guarantee at any time.',
        guaranteeForWhom: 'Offer is for lowest non-promotional APR currently offered to new cardholders.',
        guaranteeNotAvailableTo: 'Guarantee is not available for non-owner occupied homes.',
        guaranteedRateTermsLink: '<a class="text-decoration-underline" href="/public/docs/GuaranteedRate">Guarantee Terms and Conditions</a>.',
        averageAmericaApr:
            'Source: "Average Credit Card Interest Rate in America Today" <a href="https://www.lendingtree.com/credit-cards/study/average-credit-card-interest-rate-in-america/" target="_blank" rel="noopener noreferrer">lendingtree.com</a> on Sep 18, 2024 showing the average rate for credit cards in the US is 24.92% APR.',
        externalAnnualFees:
            'Annual fees for the <a rel="noopener noreferrer" target="_blank" class="text-decoration-underline" href="https://creditcards.chase.com/rewards-credit-cards/sapphire/reserve">Chase Sapphire Reserve</a> and <a rel="noopener noreferrer" target="_blank" class="text-decoration-underline" href="https://www.americanexpress.com/us/credit-cards/card/platinum/">American Express Platinum</a> on their website as of July 1, 2024.',
        lienRemoval: ({ named }: { named: (key: string) => any }) => {
            let subject

            if (named('isUcc')) {
                subject = 'purchases'
            } else {
                subject = 'home'
            }

            return `The removal of the lien on your ${subject} is contingent upon the full settlement of your Aven card balance.`
        },
        lienLiftedUntil: 'The lien cannot be lifted until the outstanding balance is paid in its entirety.',
        servicedByVisa: 'Service provided by Visa.',
        visaZeroLiability: "Visa's Zero Liability Policy covers U.S.-issued cards and does not apply to certain commercial card transactions or any transactions not processed by Visa.",
        notifyUnauthorizedUse: 'You must notify your financial institution immediately of any unauthorized use.',
        contactIssuerFor: 'For specific restrictions, limitations and other details, please consult your issuer.',
        seeVisaBenefitsTerms:
            '<a href="https://usa.visa.com/pay-with-visa/cards/visa-credit-cards/visa-signature-credit-cards.html" rel="noopener noreferrer" target="_blank" class="text-decoration-underline">See Visa benefits terms</a>.',
        seeForeclosureProtectionGuaranteeTerms:
            'See Foreclosure Protection Guarantee <a href="{guaranteedForeclosureProtectionDoc}" target="_blank" class="text-decoration-underline">Terms & Conditions</a> for full requirements.',
    },
    shared,
}
