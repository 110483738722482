import { App } from 'vue'
import { logger } from '@/utils/logger'
export const mainConfigInit = (app: App<Element>) => {
    // see here: https://vuejs.github.io/vetur/guide/FAQ.html#property-xxx-does-not-exist-on-type-combinedvueinstance
    app.config.globalProperties.$logEvent = window.logEvent
    app.config.globalProperties.$logger = logger // we add logger to the prototype so that we can access it in aven_shared
    app.config.globalProperties.$logMloLoanEvent = window.logMloLoanEvent
    app.config.globalProperties.$logRedditEvent = (eventName: string) => {
        window.rdt('track', eventName)
        window.logEvent('event_reddit_pixel_fired', { eventName })
    }
}
