import type { RouteRecordRaw } from 'vue-router'

export const surveyPageNames = {
    ACCOUNT_CLOSURE_REASONS_SURVEY: 'AccountClosureReasonsSurvey',
    WITHDRAW_OR_DECLINE_SURVEY: 'WithdrawOrDeclineReasonsSurvey',
}

export const surveyPagePaths = {
    ACCOUNT_CLOSURE_REASONS_SURVEY: `/accountClosureReasonsSurvey/:returnToken`,
    WITHDRAW_OR_DECLINE_SURVEY: `/withdrawOrDeclineReasonsSurvey/:returnToken`,
}

const surveyPageRoutes: RouteRecordRaw[] = [
    {
        path: surveyPagePaths.ACCOUNT_CLOSURE_REASONS_SURVEY,
        name: surveyPageNames.ACCOUNT_CLOSURE_REASONS_SURVEY,
        component: () => import(/* webpackChunkName: "about" */ '@/pages/survey/AccountClosureReasonsSurvey.vue'),
        meta: { public: true },
        props: true,
    },
    {
        path: surveyPagePaths.WITHDRAW_OR_DECLINE_SURVEY,
        name: surveyPageNames.WITHDRAW_OR_DECLINE_SURVEY,
        component: () => import(/* webpackChunkName: "about" */ '@/pages/survey/WithdrawOrDeclineReasonsSurvey.vue'),
        meta: { public: true },
        props: true,
    },
]

export const surveyRoutes: RouteRecordRaw[] = [...surveyPageRoutes]
