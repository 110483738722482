import type { RouteRecordRaw } from 'vue-router'
import { pressPagePaths, pressPageNames } from './prerenderRoutes'

const pressPageRoutes: RouteRecordRaw[] = [
    {
        path: pressPagePaths.SERIES_D,
        name: pressPageNames.SERIES_D,
        component: () => import(/* webpackChunkName: "press" */ '@/pages/press/SeriesD.vue'),
        meta: { public: true },
    },
]

export const pressRoutes: RouteRecordRaw[] = pressPageRoutes

export { pressPageNames, pressPagePaths }
