import { logger } from '@/utils/logger'
import { App } from 'vue'

export const currentContextForLogging: any = {}

export const initGlobalMixin = (app: App<Element>) => {
    // WARNING: DO NOT CREATE MIXINS UNLESS ABSOLUTELY NECESSARY. It is highly discouraged.
    // https://vuejs.org/v2/guide/mixins.html#Global-Mixin
    app.mixin({
        props: {
            requiresLogViewEvent: { type: Boolean },
        },
        data() {
            return {
                pageRoute: this.$route?.path,
            }
        },
        created() {
            try {
                // set up context logging for all components
                const name = this.name
                const vnode = this.$vnode
                const uid = this.uid as string
                if (name || vnode?.tag || uid) {
                    currentContextForLogging[name || vnode?.tag || uid] = this
                }

                // This is the second step to check if component requires log view event.
                // It loops through every component and checks if the `requiresLogViewEvent` exists
                // (The first step is router.ts)
                const options = this.$options
                if (options.propsData?.requiresLogViewEvent) {
                    logger.log('Check if logViewEvent has been implemented on the container component in /pages')
                    // @ts-ignore bc the component context is unavailable to us and since we've already confirmed that the component "requiresLogViewEvent", we can safely ignore the linter and check if the function exists
                    if (typeof this.logViewEvent !== 'function') {
                        logger.fatal('Must implement logViewEvent(): { this.$logEvent(eventName, eventProps) } on every page view. Please implement for this page component.')
                    }
                }
            } catch (e) {
                logger.fatal(`there is an error with the global mixin ${this?.name || this?.$vnode?.tag || this?.uid}`, e)
            }
        },
    })
}
