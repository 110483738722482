// Could be a unit test
import { checkAreAllPathsUnique, RouteOption, tryGetNextFlowPath, tryGetOptionalPath } from '@/flow/flowUtility'
import { originationPagePaths } from '@/routes/originationRoutes'
import { sharedPagePaths } from '@/routes/sharedRoutes'
import { authPagePaths } from '@/routes/authRoutes'
import { ThanksPageReasons } from '@/utils/thanksPageHelpers'

export const hmdaEntryPaths: string[] = [authPagePaths.HMDA_FLOW]

export const hmdaFlow: string[] = [originationPagePaths.HMDA, `${sharedPagePaths.THANKS}?reason=${ThanksPageReasons.hmdaFinished}`]

const optionalFlowMap: Map<string, Map<string, string[]>> = new Map()

if (!checkAreAllPathsUnique(hmdaFlow, optionalFlowMap)) {
    throw new Error('hmda page paths not unique!')
}

export const hmdaNextRouteWithCurrentPath = (currentPath: string, routeOption?: RouteOption): string | null => {
    if (routeOption) {
        return tryGetOptionalPath(currentPath, optionalFlowMap, routeOption)
    }

    const noParamsEntryPath = hmdaEntryPaths[0].replace(/:\w+/, '')
    if (currentPath.startsWith(noParamsEntryPath)) {
        return hmdaFlow[0]
    }

    return tryGetNextFlowPath(currentPath, hmdaFlow, optionalFlowMap)
}
