import type { RouteRecordRaw } from 'vue-router'

const routeName = 'creditLimitIncrease'

export const pageNames = {
    OFFER_PREVIEW: 'offer_preview',
    PRE_QUALIFICATION: 'pre_qualification',
    UPDATE_MARITAL_STATUS: 'update_marital_status',
} as const

export type creditLimitIncreasePageNameKeys = keyof typeof pageNames
export type creditLimitIncreasePageNameValues = typeof pageNames[creditLimitIncreasePageNameKeys]

export const creditLimitIncreasePagePaths = {
    OFFER_PREVIEW: `/${routeName}/${pageNames.OFFER_PREVIEW}`,
    PRE_QUALIFICATION: `/${routeName}/${pageNames.PRE_QUALIFICATION}`,
    UPDATE_MARITAL_STATUS: `/${routeName}/${pageNames.UPDATE_MARITAL_STATUS}`,
}

const completionRoutes: RouteRecordRaw[] = [
    {
        path: creditLimitIncreasePagePaths.PRE_QUALIFICATION,
        component: () => import(/* webpackChunkName: "origination" */ '@/pages/creditLimitIncrease/PreQualification.vue'),
        meta: { transitionName: 'slide' },
    },
    {
        path: creditLimitIncreasePagePaths.OFFER_PREVIEW,
        component: () => import(/* webpackChunkName: "origination" */ '@/pages/creditLimitIncrease/OfferPreview.vue'),
        meta: { transitionName: 'slide' },
    },
    {
        path: creditLimitIncreasePagePaths.UPDATE_MARITAL_STATUS,
        component: () => import(/* webpackChunkName: "origination" */ '@/pages/creditLimitIncrease/UpdateMaritalStatus.vue'),
        meta: { transitionName: 'slide' },
    },
]

export const creditLimitIncreaseRoutes: RouteRecordRaw[] = [...completionRoutes]
