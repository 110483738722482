import { RouteOption, tryGetNextFlowPath, tryGetOptionalPath } from '@/flow/flowUtility'
import { pifForAllPagePaths } from '@/routes/pifForAllRoutes'

export const pifForAllFlow: string[] = [pifForAllPagePaths.PIF_FOR_ALL_LANDING, pifForAllPagePaths.SENDER_INFO, pifForAllPagePaths.LINK]

const optionalFlowMap: Map<string, Map<string, string[]>> = new Map()

export const getNextPifForAllRouteWithCurrentPath = (currentPath: string, routeOption?: RouteOption): string | null => {
    if (routeOption) {
        return tryGetOptionalPath(currentPath, optionalFlowMap, routeOption)
    }

    const nextRoute = tryGetNextFlowPath(currentPath, pifForAllFlow, optionalFlowMap)
    return nextRoute
}
