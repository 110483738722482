import { logger } from '@/utils/logger'
import { trackClick } from '@/services/api'

export const maybeTryTrackClick = async (link: string) => {
    if (!link) {
        logger.info(`Click tracking store tried to track click, but no link to track`)
        return
    }
    try {
        logger.info(`Click tracking store is tracking click on link ${link}`)
        // This is just a "fire and forget" call. Nothing to do with the result
        await trackClick(link)
        logger.info(`Click tracking store finished tracking click on link ${link}`)
    } catch (e) {
        logger.error(`Error tracking Aven click w/ link ${link}`, null, e as unknown as any)
    }
}
