import 'url-search-params-polyfill'
import { appLocalStorage, appSessionStorage, sessionStorageKey } from '@/utils/storage'
import { logger } from '@/utils/logger'
import { httpClient, logEvent } from '@/utils/http-client'
import { getUnderwritingMetadata, isUnderwritingMetadataReady } from '@/utils/uwMetaData'
import { initLogRocket } from './logRocket'
import { openReplay } from './openreplay'
import LogRocket from 'logrocket'
import { removeWhitespaces } from '@/utils/stringUtils'

export const featureFlags = [sessionStorageKey.trustsFeatureFlag]

export const setLocalMetadata = () => {
    window.logEvent('system_aven_init', {
        referrer: document.referrer,
        referring_domain: document.referrer ? new URL(document.referrer).hostname : undefined,
    })

    // Parsing invite code from query params for tryaven.com redirects
    // https://tryaven.com/BLAH1234 -> https://aven.com/?referrer=tryaven%2FBLAH1234 where %2F is url encoding of a forward slash '/'
    const query = new URLSearchParams(window.location.search)
    logger.info(`query ${query}; referrer ${document.referrer}; location href ${window.location.href}`)

    if (query.has('referrer') || query.has('invitecode') || query.has('code')) {
        const inviteCode = query.get('referrer')?.split('/')?.[1] || query.get('invitecode') || query.get('code')

        if (inviteCode) {
            const sanitizedInviteCode = removeWhitespaces(inviteCode)
            appSessionStorage.setItem(sessionStorageKey.inviteCode, sanitizedInviteCode)
        }
    } else if (query.has('pifinvitecode')) {
        const pifInviteCode = query.get('pifinvitecode') as string
        const sanitizedPifInviteCode = removeWhitespaces(pifInviteCode)
        appSessionStorage.setItem(sessionStorageKey.pifInviteCode, sanitizedPifInviteCode)
    }

    featureFlags.forEach((flag) => {
        if (query.get(flag)) {
            appSessionStorage.setItem(flag, 'True')
        }
    })
}

export const submitSessionRecordingUrl = async (sessionId: string | null, sessionRecordingUrl: string) => {
    appSessionStorage.setItem(sessionStorageKey.sessionRecordingInitialized, 'true')
    appSessionStorage.setItem(sessionStorageKey.sessionRecordingUrl, sessionRecordingUrl)
    try {
        await httpClient.post('/ana/sessionRecordingUrl', { sessionId, sessionRecordingUrl })
    } catch (error) {
        logger.fatal(`submission failed, sessionRecordingUrl, ${sessionRecordingUrl}`, error)
    }
}

export const getUserTraits = (): Record<string, any> => {
    const loanApplicationId = appSessionStorage.getItem(sessionStorageKey.loanApplicationId)
    const applicantId = appSessionStorage.getItem(sessionStorageKey.applicantId)
    const experimentName = isUnderwritingMetadataReady() ? getUnderwritingMetadata()?.ExperimentName : 'prerender'
    const abTests = appSessionStorage.getItem(sessionStorageKey.experimentsOverrides)
    const mloId = appLocalStorage.getItem(sessionStorageKey.mloId)
    const traits: any = {}

    if (loanApplicationId) {
        traits.loanApplicationId = loanApplicationId
    }

    if (applicantId) {
        traits.applicantId = applicantId
    }

    if (experimentName) {
        traits.experimentName = experimentName
    }

    if (abTests) {
        traits.abTests = abTests
    }

    if (mloId) {
        traits.mloId = mloId
    }
    return traits
}

export const initializeSessionRecording = (sessionIdParam?: string) => {
    const sessionId = sessionIdParam || appSessionStorage.getItem(sessionStorageKey.sessionId)
    if (sessionId) {
        const userTraits = getUserTraits()
        if (process.env.VUE_APP_USE_OPEN_REPLAY === 'true' && process.env.VUE_APP_OPENREPLAY_PROJECT_KEY) {
            logger.info(`init openReplay session with traits: ${JSON.stringify(userTraits)}`)
            openReplay.init(sessionId, userTraits)
        } else if (process.env.VUE_APP_LOGROCKET_ID) {
            logger.info(`init logRocket session with traits: ${JSON.stringify(userTraits)}`)
            initLogRocket(sessionId, userTraits)
        }
    } else {
        logger.fatal(`Could not initialize session recording with no sessionId!`)
    }
}

export const setSessionMetadata = (metadata: Record<string, any>) => {
    const setOpenReplayMetadataSuccess = openReplay.trySetMetadata(metadata)
    if (!setOpenReplayMetadataSuccess) {
        const sessionId = appSessionStorage.getItem(sessionStorageKey.sessionId)
        sessionId && LogRocket.identify(sessionId, metadata)
    }
}

export const initNextDoor = () => {
    // provided by nextdoor team
    // eslint-disable-next-line no-extra-semi
    ;(function (win, doc, sdk_url) {
        if (win.ndp) return
        const tr = (win.ndp = function () {
            // eslint-disable-next-line prefer-rest-params,prefer-spread
            tr.handleRequest ? tr.handleRequest.apply(tr, arguments) : tr.queue.push(arguments)
        } as any)
        tr.queue = []
        const s = 'script'
        const new_script_section = doc.createElement(s)
        new_script_section.async = !1
        new_script_section.src = sdk_url
        const insert_pos = doc.getElementsByTagName(s)[0]
        if (insert_pos.parentNode) {
            insert_pos.parentNode.insertBefore(new_script_section, insert_pos)
        }
    })(window, document, 'https://ads.nextdoor.com/public/pixel/ndp.js')
    window.ndp('init', process.env.VUE_APP_NEXTDOOR_PIXEL, {})
    window.ndp('track', 'PAGE_VIEW')
}

/**
 * The externalId argument for the the FB pixel (i.e. this frontend code)
 * and the FB Conversions API (i.e. the backend code in facebookPixelProvider.ts) need to match.
 * The externalId is retrieved from the backend.
 */
export const maybeInitFacebook = (externalId?: string | null) => {
    if (!externalId) {
        logger.info(`maybeInitFacebook() decide to not init because no externalId provided`)
        return
    }

    try {
        // @ts-ignore
        // eslint-disable-next-line no-extra-semi
        ;(function (f, b, e, v, n: any, t: any, s: any) {
            if (f.fbq) return
            n = f.fbq = function () {
                // eslint-disable-next-line prefer-rest-params,prefer-spread
                n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments)
            }
            if (!f._fbq) f._fbq = n
            n.push = n
            n.loaded = !0
            n.version = '2.0'
            n.queue = []
            t = b.createElement(e)
            t.async = !1
            t.src = v
            s = b.getElementsByTagName(e)[0]
            s.parentNode.insertBefore(t, s)
        })(window, document, 'script', 'https://connect.facebook.net/en_US/fbevents.js')

        logger.info(`maybeInitFacebook() decided to init with externalId ${externalId}`)
        window.fbq('init', process.env.VUE_APP_FACEBOOK_PIXEL, { external_id: externalId })
        window.fbq('track', 'PageView')
    } catch (e) {
        logger.fatal('Failed to initialize facebook', e)
    }
}

export const initTikTokPixel = () => {
    if (process.env.VUE_APP_NODE_ENV !== 'production') {
        logger.info(`initTikTokPixel() decide to not init because VUE_APP_NODE_ENV is ${process.env.VUE_APP_NODE_ENV}`)
        return
    }

    if (!process.env.VUE_APP_TIKTOK_PIXEL_ID) {
        logger.info(`initTikTokPixel() decide to not init because no pixel id provided`)
        return
    }

    try {
        // @ts-ignore
        // eslint-disable-next-line no-extra-semi
        ;(function (w, d, t) {
            w.TiktokAnalyticsObject = t
            // @ts-ignore
            const ttq = (w[t] = w[t] || [])
            ttq.methods = ['page', 'track', 'identify', 'instances', 'debug', 'on', 'off', 'once', 'ready', 'alias', 'group', 'enableCookie', 'disableCookie']
            // @ts-ignore
            ttq.setAndDefer = function (t, e) {
                t[e] = function () {
                    // eslint-disable-next-line prefer-rest-params
                    t.push([e].concat(Array.prototype.slice.call(arguments, 0)))
                }
            }
            for (let i = 0; i < ttq.methods.length; i++) ttq.setAndDefer(ttq, ttq.methods[i])
            // @ts-ignore
            ttq.instance = function (t) {
                let e, n
                for (e = ttq._i[t] || [], n = 0; n < ttq.methods.length; n++) ttq.setAndDefer(e, ttq.methods[n])
                return e
            }
            // @ts-ignore
            ttq.load = function (e, n) {
                const i = 'https://analytics.tiktok.com/i18n/pixel/events.js'
                ttq._i = ttq._i || {}
                ttq._i[e] = []
                ttq._i[e]._u = i
                ttq._t = ttq._t || {}
                ttq._t[e] = +new Date()
                ttq._o = ttq._o || {}
                ttq._o[e] = n || {}
                const o = document.createElement('script')
                o.type = 'text/javascript'
                o.async = !0
                o.src = i + '?sdkid=' + e + '&lib=' + t
                const a = document.getElementsByTagName('script')[0]
                // @ts-ignore
                a.parentNode.insertBefore(o, a)
            }

            logger.info(`initTikTokPixel() initialized`)
            ttq.load(process.env.VUE_APP_TIKTOK_PIXEL_ID)
            ttq.page()
        })(window, document, 'ttq')
    } catch (error) {
        logger.fatal('initTikTokPixel() Failed to initialize tiktok pixel', error)
    }
}

export const initReddit = () => {
    if (process.env.VUE_APP_NODE_ENV !== 'production') {
        logger.info(`Not init Reddit pixel because VUE_APP_NODE_ENV is ${process.env.VUE_APP_NODE_ENV}`)
        return
    }

    // @ts-ignore
    // eslint-disable-next-line no-extra-semi
    ;(function (w, d) {
        if (!w.rdt) {
            const p = (w.rdt = function () {
                // @ts-ignore
                // eslint-disable-next-line prefer-rest-params,prefer-spread
                p.sendEvent ? p.sendEvent.apply(p, arguments) : p.callQueue.push(arguments)
            })
            // @ts-ignore
            p.callQueue = []
            const t = d.createElement('script')
            ;(t.src = 'https://www.redditstatic.com/ads/pixel.js'), (t.async = !1)
            const s = d.getElementsByTagName('script')[0]
            // @ts-ignore
            s.parentNode.insertBefore(t, s)
        }
    })(window, document)
    window.rdt('init', process.env.VUE_APP_REDDIT_PIXEL)
    window.rdt('track', 'PageVisit')
}

export const initTrustPilot = () => {
    // @ts-ignore
    // eslint-disable-next-line no-extra-semi
    ;(function (w, d) {
        if (!w.Trustpilot) {
            // @ts-ignore
            const t = d.createElement('script')
            ;(t.src = 'https://widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js'), (t.async = !1)
            const s = d.getElementsByTagName('script')[0]
            // @ts-ignore
            s.parentNode.insertBefore(t, s)
        }
    })(window, document)
}

export const initMicrosoftPixel = () => {
    if (process.env.VUE_APP_NODE_ENV !== 'production') {
        logger.info(`Not init Microsoft pixel because VUE_APP_NODE_ENV is ${process.env.VUE_APP_NODE_ENV}`)
        return
    }

    try {
        (function (w, d, t, r, u) {
            // @ts-ignore
            let f,
                // @ts-ignore
                n,
                i
                // @ts-ignore
            ;(w[u] = w[u] || []),
                (f = function () {
                    const o = { ti: '97105397', enableAutoSpaTracking: true }
                    // @ts-ignore
                    ;(o.q = w[u]), (w[u] = new UET(o)), w[u].push('pageLoad')
                }),
                // @ts-ignore
                (n = d.createElement(t)),
                // @ts-ignore
                (n.src = r),
                // @ts-ignore
                (n.async = 1),
                // @ts-ignore
                (n.onload = n.onreadystatechange =
                    function () {
                        // @ts-ignore
                        const s = this.readyState
                        // @ts-ignore
                        ;(s && s !== 'loaded' && s !== 'complete') || (f(), (n.onload = n.onreadystatechange = null))
                    }),
                // @ts-ignore
                (i = d.getElementsByTagName(t)[0]),
                // @ts-ignore
                i.parentNode.insertBefore(n, i)
        })(
            // eslint-disable-next-line no-unexpected-multiline
            window,
            document,
            'script',
            '//bat.bing.com/bat.js',
            'uetq'
        )
        logger.info('Microsoft Pixel initialized')
    } catch (error) {
        logger.fatal('Failed to initialize Microsoft Pixel', error)
    }
}

export const initTwitterPixel = () => {
    if (process.env.VUE_APP_NODE_ENV !== 'production') {
        logger.info(`Not initing Twitter pixel because VUE_APP_NODE_ENV is ${process.env.VUE_APP_NODE_ENV}`)
        return
    }

    try {
        // @ts-ignore
        !(function (e, t, n, s, u, a) {
            // @ts-ignore
            e.twq ||
                // @ts-ignore
                ((s = e.twq =
                    function () {
                        // @ts-ignore
                        // eslint-disable-next-line prefer-rest-params,prefer-spread
                        s.exe ? s.exe.apply(s, arguments) : s.queue.push(arguments)
                    }),
                // @ts-ignore
                (s.version = '1.1'),
                // @ts-ignore
                (s.queue = []),
                // @ts-ignore
                (u = t.createElement(n)),
                // @ts-ignore
                (u.async = !0),
                // @ts-ignore
                (u.src = 'https://static.ads-twitter.com/uwt.js'),
                // @ts-ignore
                (a = t.getElementsByTagName(n)[0]),
                // @ts-ignore
                a.parentNode.insertBefore(u, a))
        })(window, document, 'script')
        // @ts-ignore
        window.twq('config', 'o429q')
        logger.info('Twitter Pixel initialized')
    } catch (error) {
        logger.fatal('Failed to initialize Twitter Pixel', error)
    }
}

export const initLinkedInPixel = () => {
    if (process.env.VUE_APP_NODE_ENV !== 'production') {
        logger.info(`Not initing LinkedIn pixel because VUE_APP_NODE_ENV is ${process.env.VUE_APP_NODE_ENV}`)
        return
    }

    try {
        window._linkedin_partner_id = '5972236'
        window._linkedin_data_partner_ids = window._linkedin_data_partner_ids || []
        window._linkedin_data_partner_ids.push(window._linkedin_partner_id)
        ;(function (l) {
            if (!l) {
                // @ts-ignore
                window.lintrk = function (a, b) {
                    window.lintrk.q.push([a, b])
                }
                window.lintrk.q = []
            }
            const s = document.getElementsByTagName('script')[0]
            const b = document.createElement('script')
            b.type = 'text/javascript'
            // @ts-ignore
            b.async = true
            b.src = 'https://snap.licdn.com/li.lms-analytics/insight.min.js'
            // @ts-ignore
            s.parentNode.insertBefore(b, s)
        })(window.lintrk)
        logger.info(`LinkedIn Pixel initialized`)
    } catch (error) {
        logger.fatal('Failed to initialize LinkedIn Pixel', error)
    }
}

export const initTvSquaredPixel = () => {
    if (process.env.VUE_APP_NODE_ENV !== 'production') {
        logger.info(`Not initing TvSquared pixel because VUE_APP_NODE_ENV is ${process.env.VUE_APP_NODE_ENV}`)
        return
    }

    try {
        // @ts-ignore
        const _tvq = (window._tvq = window._tvq || [])
        ;(function () {
            const u = 'https:' == document.location.protocol ? 'https://collector-44255.us.tvsquared.com/' : 'http://collector-44255.us.tvsquared.com/'
            _tvq.push(['setSiteId', 'TV-5454724545-1'])
            _tvq.push(['setTrackerUrl', u + 'tv2track.php'])
            _tvq.push([
                function () {
                    // @ts-ignore
                    this.deleteCustomVariable(5, 'page')
                },
            ])
            _tvq.push(['trackPageView'])
            const d = document,
                g = d.createElement('script'),
                s = d.getElementsByTagName('script')[0]
            g.type = 'text/javascript'
            g.defer = true
            g.async = true
            g.src = u + 'tv2track.js'
            // @ts-ignore
            s.parentNode.insertBefore(g, s)
        })()
        logger.info('TvSquared Pixel initialized')
    } catch (error) {
        logger.fatal('Failed to initialize TvSquared Pixel', error)
    }
}

export const initZendeskScript = () => {
    const script = document.createElement('script')
    script.defer = true
    script.id = 'ze-snippet'
    script.src = 'https://static.zdassets.com/ekr/snippet.js?key=380ebd06-0223-483e-8588-5c13a449ce55'
    script.crossOrigin = 'anonymous'
    script.onload = function () {
        // Allow Chat by Default
        window.zE('webWidget', 'updateSettings', {
            webWidget: {
                chat: {
                    suppress: false,
                    departments: {
                        enabled: ['Support'],
                        select: 'Support',
                    },
                },
            },
        })

        // Hide Zendesk Launcher by Default & on Widget Close
        window.zE('webWidget', 'hide')
        window.zE('webWidget:on', 'close', function () {
            window.zE('webWidget', 'hide')
        })
        logger.info('Zendesk Script initialized')
    }

    document.body.appendChild(script)
}

export const initMNTNPixel = () => {
    if (!['production'].includes(process.env.VUE_APP_NODE_ENV ?? '')) {
        logger.info(`Not initing MNTN pixel because VUE_APP_NODE_ENV is ${process.env.VUE_APP_NODE_ENV}`)
        return
    }

    try {
        // MNTN Tracking Pixel
        // INSTALL ON ALL PAGES OF SITE
        (function () {
            'use strict'
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const e = null,
                b = '4.0.0',
                n = '39181',
                additional = 'term=value'
            let t, r, i
            try {
                // @ts-ignore
                t = top.document.referer !== '' ? encodeURIComponent(top.document.referrer.substring(0, 2048)) : ''
            } catch (o) {
                t = document.referrer !== null ? document.referrer.toString().substring(0, 2048) : ''
            }
            try {
                r =
                    window && window.top && document.location && window.top.location === document.location
                        ? document.location
                        : window && window.top && window.top.location
                        ? window.top.location
                        : document.location
            } catch (u) {
                r = document.location
            }
            try {
                i = parent.location.href !== '' ? encodeURIComponent(parent.location.href.toString().substring(0, 2048)) : ''
            } catch (a) {
                try {
                    i = r !== null ? encodeURIComponent(r.toString().substring(0, 2048)) : ''
                } catch (f) {
                    i = ''
                }
            }
            let l
            const c = document.createElement('script'),
                p = document.getElementsByTagName('script'),
                d = Number(p.length) - 1,
                v = document.getElementsByTagName('script')[d]
            let h = null
            if (typeof l === 'undefined') {
                l = Math.floor(Math.random() * 1e17)
            }
            h = 'dx.mountain.com/spx?' + 'dxver=' + b + '&shaid=' + n + '&tdr=' + t + '&plh=' + i + '&cb=' + l + additional
            c.type = 'text/javascript'
            c.src = ('https:' === document.location.protocol ? 'https://' : 'http://') + h
            v.parentNode?.insertBefore(c, v)
        })()
        logger.info('MNTN Pixel initialized')
        logEvent('event_mntn_pixel_init').catch((error) => {
            logger.error('Failed to log event_mntn_init', error)
        })
    } catch (error) {
        logger.fatal('Failed to initialize MNTN Pixel', error)
    }
}

export const initTvScientificPixel = () => {
    if (!['production'].includes(process.env.VUE_APP_NODE_ENV ?? '')) {
        logger.info(`Not initing TvScientific pixel because VUE_APP_NODE_ENV is ${process.env.VUE_APP_NODE_ENV}`)
        return
    }

    try {
        (function () {
            // @ts-ignore
            let p, s, d, w
            // eslint-disable-next-line prefer-const
            d = document
            // eslint-disable-next-line prefer-const
            w = window.location
            // eslint-disable-next-line prefer-const
            p = d.createElement('IMG')
            // eslint-disable-next-line prefer-const
            s = w.protocol + '//tvspix.com/t.png?&t=' + new Date().getTime() + '&l=tvscientific-pix-o-d113fed8-72ed-4dce-ad10-9e052d8b63ae&u3=' + encodeURIComponent(w.href)
            p.setAttribute('src', s)
            p.setAttribute('height', '0')
            p.setAttribute('width', '0')
            p.setAttribute('alt', '')
            p.style.setProperty('display', 'none')
            p.style.setProperty('position', 'absolute')
            p.style.setProperty('visibility', 'hidden')
            d.body.appendChild(p)
        })()
        logger.info('TvScientific Pixel initialized')
        logEvent('event_tv_scientific_pixel_init').catch((error) => {
            logger.error('Failed to log tv scientific init event', error)
        })
    } catch (error) {
        logger.fatal('Failed to initialize TvScientific Pixel', error)
    }
}
