"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AssetVerificationStatus = exports.AssetVerificationResultType = exports.AssetVerificationMethod = exports.AssetVerificationPurpose = void 0;
var AssetVerificationPurpose;
(function (AssetVerificationPurpose) {
    AssetVerificationPurpose["plaid"] = "plaid";
})(AssetVerificationPurpose = exports.AssetVerificationPurpose || (exports.AssetVerificationPurpose = {}));
var AssetVerificationMethod;
(function (AssetVerificationMethod) {
    AssetVerificationMethod["plaid"] = "plaid";
    AssetVerificationMethod["unknown"] = "unknown";
})(AssetVerificationMethod = exports.AssetVerificationMethod || (exports.AssetVerificationMethod = {}));
var AssetVerificationResultType;
(function (AssetVerificationResultType) {
    AssetVerificationResultType["assetsVerifiedAndSufficient"] = "assetsVerifiedAndSufficient";
    AssetVerificationResultType["assetsVerifiedAndInsufficient"] = "assetsVerifiedAndInsufficient";
    AssetVerificationResultType["assetVerificationNotApplicable"] = "assetVerificationNotApplicable";
    AssetVerificationResultType["loanApplicationStatusDuplicate"] = "loanApplicationStatusDuplicate";
    AssetVerificationResultType["loanApplicationStatusWithdrawn"] = "loanApplicationWithdrawn";
    AssetVerificationResultType["cannotInitiateAssetVerification"] = "cannotInitiateAssetVerification";
})(AssetVerificationResultType = exports.AssetVerificationResultType || (exports.AssetVerificationResultType = {}));
var AssetVerificationStatus;
(function (AssetVerificationStatus) {
    AssetVerificationStatus["notApplicable"] = "notApplicable";
    AssetVerificationStatus["pending"] = "pending";
    AssetVerificationStatus["verifiedAndSufficient"] = "verifiedAndSufficient";
    AssetVerificationStatus["verifiedAndInsufficient"] = "verifiedAndInsufficient";
    AssetVerificationStatus["notVerified"] = "notVerified";
})(AssetVerificationStatus = exports.AssetVerificationStatus || (exports.AssetVerificationStatus = {}));
