import { createRouter, createWebHistory } from 'vue-router'
import type { RouteRecordRaw, RouteLocationNormalized } from 'vue-router'
import { authRoutes } from '@/routes/authRoutes'
import { logger, inspect } from '@/utils/logger'
import { sharedPagePaths, sharedRoutes } from '@/routes/sharedRoutes'
import { appLocalStorage, appSessionStorage, sessionStorageKey } from '@/utils/storage'
import { marketingPagePaths, marketingPageRoutes } from '@/routes/marketingRoutes'
import { educationRoutes } from '@/routes/educationRoutes'
import { supportPageRoutes } from '@/routes/supportRoutes'
import { originationRoutes } from '@/routes/originationRoutes'
import { originationEntryPagePaths, originationBackGuardPagePaths } from '@/flow/originationFlow'
import { checkPathsMatch, getNormalizedPath, RouteOption } from '@/flow/flowUtility'
import { getNextPath, latestPath } from '@/flow/flowController'
import { currentContextForLogging } from '@/config/initGlobalMixin'
import { isSafariPrivateBrowsing } from '@/utils/parseUserAgents'
import { isEmpty } from 'lodash'
import { goToInitialPageWithCleanState, goToPageWithCleanState } from '@/utils/routerUtils'
import { isStateStale } from '@/utils/stateUtils'
import { docsRoutes } from '@/routes/docRoutes'
import { mloPageNames, mloRoutes } from '@/routes/mloRoutes'
import { ThanksPageReasons } from '@/utils/thanksPageHelpers'
import { dataSupportPageRoutes } from '@/routes/dataSupportRoutes'
import { creditLimitIncreaseRoutes } from '@/routes/creditLimitIncreaseRoutes'
import { creditLimitIncreaseBackGuardPagePaths } from '@/flow/creditLimitIncreaseFlow'
import { pifForAllPageRoutes } from '@/routes/pifForAllRoutes'
import { testingRoutes } from '@/routes/testingRoutes'
import { cliAndAprReductionAndBalanceSweepBackGuardPagePaths } from '@/flow/cliAndAprReductionAndBalanceSweepFlow'
import { cliAndAprReductionAndBalanceSweepRoutes } from '@/routes/cliAndAprReductionAndBalanceSweepRoutes'
import { surveyRoutes } from '@/routes/surveyRoutes'
import { uccRoutes } from '@/routes/uccRoutes'
import { pressRoutes } from '@/routes/pressRoutes'

// This extends RouteConfig and makes requiresLogViewEvent required. It should be added to every single route that renders a component.
// This is the first step to making sure the logEvent call is added to each page that renders a component. The second step lives in main.ts.
export type AvenRouteConfig = RouteRecordRaw & { props: { requiresLogViewEvent: boolean } }

const DISABLE_NAVIGATION_GUARDS = false // disables redirects so components can be iterated on more quickly. don't forget to set back to false.
let NAVIGATED_ONCE = false // some components will redirect you manually. this gets set to true automatically in the beforeEach hook below.

export const routes = [
    ...marketingPageRoutes,
    ...authRoutes,
    ...sharedRoutes,
    ...originationRoutes,
    ...educationRoutes,
    ...supportPageRoutes,
    ...docsRoutes,
    ...mloRoutes,
    ...dataSupportPageRoutes,
    ...creditLimitIncreaseRoutes,
    ...cliAndAprReductionAndBalanceSweepRoutes,
    ...pifForAllPageRoutes,
    ...testingRoutes,
    ...surveyRoutes,
    ...uccRoutes,
    ...pressRoutes,
]

const oldLandingPagePathPairs = [
    ['secret', marketingPagePaths.LANDING],
    ['secretjoin', marketingPagePaths.LANDING_JOIN],
]

export const router = createRouter({
    history: createWebHistory(),
    routes,
    scrollBehavior(to) {
        if (to.hash) {
            return {
                el: to.hash,
            }
        } else {
            return { top: 0 }
        }
    },
})

const originalPush = router.push
router.push = async function push(location: any) {
    return originalPush.call(this, location).catch((err: any) => {
        logger.error('Navigation failure', err)
    })
}
const originalReplace = router.replace
router.replace = async function replace(location: any) {
    return originalReplace.call(this, location).catch((err: any) => {
        logger.error('Navigation failure', err)
    })
}

router.onError((error: any) => {
    logger.info(`router error: ${inspect(error)}`)
    // See: https://blog.francium.tech/vue-lazy-routes-loading-chunk-failed-9ee407bbd58
    if (/Loading.*chunk.*failed./i.test(error.message)) {
        logger.info('Reloading page to fix stale chunk error')
        return window.location.reload()
    }

    throw error
})

router.afterEach(() => {
    try {
        for (const member in currentContextForLogging) {
            delete currentContextForLogging[member]
        }
    } catch (e) {
        logger.fatal(`error clearing current context for logging`, e)
    }
})

router.beforeEach((to: RouteLocationNormalized, from: RouteLocationNormalized, next: Function) => {
    if (DISABLE_NAVIGATION_GUARDS) {
        if (NAVIGATED_ONCE) {
            return next(false)
        }
        NAVIGATED_ONCE = true
        return next()
    }

    const hasSessionAccessJWT = !!appSessionStorage.getItem(sessionStorageKey.sessionAccessJWT)
    const hasSessionId = !!appSessionStorage.getItem(sessionStorageKey.sessionId)
    const hasSession = hasSessionId && hasSessionAccessJWT
    logger.info(`router.beforeEach, from: ${from.fullPath} to: ${to.fullPath}, hasSession: ${hasSession}`)
    window.previousPath = from.path

    for (const [oldPath, newPath] of oldLandingPagePathPairs) {
        if (checkPathsMatch(oldPath, to.path)) {
            logger.error(`User navigating to a deprecated page ${oldPath}, redirecting to ${newPath}`)
            return goToPageWithCleanState(newPath, true)
        }
    }

    // remove any modals if needed
    document.body.classList.remove('modal-open')
    document.getElementById('modal-backdrop')?.remove()

    // from null to ["/", "/join", etc...], users loads our site
    // we store the starting page path
    if (originationEntryPagePaths.findIndex((path) => checkPathsMatch(path, to.path)) >= 0) {
        logger.info(`saved start page path in session storage, ${to.path}`)
        appSessionStorage.setItem(sessionStorageKey.startPagePath, to.path)
    }

    let navigatedEarly

    // clear storage if state is stale
    navigatedEarly = clearStorageCheck(to)
    if (navigatedEarly) {
        return
    }

    // jwt token required for all paths that are not public
    navigatedEarly = authCheck(to)
    if (navigatedEarly) {
        return
    }

    // prevent users from navigating back on certain guard pages
    navigatedEarly = backGuardCheck(to, from, next)
    if (navigatedEarly) {
        return
    }

    return next()
})

export default router

const clearStorageCheck = (to: RouteLocationNormalized) => {
    if (!isStateStale()) {
        return false
    }

    // clear storage will remove jwt token which will force user back to start page
    logger.info('User wants to navigate when application process has ended. clearing storage and navigating to front page if necessary')
    if (to.matched.some((record) => record.meta.public)) {
        // preserve search params when navigating to non-public pages while forcing a reload to acquire a new session
        let searchParams = window.location.search
        if (!searchParams && !isEmpty(to.query)) {
            const query = Object.assign({}, to.query as any)
            searchParams = '?' + new URLSearchParams(query).toString()
        }
        goToPageWithCleanState(`${to.path}${searchParams ? searchParams : ''}`, true)
    } else {
        goToInitialPageWithCleanState()
    }
    return true
}

const authCheck = (to: RouteLocationNormalized) => {
    if (appSessionStorage.getItem(sessionStorageKey.jwtTokens) || to.matched.some((record) => record.meta.public)) {
        return false
    }

    if (appLocalStorage.getItem(sessionStorageKey.mloJwtTokens) && to.name && Object.values(mloPageNames).includes(to.name as string)) {
        return false
    }

    if (isSafariPrivateBrowsing()) {
        router.push({ path: sharedPagePaths.THANKS, query: { reason: ThanksPageReasons.privateBrowsing } })
        return true
    }

    // force back to start page if there is no jwt
    goToInitialPageWithCleanState()
    return true
}

const backGuardCheck = (to: RouteLocationNormalized, from: RouteLocationNormalized, next: Function) => {
    if (checkPathsMatch(latestPath, to.path)) {
        return false
    }

    const mergedBackGuardPagePaths: string[] = [...cliAndAprReductionAndBalanceSweepBackGuardPagePaths, ...creditLimitIncreaseBackGuardPagePaths, ...originationBackGuardPagePaths]

    if (mergedBackGuardPagePaths.includes(getNormalizedPath(from.path))) {
        for (const option of [...Object.values(RouteOption)]) {
            if (checkPathsMatch(from.path, getNextPath(to.path, option))) {
                if (process.env.VUE_APP_NODE_ENV !== 'development') {
                    logger.info(`navigation to ${to.path} from ${from.path} aborted by back guard`)
                    next(false)
                    return true
                } else {
                    alert('WARNING: Backguard ignored. This navigation would be aborted on prod and staging environments! Be sure this is intended behavior.')
                    return false
                }
            }
        }
    }

    return false
}
