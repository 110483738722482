"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PersonaInquiryStatus = void 0;
/**
 * Persona inquiry statuses
 * See: https://docs.withpersona.com/docs/models-lifecycle#inquiry-statuses
 */
var PersonaInquiryStatus;
(function (PersonaInquiryStatus) {
    PersonaInquiryStatus["completed"] = "completed";
    PersonaInquiryStatus["failed"] = "failed";
    PersonaInquiryStatus["needs_review"] = "needs_review";
    PersonaInquiryStatus["created"] = "created";
    PersonaInquiryStatus["pending"] = "pending";
    PersonaInquiryStatus["expired"] = "expired";
    PersonaInquiryStatus["approved"] = "approved";
    PersonaInquiryStatus["declined"] = "declined";
})(PersonaInquiryStatus = exports.PersonaInquiryStatus || (exports.PersonaInquiryStatus = {}));
