/**
 * To use, pick a returnToken2 & a ValidDocusignDocType, then construct a url
 * like: http://localhost:8081/docs/{docType}/{returnToken2}
 *
 * Alternatively, you can go to http://localhost:8081/docs/devDocReturn and
 * enter the returnToken + document type directly
 */

import type { RouteRecordRaw } from 'vue-router'

const routeName: string = 'docs'

const pageNames = {
    DOC_RETURN: 'docs',
    PUBLIC_DOCS: 'publicDocs',
    PUBLIC_DOC_DETERMINISTIC: 'publicDocsDeterministic',
    DOCS_UPLOAD: 'docsUpload',
    DOCS_UPLOAD_INCOME_VERIFICATION: 'docsUploadIncomeVerification',
    TYPED_DOC_UPLOAD: 'specificDocUpload',
    INFLOW_DOC_UPLOAD: 'inflowDocUpload',
    FLOOD_DOCS_UPLOAD: 'floodDocsUpload',
    DEV_DOC_RETURN: 'devDocs',
}

export const docRoutePaths = {
    PUBLIC_DOC: `/public/${routeName}/:docType`,
    PUBLIC_DOC_DETERMINISTIC: `/public/${routeName}/:docType/:policyExperiment`,
    DOC_RETURN: `/${routeName}/:docType/:returnToken`,
    DOCS_UPLOAD: `/${routeName}_upload/:returnToken`,
    DOCS_UPLOAD_INCOME_VERIFICATION: `/${routeName}_upload_income_verification`,
    TYPED_DOC_UPLOAD: `/${routeName}_upload/:docType/:returnToken`,
    INFLOW_DOC_UPLOAD: `/inflow_doc_upload/:docType`,
    FLOOD_DOCS_UPLOAD: `/flood_docs_upload/:returnToken`,
    DEV_DOC_RETURN: `/${routeName}/devDocReturn`,
}

export const docsRoutes: RouteRecordRaw[] = [
    {
        path: docRoutePaths.DOC_RETURN,
        name: pageNames.DOC_RETURN,
        component: () => import(/* webpackChunkName: "origination-jodl-docs" */ '@/pages/docs/JodlDocument.vue'),
        meta: { public: true },
        // When props is set to true, the route.params will be set as the component props.
        props: true,
    },
    {
        path: docRoutePaths.PUBLIC_DOC,
        name: pageNames.PUBLIC_DOCS,
        component: () => import(/* webpackChunkName: "origination-jodl-docs" */ '@/pages/docs/JodlDocument.vue'),
        meta: { public: true },
        // When props is set to true, the route.params will be set as the component props.
        props: true,
    },
    {
        path: docRoutePaths.PUBLIC_DOC_DETERMINISTIC,
        name: pageNames.PUBLIC_DOC_DETERMINISTIC,
        component: () => import(/* webpackChunkName: "origination-jodl-docs" */ '@/pages/docs/JodlDocument.vue'),
        meta: { public: true },
        // When props is set to true, the route.params will be set as the component props.
        props: true,
    },
    {
        path: docRoutePaths.DOCS_UPLOAD,
        name: pageNames.DOCS_UPLOAD,
        component: () => import(/* webpackChunkName: "origination-jodl-docs" */ '@/pages/docs/SupportingDocumentVerification.vue'),
        meta: { public: true },
        // When props is set to true, the route.params will be set as the component props.
        props: true,
    },
    {
        path: docRoutePaths.DOCS_UPLOAD_INCOME_VERIFICATION,
        name: pageNames.DOCS_UPLOAD_INCOME_VERIFICATION,
        component: () => import(/* webpackChunkName: "origination-jodl-docs" */ '@/pages/docs/SupportingDocumentVerification.vue'),
    },
    {
        path: docRoutePaths.TYPED_DOC_UPLOAD,
        name: pageNames.TYPED_DOC_UPLOAD,
        component: () => import(/* webpackChunkName: "origination-jodl-docs" */ '@/pages/docs/SupportingDocumentVerification.vue'),
        meta: { public: true },
        // When props is set to true, the route.params will be set as the component props.
        props: true,
    },
    {
        path: docRoutePaths.INFLOW_DOC_UPLOAD,
        name: pageNames.INFLOW_DOC_UPLOAD,
        component: () => import(/* webpackChunkName: "origination-jodl-docs" */ '@/pages/docs/SupportingDocumentVerification.vue'),
        meta: { public: true },
        // When props is set to true, the route.params will be set as the component props.
        props: true,
    },
    {
        path: docRoutePaths.FLOOD_DOCS_UPLOAD,
        name: pageNames.FLOOD_DOCS_UPLOAD,
        component: () => import(/* webpackChunkName: "origination-jodl-docs" */ '@/pages/docs/FloodDocumentUpload.vue'),
        meta: { public: true },
        // When props is set to true, the route.params will be set as the component props.
        props: true,
    },

    // Legacy redirects:
    {
        path: '/docs/EarlyHELOCDisclosure.pdf',
        redirect: '/public/docs/EarlyHELOCDisclosure',
    },
    {
        path: '/docs/PifTerms.pdf',
        redirect: '/public/docs/PifTerms',
    },
    {
        path: '/docs/TermsOfUse.pdf',
        redirect: '/public/docs/TermsOfService',
    },
]

// Only respond to this route if it's local dev
if (process.env.VUE_APP_NODE_ENV !== 'production') {
    docsRoutes.push({
        path: docRoutePaths.DEV_DOC_RETURN,
        name: pageNames.DEV_DOC_RETURN,
        component: () => import(/* webpackChunkName: "origination-jodl-docs" */ '@/pages/docs/JodlDocument.vue'),
        meta: { public: true },
        // When props is set to true, the route.params will be set as the component props.
        props: true,
    })
}
