import { appSessionStorage, sessionStorageKey } from '@/utils/storage'

export const isStateStale = () => {
    return appSessionStorage.getItem(sessionStorageKey.clearStorageOnNavigation) === 'true'
}

export const markStateStale = () => {
    return appSessionStorage.setItem(sessionStorageKey.clearStorageOnNavigation, 'true')
}

export const stateImage = new Map([
    ['AL', require('@/assets/images/origination/states/Alabama.svg')],
    ['AK', require('@/assets/images/origination/states/Alaska.svg')],
    ['AZ', require('@/assets/images/origination/states/Arizona.svg')],
    ['AR', require('@/assets/images/origination/states/Arkansas.svg')],
    ['CA', require('@/assets/images/origination/states/California.svg')],
    ['CO', require('@/assets/images/origination/states/Colorado.svg')],
    ['CT', require('@/assets/images/origination/states/Connecticut.svg')],
    ['DC', require('@/assets/images/origination/states/DC.svg')],
    ['DE', require('@/assets/images/origination/states/Delaware.svg')],
    ['FL', require('@/assets/images/origination/states/Florida.svg')],
    ['GA', require('@/assets/images/origination/states/Georgia.svg')],
    ['HI', require('@/assets/images/origination/states/Hawaii.svg')],
    ['ID', require('@/assets/images/origination/states/Idaho.svg')],
    ['IL', require('@/assets/images/origination/states/Illinois.svg')],
    ['IN', require('@/assets/images/origination/states/Indiana.svg')],
    ['IA', require('@/assets/images/origination/states/Iowa.svg')],
    ['KS', require('@/assets/images/origination/states/Kansas.svg')],
    ['KY', require('@/assets/images/origination/states/Kentucky.svg')],
    ['LA', require('@/assets/images/origination/states/Louisiana.svg')],
    ['ME', require('@/assets/images/origination/states/Maine.svg')],
    ['MD', require('@/assets/images/origination/states/Maryland.svg')],
    ['MA', require('@/assets/images/origination/states/Massachusetts.svg')],
    ['MI', require('@/assets/images/origination/states/Michigan.svg')],
    ['MN', require('@/assets/images/origination/states/Minnesota.svg')],
    ['MS', require('@/assets/images/origination/states/Mississippi.svg')],
    ['MO', require('@/assets/images/origination/states/Missouri.svg')],
    ['MT', require('@/assets/images/origination/states/Montana.svg')],
    ['NE', require('@/assets/images/origination/states/Nebraska.svg')],
    ['NV', require('@/assets/images/origination/states/Nevada.svg')],
    ['NH', require('@/assets/images/origination/states/NewHampshire.svg')],
    ['NC', require('@/assets/images/origination/states/NorthCarolina.svg')],
    ['OH', require('@/assets/images/origination/states/Ohio.svg')],
    ['OK', require('@/assets/images/origination/states/Oklahoma.svg')],
    ['OR', require('@/assets/images/origination/states/Oregon.svg')],
    ['PA', require('@/assets/images/origination/states/Pennsylvania.svg')],
    ['RI', require('@/assets/images/origination/states/RhodeIsland.svg')],
    ['SC', require('@/assets/images/origination/states/SouthCarolina.svg')],
    ['TN', require('@/assets/images/origination/states/Tennessee.svg')],
    ['TX', require('@/assets/images/origination/states/Texas.svg')],
    ['UT', require('@/assets/images/origination/states/Utah.svg')],
    ['VT', require('@/assets/images/origination/states/Vermont.svg')],
    ['VA', require('@/assets/images/origination/states/Virginia.svg')],
    ['WA', require('@/assets/images/origination/states/Washington.svg')],
    ['WV', require('@/assets/images/origination/states/WestVirginia.svg')],
    ['WI', require('@/assets/images/origination/states/Wisconsin.svg')],
    ['WY', require('@/assets/images/origination/states/Wyoming.svg')],
])
