// this file MUST remain .js file extension because vue.config.js cannot import .ts extensions
// we also cannot import any .ts files into this file because es modules disallows this for vue.config.js
// we'll get type safety in the ts files so paths are added here

/** PRERENDERED EDUCATION PATHS **/
const baseEducationRoute = 'education'
const educationPageNames = {
    EDUCATION: 'education',
    HOME_EQUITY_LINE_OF_CREDIT_HELOC_CARD_WHAT_IS_IT: 'home-equity-line-of-credit-heloc-card-what-is-it',
    HOME_EQUITY_LINE_OF_CREDIT_HELOC_CARD_HOW_IT_WORKS: 'home-equity-line-of-credit-heloc-card-how-it-works',
    HOME_EQUITY_CREDIT_CARD_HOW_TO_GET_ONE: 'home-equity-credit-card-how-to-get-one',
    THE_FASTEST_WAY_TO_GET_A_HELOC: 'the-fastest-way-to-get-a-heloc',
    WHEN_ARE_HELOCS_HOME_EQUITY_LINES_OF_CREDIT_A_GOOD_IDEA: 'when-are-helocs-home-equity-lines-of-credit-a-good-idea',
    WHAT_IS_A_HOME_EQUITY_LINE_OF_CREDIT_HELOC_A_BEGINNERS_GUIDE: 'what-is-a-home-equity-line-of-credit-heloc-a-beginners-guide',
    HOME_EQUITY_LINES_CREDIT_HELOCS_VS_MORTGAGES_SIMILARITIES_DIFFERENCES: 'home-equity-lines-credit-helocs-vs-mortgages-similarities-differences',
    HOW_ARE_HELOC_RATES_DETERMINED: 'how-are-heloc-rates-determined',
    REFINANCING_A_HELOC: 'refinancing-a-heloc',
    HOW_TO_GET_LOWEST_RATE: 'how-to-get-lowest-rate',
    FIXED_OR_VARIABLE: 'fixed-or-variable',
    HELOC_ON_RENTAL_PROPERTIES: 'heloc-on-rental-properties',
    MOBILE_BANKING_SECURITY_TIPS: 'mobile-banking-security-tips',
    HOME_DEPOT_VS_AVEN: 'home-depot-credit-card-vs-aven-home-equity-credit-card',
    WHAT_IS_AN_AVEN_CARD: 'what-is-an-aven-card',
    WHAT_CREDIT_SCORE_IS_NEEDED_FOR_THE_AVEN_CARD: 'what-credit-score-is-needed-for-the-aven-card',
    CAN_YOU_GET_CASH_FROM_AVEN_CARD: 'can-you-get-cash-from-aven-card',
}
const educationPagePaths = {
    EDUCATION: `/${baseEducationRoute}`,
    HOME_EQUITY_LINE_OF_CREDIT_HELOC_CARD_WHAT_IS_IT: `/${baseEducationRoute}/${educationPageNames.HOME_EQUITY_LINE_OF_CREDIT_HELOC_CARD_WHAT_IS_IT}`,
    HOME_EQUITY_LINE_OF_CREDIT_HELOC_CARD_HOW_IT_WORKS: `/${baseEducationRoute}/${educationPageNames.HOME_EQUITY_LINE_OF_CREDIT_HELOC_CARD_HOW_IT_WORKS}`,
    HOME_EQUITY_CREDIT_CARD_HOW_TO_GET_ONE: `/${baseEducationRoute}/${educationPageNames.HOME_EQUITY_CREDIT_CARD_HOW_TO_GET_ONE}`,
    THE_FASTEST_WAY_TO_GET_A_HELOC: `/${baseEducationRoute}/${educationPageNames.THE_FASTEST_WAY_TO_GET_A_HELOC}`,
    WHEN_ARE_HELOCS_HOME_EQUITY_LINES_OF_CREDIT_A_GOOD_IDEA: `/${baseEducationRoute}/${educationPageNames.WHEN_ARE_HELOCS_HOME_EQUITY_LINES_OF_CREDIT_A_GOOD_IDEA}`,
    WHAT_IS_A_HOME_EQUITY_LINE_OF_CREDIT_HELOC_A_BEGINNERS_GUIDE: `/${baseEducationRoute}/${educationPageNames.WHAT_IS_A_HOME_EQUITY_LINE_OF_CREDIT_HELOC_A_BEGINNERS_GUIDE}`,
    HOME_EQUITY_LINES_CREDIT_HELOCS_VS_MORTGAGES_SIMILARITIES_DIFFERENCES: `/${baseEducationRoute}/${educationPageNames.HOME_EQUITY_LINES_CREDIT_HELOCS_VS_MORTGAGES_SIMILARITIES_DIFFERENCES}`,
    HOW_ARE_HELOC_RATES_DETERMINED: `/${baseEducationRoute}/${educationPageNames.HOW_ARE_HELOC_RATES_DETERMINED}`,
    REFINANCING_A_HELOC: `/${baseEducationRoute}/${educationPageNames.REFINANCING_A_HELOC}`,
    HOW_TO_GET_LOWEST_RATE: `/${baseEducationRoute}/${educationPageNames.HOW_TO_GET_LOWEST_RATE}`,
    FIXED_OR_VARIABLE: `/${baseEducationRoute}/${educationPageNames.FIXED_OR_VARIABLE}`,
    HELOC_ON_RENTAL_PROPERTIES: `/${baseEducationRoute}/${educationPageNames.HELOC_ON_RENTAL_PROPERTIES}`,
    MOBILE_BANKING_SECURITY_TIPS: `/${baseEducationRoute}/${educationPageNames.MOBILE_BANKING_SECURITY_TIPS}`,
    HOME_DEPOT_VS_AVEN: `/${baseEducationRoute}/${educationPageNames.HOME_DEPOT_VS_AVEN}`,
    WHAT_IS_AN_AVEN_CARD: `/${baseEducationRoute}/${educationPageNames.WHAT_IS_AN_AVEN_CARD}`,
    WHAT_CREDIT_SCORE_IS_NEEDED_FOR_THE_AVEN_CARD: `/${baseEducationRoute}/${educationPageNames.WHAT_CREDIT_SCORE_IS_NEEDED_FOR_THE_AVEN_CARD}`,
    CAN_YOU_GET_CASH_FROM_AVEN_CARD: `/${baseEducationRoute}/${educationPageNames.CAN_YOU_GET_CASH_FROM_AVEN_CARD}`,
}

const baseSupportRoute = 'support'
const supportPageNames = {
    CALL_BOOKING: 'call_booking',
}
const supportPagePaths = {
    SUPPORT: `/${baseSupportRoute}`,
    CALL_BOOKING: `/${baseSupportRoute}/${supportPageNames.CALL_BOOKING}`,
}

const basePressRoute = 'press'
const pressPageNames = {
    SERIES_D: 'series-d',
}
const pressPagePaths = {
    SERIES_D: `/${basePressRoute}/${pressPageNames.SERIES_D}`,
}

// define routes to prerender
const routesToPrerender = [
    '/',
    '/privacy',
    '/app',
    '/reviews',
    '/careers',
    '/disclosures',
    '/about',
    '/contact',
    '/linkLanding', // This is our PIF pre-rendered page that includes og:title & og:image for preview sharing
    '/linkTravelLanding', // This is our PIF pre-rendered page that includes og:title & og:image for preview sharing
    '/bahamas', // This is our PIF pre-rendered page that includes og:title & og:image for preview sharing
    '/helocCreditCard',
    '/helocCompare',
    '/fastClose',
    '/onlineNotary',
    '/compareHeloc',
    '/closeFast',
    '/notaryOnline',
    ...Object.values(supportPagePaths),
    ...Object.values(educationPagePaths),
    ...Object.values(pressPagePaths),
]

const routeExperiments = {
    default: 'default20210802',
}

// export these constants so they can be used in other routes/files
module.exports = { routesToPrerender, educationPagePaths, educationPageNames, routeExperiments, pressPagePaths, pressPageNames }
