import type { RouteRecordRaw } from 'vue-router'

const routeName = 'dataSupport'

const dataSupportPageNames = {
    DATA_SUPPORT_HOME: 'data_support_home',
    DATA_SUPPORT_CALL_BOOKING: 'data_support_call_booking',
}

const dataSupportPagePaths = {
    DATA_SUPPORT_HOME: `/${routeName}`,
    DATA_SUPPORT_CALL_BOOKING: `/${routeName}/callBooking`,
}

export const dataSupportPageRoutes: RouteRecordRaw[] = [
    {
        path: dataSupportPagePaths.DATA_SUPPORT_HOME,
        name: dataSupportPageNames.DATA_SUPPORT_HOME,
        component: () => import(/* webpackChunkName: "support" */ '@/pages/dataSupport/DataSupportLanding.vue'),
        meta: { public: true },
    },
    {
        path: dataSupportPagePaths.DATA_SUPPORT_CALL_BOOKING,
        name: dataSupportPageNames.DATA_SUPPORT_CALL_BOOKING,
        component: () => import(/* webpackChunkName: "support" */ '@/pages/dataSupport/DataSupportCallBooking.vue'),
        meta: { public: true },
    },
]

export { dataSupportPagePaths, dataSupportPageNames }
