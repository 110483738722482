import { mloRoutePaths } from '@/routes/mloRoutes'
import { RouteOption, tryGetNextFlowPath, tryGetOptionalPath } from '@/flow/flowUtility'

export const mloFlow: string[] = [
    mloRoutePaths.MLO_DASHBOARD,
    mloRoutePaths.MLO_APPLICATION_PERSONAL_INFO,
    // mloRoutePaths.MLO_APPLICATION_UW_VALUATION,
    // mloRoutePaths.MLO_APPLICATION_FLOOD_DETERMINATION,
    // mloRoutePaths.MLO_APPLICATION_REPORT,
    mloRoutePaths.MLO_APPLICATION_PQ_CHOICE_PREVIEW,
    mloRoutePaths.MLO_APPLICATION_PQ_OVERVIEW,
]

const optionalFlowMap: Map<string, Map<string, string[]>> = new Map()

export const getMloNextRouteWithCurrentPath = (currentPath: string, routeOption?: RouteOption): string | null => {
    if (routeOption) {
        return tryGetOptionalPath(currentPath, optionalFlowMap, routeOption)
    }

    const nextRoute = tryGetNextFlowPath(currentPath, mloFlow, optionalFlowMap)
    return nextRoute
}
