import { logger } from '@/utils/logger'

// WARNING: THESE UTILITY FUNCTIONS ARE MEANT TO BE USED FOR UI DISPLAY PURPOSES ONLY
// DO NOT USE THESE IN ANY LEGAL OR FINANCIAL CALCULATIONS

export const toFormattedUSDStripTrailingZeroCents = (number: number): string => {
    let stringNum = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
    }).format(number)

    stringNum = stringNum.replace(/\.00$/, '')

    return stringNum
}

/**
 * Extracts and returns the decimal places of a number up to a specified number of digits.
 * expect(getDecimalPlacesForUI(100.125, 2)).toBe('.13')
 * expect(getDecimalPlacesForUI(100.1, 2)).toBe('.10')
 * expect(getDecimalPlacesForUI(100)).toBe(null)
 * expect(getDecimalPlacesForUI(100.125, 3)).toBe('.125')
 * expect(getDecimalPlacesForUI(100.1, 1)).toBe('.1')
 * expect(getDecimalPlacesForUI(100.001, 3)).toBe('.001')
 * expect(getDecimalPlacesForUI(100.1234, 2)).toBe('.12')
 * expect(getDecimalPlacesForUI(100.1, 3)).toBe('.100')
 * @param number - The number from which to extract the decimal places.
 * @param keepDigitsToKeep - The number of decimal places to keep (default is 2).
 * @returns A string representing the extracted decimal places, or an empty string if there are no decimal places.
 */
export const getDecimalPlacesForUI = (number: number, keepDigitsToKeep = 2): string | null => {
    // Find the position of the decimal point
    const decimalIndex = number.toString().indexOf('.')

    // If there's no decimal point, return an empty string
    if (decimalIndex === -1) {
        return null
    }
    // Convert the number to a string with enough precision
    const roundedNumber = number.toFixed(keepDigitsToKeep)

    // Extract the decimal part of the number
    const decimalPart = roundedNumber.substring(decimalIndex + 1)

    // Return the decimal part with a leading decimal point
    return decimalPart
}

export const toFormattedUSDNoCents = function (number: number, round: 'round' | 'floor' | 'ceil' = 'round'): string {
    let roundedNumber: number

    switch (round) {
        case 'floor':
            roundedNumber = Math.floor(number)
            break
        case 'ceil':
            roundedNumber = Math.ceil(number)
            break
        case 'round':
        default:
            roundedNumber = Math.round(number)
            break
    }

    return new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
    }).format(roundedNumber)
}

export const formatApr = (number: number): string => {
    return `${parseFloat(String(number * 100))
        .toFixed(2)
        .replace('.00', '')}%`
}

export const toFormattedAprStripTrailingZerosAfterDecimalPoint = (number: number): string => {
    let stringNum = parseFloat(String(number * 100)).toFixed(2)

    stringNum = stringNum.replace(/\.00$/, '')

    if (/\.\d0$/.test(stringNum)) {
        // match XX.X0 to drop the last 0
        return stringNum.slice(0, stringNum.length - 1) + '%'
    }
    return stringNum + '%'
}

export const formatRatioToPercentageStripTrailingZerosAndAtMostOneDecimalDigit = (ratioNumber: number): string => {
    let stringNum = parseFloat(String(ratioNumber * 100)).toFixed(1)
    stringNum = stringNum.replace(/\.0$/, '')
    return stringNum + '%'
}

export const formatNumberStripTrailingZerosAfterDecimalPoint = (number: number): string => {
    let stringNum = number.toFixed(2)
    stringNum = stringNum.replace(/\.00$/, '')
    return stringNum
}

export const formatMonthsToYearsAndMonths = function (numOfMonths: number): string {
    const years = Math.floor(numOfMonths / 12)
    const remainingMonths = numOfMonths % 12
    if (years > 0 && remainingMonths > 0) {
        return `${years} Year${years > 1 ? 's' : ''} ${remainingMonths} Month${remainingMonths > 1 ? 's' : ''}`
    } else if (years > 0) {
        return `${years} Year${years > 1 ? 's' : ''}`
    } else {
        // remainingMonths > 0
        return `${remainingMonths} Month${remainingMonths > 1 ? 's' : ''}`
    }
}

export const toFormattedUSDKs = function (number: number) {
    return `${toFormattedUSDNoCents(number / 1000)}K`
}

export default {
    methods: {
        toFormattedUSD(number: number) {
            return new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: 'USD',
            }).format(number)
        },
        toFormattedUSDStripTrailingZeroCents,
        toFormattedUSDNoCents,
        toFormattedUSDKs,
        formatApr,
        toFormattedAprStripTrailingZerosAfterDecimalPoint,
        formatRatioToPercentageStripTrailingZerosAndAtMostOneDecimalDigit,
        formatNumberStripTrailingZerosAfterDecimalPoint,
        // use lodash?
        camelCase: (value: string) => {
            if (!value) return ''

            const ret = value.charAt(0).toUpperCase() + value.substring(1).toLowerCase()
            logger.info(`value: ${value} camcelCase: ${ret}`)
            return ret
        },
        formatMonthsToYearsAndMonths,
    },
}
