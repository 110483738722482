"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ChurnRetentionTreatmentType = void 0;
var ChurnRetentionTreatmentType;
(function (ChurnRetentionTreatmentType) {
    ChurnRetentionTreatmentType["CLI"] = "CLI";
    ChurnRetentionTreatmentType["APR_REDUCTION"] = "APR_REDUCTION";
    ChurnRetentionTreatmentType["CLI_AND_APR_REDUCTION"] = "CLI_AND_APR_REDUCTION";
    ChurnRetentionTreatmentType["CLI_AND_APR_REDUCTION_AND_BALANCE_SWEEP"] = "CLI_AND_APR_REDUCTION_AND_BALANCE_SWEEP";
    ChurnRetentionTreatmentType["MORTGAGE_BROKERING"] = "MORTGAGE_BROKERING";
    ChurnRetentionTreatmentType["FIXED_TERM_INSTALLMENT_PLAN"] = "FIXED_TERM_INSTALLMENT_PLAN";
    ChurnRetentionTreatmentType["DEFENSIVE_FIXED_MORTGAGE_PLAN"] = "DEFENSIVE_FIXED_MORTGAGE_PLAN";
    ChurnRetentionTreatmentType["FULL_BALANCE_SWEEP_WITH_FEE"] = "FULL_BALANCE_SWEEP_WITH_FEE";
    ChurnRetentionTreatmentType["CLI_THEN_BALANCE_SWEEP_FULL_LINE"] = "CLI_THEN_BALANCE_SWEEP_FULL_LINE";
})(ChurnRetentionTreatmentType = exports.ChurnRetentionTreatmentType || (exports.ChurnRetentionTreatmentType = {}));
