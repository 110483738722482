"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AvenProperty = void 0;
var AvenProperty;
(function (AvenProperty) {
    /**
     * Sessions created for Aven card applicants/applications.
     */
    AvenProperty["ORIGINATION"] = "ORIGINATION";
    AvenProperty["NOTARY"] = "NOTARY";
    /**
     * Sessions created for advisor users when they begin to the flow to create an Advisor account.
     * See landing page Advisor.vue
     */
    AvenProperty["ADVISOR"] = "ADVISOR";
    AvenProperty["SCORE"] = "SCORE";
    AvenProperty["NET_WORTH"] = "NET_WORTH";
    AvenProperty["CRYPTO"] = "CRYPTO";
    AvenProperty["AUTO"] = "AUTO";
    AvenProperty["MY"] = "MY";
    AvenProperty["OPERATIONS"] = "OPERATIONS";
    AvenProperty["MLO"] = "MLO";
    AvenProperty["UCC"] = "UCC";
    /**
     * Sessions created for non-customers when they begin the flow to create a PIF code.
     * See landing page PifForAllLandingPage.vue
     */
    AvenProperty["PIF_FOR_ALL_SENDER"] = "PIF_FOR_ALL_SENDER";
    /**
     * Sessions created for non-customers when they visit Aven Reviews Landing Page -> avenreviews.com
     */
    AvenProperty["AVEN_REVIEWS"] = "AVEN_REVIEWS";
    /**
     * Sessions created for non-customers when they visit Best credit card for Home Depot -> comparecreditcardsforhomedepot.com/
     */
    AvenProperty["BEST_CREDIT_CARD_FOR_HOME_DEPOT"] = "BEST_CREDIT_CARD_FOR_HOME_DEPOT";
    /**
     * Retool dashboard that Customer Service Reps use
     */
    AvenProperty["CSR_RETOOL"] = "CSR_RETOOL";
})(AvenProperty = exports.AvenProperty || (exports.AvenProperty = {}));
