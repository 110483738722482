import type { RouteRecordRaw } from 'vue-router'
import { educationPagePaths, educationPageNames } from './prerenderRoutes'

const educationPageRoutes: RouteRecordRaw[] = [
    {
        path: educationPagePaths.EDUCATION,
        name: educationPageNames.EDUCATION,
        component: () => import(/* webpackChunkName: "education" */ '@/pages/education/EducationPage.vue'),
        meta: { public: true },
    },
    {
        path: educationPagePaths.HOME_EQUITY_LINE_OF_CREDIT_HELOC_CARD_WHAT_IS_IT,
        name: educationPageNames.HOME_EQUITY_LINE_OF_CREDIT_HELOC_CARD_WHAT_IS_IT,
        component: () => import(/* webpackChunkName: "education" */ '@/pages/education/HomeEquityLineOfCreditHelocCardWhatIsIt.vue'),
        meta: { public: true },
    },
    {
        path: educationPagePaths.HOME_EQUITY_LINE_OF_CREDIT_HELOC_CARD_HOW_IT_WORKS,
        name: educationPageNames.HOME_EQUITY_LINE_OF_CREDIT_HELOC_CARD_HOW_IT_WORKS,
        component: () => import(/* webpackChunkName: "education" */ '@/pages/education/HomeEquityLineOfCreditHelocCardHowItWorks.vue'),
        meta: { public: true },
    },
    {
        path: educationPagePaths.HOME_EQUITY_CREDIT_CARD_HOW_TO_GET_ONE,
        name: educationPageNames.HOME_EQUITY_CREDIT_CARD_HOW_TO_GET_ONE,
        component: () => import(/* webpackChunkName: "education" */ '@/pages/education/HomeEquityCreditCardHowToGetOne.vue'),
        meta: { public: true },
    },
    {
        path: educationPagePaths.THE_FASTEST_WAY_TO_GET_A_HELOC,
        name: educationPageNames.THE_FASTEST_WAY_TO_GET_A_HELOC,
        component: () => import(/* webpackChunkName: "education" */ '@/pages/education/TheFastestWayToGetAHELOC.vue'),
        meta: { public: true },
    },
    {
        path: educationPagePaths.WHEN_ARE_HELOCS_HOME_EQUITY_LINES_OF_CREDIT_A_GOOD_IDEA,
        name: educationPageNames.WHEN_ARE_HELOCS_HOME_EQUITY_LINES_OF_CREDIT_A_GOOD_IDEA,
        component: () => import(/* webpackChunkName: "education" */ '@/pages/education/WhenAreHelocsHomeEquityLinesOfCreditAGoodIdea.vue'),
        meta: { public: true },
    },
    {
        path: educationPagePaths.WHAT_IS_A_HOME_EQUITY_LINE_OF_CREDIT_HELOC_A_BEGINNERS_GUIDE,
        name: educationPageNames.WHAT_IS_A_HOME_EQUITY_LINE_OF_CREDIT_HELOC_A_BEGINNERS_GUIDE,
        component: () => import(/* webpackChunkName: "education" */ '@/pages/education/WhatIsAHomeEquityLineOfCreditHelocABeginnersGuide.vue'),
        meta: { public: true },
    },
    {
        path: educationPagePaths.HOME_EQUITY_LINES_CREDIT_HELOCS_VS_MORTGAGES_SIMILARITIES_DIFFERENCES,
        name: educationPageNames.HOME_EQUITY_LINES_CREDIT_HELOCS_VS_MORTGAGES_SIMILARITIES_DIFFERENCES,
        component: () => import(/* webpackChunkName: "education" */ '@/pages/education/HomeEquityLinesCreditHelocsVsMortgagesSimilaritiesDifferences.vue'),
        meta: { public: true },
    },
    {
        path: educationPagePaths.HOW_ARE_HELOC_RATES_DETERMINED,
        name: educationPageNames.HOW_ARE_HELOC_RATES_DETERMINED,
        component: () => import(/* webpackChunkName: "education" */ '@/pages/education/HowAreHelocRatesDetermined.vue'),
        meta: { public: true },
    },
    {
        path: educationPagePaths.REFINANCING_A_HELOC,
        name: educationPageNames.REFINANCING_A_HELOC,
        component: () => import(/* webpackChunkName: "education" */ '@/pages/education/RefinancingAHeloc.vue'),
        meta: { public: true },
    },
    {
        path: educationPagePaths.HOW_TO_GET_LOWEST_RATE,
        name: educationPageNames.HOW_TO_GET_LOWEST_RATE,
        component: () => import(/* webpackChunkName: "education" */ '@/pages/education/HowToGetLowestRate.vue'),
        meta: { public: true },
    },
    {
        path: educationPagePaths.FIXED_OR_VARIABLE,
        name: educationPageNames.FIXED_OR_VARIABLE,
        component: () => import(/* webpackChunkName: "education" */ '@/pages/education/FixedOrVariable.vue'),
        meta: { public: true },
    },
    {
        path: educationPagePaths.HELOC_ON_RENTAL_PROPERTIES,
        name: educationPageNames.HELOC_ON_RENTAL_PROPERTIES,
        component: () => import(/* webpackChunkName: "education" */ '@/pages/education/HelocOnRentalProperties.vue'),
        meta: { public: true },
    },
    {
        path: educationPagePaths.MOBILE_BANKING_SECURITY_TIPS,
        name: educationPageNames.MOBILE_BANKING_SECURITY_TIPS,
        component: () => import(/* webpackChunkName: "education" */ '@/pages/education/SecurityTips.vue'),
        meta: { public: true },
    },
    {
        path: educationPagePaths.HOME_DEPOT_VS_AVEN,
        name: educationPageNames.HOME_DEPOT_VS_AVEN,
        component: () => import(/* webpackChunkName: "education" */ '@/pages/education/HomeDepotVsAven.vue'),
        meta: { public: true },
    },
    {
        path: educationPagePaths.WHAT_IS_AN_AVEN_CARD,
        name: educationPageNames.WHAT_IS_AN_AVEN_CARD,
        component: () => import(/* webpackChunkName: "education" */ '@/pages/education/WhatIsAnAvenCard.vue'),
        meta: { public: true },
    },
    {
        path: educationPagePaths.WHAT_CREDIT_SCORE_IS_NEEDED_FOR_THE_AVEN_CARD,
        name: educationPageNames.WHAT_CREDIT_SCORE_IS_NEEDED_FOR_THE_AVEN_CARD,
        component: () => import(/* webpackChunkName: "education" */ '@/pages/education/WhatCreditScoreIsNeeded.vue'),
        meta: { public: true },
    },
    {
        path: educationPagePaths.CAN_YOU_GET_CASH_FROM_AVEN_CARD,
        name: educationPageNames.CAN_YOU_GET_CASH_FROM_AVEN_CARD,
        component: () => import(/* webpackChunkName: "education" */ '@/pages/education/CanYouGetCashFromAvenCard.vue'),
        meta: { public: true },
    },
]

export const educationRoutes: RouteRecordRaw[] = educationPageRoutes

export { educationPagePaths, educationPageNames }
