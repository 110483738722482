import { createI18n } from 'vue-i18n'
import { ENGLISH_TRANSLATIONS } from '@/localization/en'

const TRANSLATIONS = {
    en: { ...ENGLISH_TRANSLATIONS },
}

const i18n = createI18n({
    locale: 'en',
    fallbackLocale: 'en',
    messages: TRANSLATIONS,
})

const i18nOverride = {
    ...i18n,
    t: i18n.global.t,
}
// TODO: not sure if this works or will destroy me
export { i18nOverride as i18n }
