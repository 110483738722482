import type { Router } from 'vue-router'
import { marketingPagePaths } from '@/routes/marketingRoutes'
import { EXPERIMENT_SPEC_GROUP_NAME, EXPERIMENT_TYPE, isExperimentEnabled } from '@/experiments/getOverrideForExperiment'
import { experimentPagePaths } from '@/experiments/src/routes/marketingRoutes'

export const redirections = (router: Router) => {
    const getRedirectionPath = (path: `/${string}`) => {
        // Even though we check against experiment with referrers, given that the experiment is persisted across the
        // entire session, we still need to check if we are in the referrer path in order to do the redirection, if we
        // don't do this, it will occur that if for some reason the user navigates to any other route it will still get
        // redirected given that the experiment will still be detected and this guard runs before every route
        // navigation.

        const isJoinRoute = path === marketingPagePaths.LANDING_JOIN
        if (isExperimentEnabled(EXPERIMENT_TYPE.landingPage, EXPERIMENT_SPEC_GROUP_NAME.rocketLandingPageRedirection) && isJoinRoute) {
            return '/launchpad'
        }

        const isCreditCardRoute = path === experimentPagePaths.CREDIT_CARD
        if (isExperimentEnabled(EXPERIMENT_TYPE.landingPage, EXPERIMENT_SPEC_GROUP_NAME.creditCardRocketLandingPageRedirection) && isCreditCardRoute) {
            return '/launchpad'
        }

        // If the user is in specific DM route and the experiment is enabled, redirect to launchpadmail, which forces the input of invite code
        const isDmLaunchpadRoute = [experimentPagePaths.JOIN_AVEN, experimentPagePaths.GO_AVEN].includes(path)
        if (isDmLaunchpadRoute && isExperimentEnabled(EXPERIMENT_TYPE.landingPage, EXPERIMENT_SPEC_GROUP_NAME.showLaunchpadForDM)) {
            return '/launchpadmail'
        }

        return undefined
    }

    router.beforeEach((to, from, next) => {
        const routerRedirectionPath = getRedirectionPath(to.path as `/${string}`)
        if (routerRedirectionPath) {
            next(routerRedirectionPath)
            return
        }

        next()
    })
}
