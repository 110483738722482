import { inspect, logger } from '@/utils/logger'
import { coApplicantHttpClient, httpClient, loggerHttpClient, mloLoanHttpClient } from '@/utils/http-client'
import 'url-search-params-polyfill' // Just to be safe
import OpenReplayTracker from '@openreplay/tracker'
import { submitSessionRecordingUrl } from './marketing'
import { appSessionStorage, sessionStorageKey } from '@/utils/storage'
import { InputMode } from '@openreplay/tracker/lib/modules/input'

const openReplayTrackerForSessionId: Record<string, OpenReplayTracker> = {}

const getTrackerSettings = (projectKey: string) => {
    return {
        projectKey,
        ingestPoint: 'https://openreplay.aven.com/ingest',
        captureIFrames: false, // don't capture very sensitive info
        obscureInputEmails: false,
        obscureInputNumbers: false,
        obscureInputDates: false,
        obscureTextEmails: false,
        obscureTextNumbers: false,
        defaultInputMode: InputMode.Plain,
    }
}

class OpenReplay {
    private readonly projectKey: string | undefined

    constructor(projectKey = process.env.VUE_APP_OPENREPLAY_PROJECT_KEY) {
        this.projectKey = projectKey
    }

    public init = async (sessionId: string, userTraits: Record<string, any>) => {
        if (openReplayTrackerForSessionId[sessionId]) {
            logger.info(`Tried to initialize openReplay twice in the same session, exiting instead`)
            return
        }

        logger.info(`OpenReplay with sessionId: ${sessionId}`)
        try {
            let sessionRecordingUrl: string | undefined
            if (this.projectKey) {
                const openReplayTracker = new OpenReplayTracker(getTrackerSettings(this.projectKey))

                await openReplayTracker.start({
                    userID: sessionId,
                    sessionHash: sessionId, // enables cross domain linking of sessions
                    metadata: userTraits,
                })
                openReplayTrackerForSessionId[sessionId] = openReplayTracker

                logger.info(`Adding request watcher for openreplay`)
                httpClient.initializeOpenReplayRequestTracker(openReplayTracker)
                loggerHttpClient.initializeOpenReplayRequestTracker(openReplayTracker)
                coApplicantHttpClient.initializeOpenReplayRequestTracker(openReplayTracker)
                mloLoanHttpClient.initializeOpenReplayRequestTracker(openReplayTracker)

                logger.info(`OpenReplay initialized`)

                logger.info(`Attempting to get openreplay sessionRecordingUrl for sessionID ${sessionId}`)
                sessionRecordingUrl = openReplayTracker.getSessionURL()
            } else {
                logger.info(`Skipping openreplay initialization on ${process.env.VUE_APP_NODE_ENV} env`)
                sessionRecordingUrl = 'local dev test openreplay session url'
            }

            if (sessionRecordingUrl) {
                await submitSessionRecordingUrl(sessionId, sessionRecordingUrl)
            } else {
                logger.error(`Could not get session recording url for openreplay`)
            }
        } catch (error) {
            logger.error(`openreplay failed to initialize for sessiondId: ${sessionId}.`, error)
        }
    }

    public trySetMetadata = (metadata: Record<string, any>, sessionIdParam?: string) => {
        const sessionId = sessionIdParam || appSessionStorage.getItem(sessionStorageKey.sessionId)
        if (!sessionId) {
            logger.error(`Tried to set metadata with no sessionId, exiting instead`)
            return false
        }

        if (!openReplayTrackerForSessionId[sessionId]) {
            logger.error(`Tried to set metadata with uninitialized openreplay exiting instead`)
            return false
        }
        try {
            logger.info(`Trying to set metadata on openreplay: ${inspect(metadata)}`)
            for (const [trait, value] of Object.entries(metadata)) {
                openReplayTrackerForSessionId[sessionId].setMetadata(trait, value)
            }
        } catch (error) {
            logger.error(`failed to setMetadata on openreplay: ${inspect(metadata)}.`, error)
        }
        return true
    }
}

export const openReplay = new OpenReplay()
export const autoOpenReplay = new OpenReplay(process.env.VUE_APP_AUTO_OPENREPLAY_PROJECT_KEY)
