import { AxiosResponse } from 'axios'
import { httpClient } from '@/utils/http-client'

export const autoUnderwritingMetadata = async (): Promise<AxiosResponse> => {
    return await httpClient.get(`/auto/underwritingMetadata`)
}

export const getOrCreateAutoApplicationWithLicensePlate = async (licensePlate: string, licensePlateState: string, sessionId: string): Promise<AxiosResponse> => {
    return await httpClient.post(
        '/auto/getOrCreateAutoApplicationWithLicensePlate',
        {
            licensePlate,
            licensePlateState,
            sessionId,
        },
        {
            timeout: 60000,
        }
    )
}

export const getOrCreateAutoApplicationWithVehicleModel = async (
    vehicle: {
        year: string
        make: string
        model: string
        trim: string
        mileage: string
    },
    sessionId: string
): Promise<AxiosResponse> => {
    const { year, make, model, trim, mileage } = vehicle
    return await httpClient.post(
        '/auto/getOrCreateAutoApplicationWithVehicleModel',
        {
            year,
            make,
            model,
            trim,
            mileage,
            sessionId,
        },
        {
            timeout: 60000,
        }
    )
}

export const getVehicleYears = async () => {
    return await httpClient.get('/auto/vehicleYears')
}

export const getVehicleMakes = async (year: string) => {
    return await httpClient.get('/auto/vehicleMakes', { params: { year } })
}

export const getVehicleModels = async (year: string, make: string) => {
    return await httpClient.get('/auto/vehicleModels', { params: { year, make } })
}

export const getVehicleTrims = async (year: string, make: string, model: string) => {
    return await httpClient.get('/auto/vehicleTrims', { params: { year, make, model } })
}
