// @ts-ignore - themes is not typed correctly for some reason
import { generateClasses } from '@formkit/themes'
import { DefaultConfigOptions } from '@formkit/vue'
import { genesisIcons } from '@formkit/icons'
import { createProPlugin, inputs } from '@formkit/pro'
import { createFloatingLabelsPlugin } from '@formkit/addons'
import 'aven_shared/src/styles/formkitAven.css'
import ssnLast4 from 'aven_shared/src/utils/formkitRules/ssnLast4'
import dateLong from 'aven_shared/src/utils/formkitRules/dateLong'
import phoneLength from 'aven_shared/src/utils/formkitRules/phoneLength'

const pro = createProPlugin(process.env.VUE_APP_FORMKIT_KEY as string, inputs)
const floatingLabels = createFloatingLabelsPlugin({
    useAsDefault: true, // defaults to false
})
const config: DefaultConfigOptions = {
    icons: { ...genesisIcons },
    config: {
        classes: generateClasses({
            global: {
                messages: 'tw-list-none tw-list-image-none',
                label: 'form-label',
            },
            form: {
                messages: 'd-none',
            },
            submit: {
                wrapper: '$reset d-grid',
                input: '$reset btn btn-primary',
            },
            button: {
                wrapper: '$reset d-grid',
                input: '$reset btn btn-primary',
            },
        }),
    },
    rules: {
        ssnLast4,
        dateLong,
        phoneLength,
    },
    messages: {
        en: {
            validation: {
                required() {
                    return 'This field is required'
                },
                ssnLast4() {
                    return 'Last 4 SSN digits are required'
                },
                min(node) {
                    return `Must be greater than or equal to ${node.args[0]}`
                },
                email() {
                    return 'Must be a valid email'
                },
                length(node) {
                    return `Must be at least ${node.args[0]} characters`
                },
                dateLong() {
                    return `Format must be MM/DD/YYYY`
                },
                phoneLength() {
                    return `Not a valid phone number`
                },
            },
        },
    },
    plugins: [pro, floatingLabels],
}

export default config
