<template>
    <div id="app">
        <router-view :key="$route.path" />
    </div>
</template>

<script>
    import { defineComponent } from 'vue'
    import { setLocalMetadata } from '@/services/marketing'
    import { runWithRetryLogic } from '@/utils/http-client'
    import { setMeta } from '@/utils/useMeta'

    export default defineComponent({
        name: 'App',
        setup() {
            setMeta({
                title: 'Aven HELOC Card: A Credit Card backed by Home Equity',
                meta: [
                    {
                        name: 'description',
                        vmid: 'description',
                        content: 'Unlock the power of home equity to get insanely low credit card rates. Stop worrying about promo rates expiring. Save every single month, forever.',
                    },
                ],
            })
        },
        created() {
            this.$cookies.set('locale', 'en')
        },
        mounted() {
            runWithRetryLogic(setLocalMetadata, 2)
        },
    })
</script>

<style lang="scss">
    @import 'node_modules/aven_shared/src/styles/main_new.scss';
    @import 'node_modules/aven_shared/src/styles/tailwind.scss';

    //@import 'styles/main.scss';
</style>
