// Constants
export const ESTIMATED_CARD_MAIL_ARRIVAL_BUSINESS_DAYS = '5-10'

// Keep in sync with LoanApplicationType in backend
export enum LoanApplicationType {
    AVEN_ORIGINATION = 'AVEN_ORIGINATION',
    AVEN_MLO_ORIGINATION = 'AVEN_MLO_ORIGINATION',
    AVEN_HOME_REATTACH = 'AVEN_HOME_REATTACH',
    AVEN_HOME_REUNDERWRITING_LINE_SIZE_INCREASE = 'AVEN_HOME_REUNDERWRITING_LINE_SIZE_INCREASE',
    AVEN_HOME_REUNDERWRITING_LINE_SIZE_INCREASE_DRYRUN = 'AVEN_HOME_REUNDERWRITING_LINE_SIZE_INCREASE_DRYRUN',
    AVEN_HOME_REUNDERWRITING_APR_REDUCTION = 'AVEN_HOME_REUNDERWRITING_APR_REDUCTION',
    AVEN_HOME_CLI_AND_APR_REDUCTION_AND_BALANCE_SWEEP = 'AVEN_HOME_CLI_AND_APR_REDUCTION_AND_BALANCE_SWEEP',
    _AVEN_WAITLIST = '_AVEN_WAITLIST',
    _AVEN_HOME_REUNDERWRITING_ANNUAL_RENEWAL = 'AVEN_HOME_REUNDERWRITING_ANNUAL_RENEWAL',
    MORTGAGE_BROKERING = 'MORTGAGE_BROKERING',
    AVEN_HOME_REUNDERWRITING_POLICY_CHANGE = 'AVEN_HOME_REUNDERWRITING_POLICY_CHANGE',
    AVEN_UCC_ORIGINATION = 'AVEN_UCC_ORIGINATION',
}

// Keep in sync with LoanApplicationStatus in backend
export enum LoanApplicationStatus {
    pending = 'pending',
    preQualified = 'preQualified',
    preQualificationAccepted = 'preQualificationAccepted',
    incomeVerification = 'incomeVerification',
    incomeVerified = 'incomeVerified',
    floodInsuranceVerification = 'floodInsuranceVerification',
    floodInsuranceVerified = 'floodInsuranceVerified',
    offered = 'offered',
    accepted = 'accepted',
    awaitingSuccessfulNotarizations = 'awaitingSuccessfulNotarizations',
    notarizationsComplete = 'notarizationsComplete',
    withdrawn = 'withdrawn',
    declined = 'declined',
    approved = 'approved',
    denied = 'denied',
    blocked = 'blocked',
    trialDenialDm = 'trialDenialDm',
    deniedIndividualCanAddCoApplicant = 'deniedIndividualCanAddCoApplicant',
    humanInvestigate = 'humanInvestigate',
    ineligible = 'ineligible',
    incomplete = 'incomplete',
    offeredNotApproved = 'offeredNotApproved',
    duplicate = 'duplicate',
    mobileNotary = 'mobileNotary', // application is in a county that requires RIN
}

// Keep in sync with terminalLoanApplicationStatuses in backend (loanApplicationHelper.ts)
export const terminalLoanApplicationStatuses: readonly LoanApplicationStatus[] = [
    LoanApplicationStatus.incomplete,
    LoanApplicationStatus.offeredNotApproved,
    LoanApplicationStatus.duplicate,
    LoanApplicationStatus.ineligible,
    LoanApplicationStatus.denied,
    LoanApplicationStatus.deniedIndividualCanAddCoApplicant, // we create new LAs for applicants who reach this status so it is still terminal
    LoanApplicationStatus.approved,
    LoanApplicationStatus.blocked,
    LoanApplicationStatus.withdrawn,
    LoanApplicationStatus.declined,
] as const

// Keep in sync with activeLoanApplicationStatuses in backend (loanApplicationHelper.ts)
export const activeLoanApplicationStatuses: LoanApplicationStatus[] = Object.values(LoanApplicationStatus).filter(
    (status) => !terminalLoanApplicationStatuses.concat([LoanApplicationStatus.trialDenialDm]).includes(status)
)

// Keep in sync with terminalNonApprovedLoanApplicationStatuses in backend (loanApplicationHelper.ts)
export const terminalNonApprovedLoanApplicationStatuses: LoanApplicationStatus[] = Object.values(terminalLoanApplicationStatuses).filter((status) => status !== LoanApplicationStatus.approved)

// Keep in sync with backend (loanApplication.ts)
export enum ConsentToMloOriginationStatus {
    pending = 'pending',
    consentEmailSent = 'consentEmailSent',
    consented = 'consented',
    declined = 'declined',
}

// Keep in sync with ApplicantType in aven_backend/src/entity/applicant.ts
export enum ApplicantType {
    primary = 'primary', // i.e. regular applicant
    coApplicant = 'coApplicant', // is underwritten along with primary
    coOwner = 'coOwner', // purely for signature purposes
    livingTrustMember = 'livingTrustMember', // purely for signature purposes
}

export const CASH_OUT_TRXN_FEE_PERCENTAGE = 2.5
export const INSTALLMENT_PLAN_APR_MOD = 3.5

// debounce time in milliseconds
export const BUTTON_CLICK_DEBOUNCE_TIME_MSEC = 500

// sync with enum AccountClosureReason in aven_backend/src/entity/accountClosureRequest.ts
export enum AccountClosureReason {
    LOWER_MONTHLY_PAYMENTS = 'lower_monthly_payment',
    LOWER_APR = 'lower_apr',
    ACCESS_MORE_HOME_EQUITY = 'access_more_home_equity',
    SELLING_HOME = 'selling_home',
    NOT_HAPPY = 'not_happy',
    NO_REASON_STATED = 'no_reason_stated',
    LINE_SIZE_TOO_LOW = 'line_size_too_low',
    NOT_USE = 'not_use',
    OTHER = 'other',
    DEPRECATED_REFI = 'refi',
    DEPRECATED_CASH_OUT_REFI = 'cash_out_refi',

    // for account being detected as fraud, we still need to go through the regular account closure procedure (eg reconveyance, etc)
    FRAUD = 'fraud',
    // account is being closed by aven because contingency was not cleared
    CONTINGENCY_REJECTED = 'contingency_rejected',
}

// sync with enum WithdrawOrDeclineReason in aven_backend/src/entity/customerSurveyResponse.types.ts
export enum WithdrawOrDeclineReason {
    HIGH_MONTHLY_PAYMENTS = 'HIGH_MONTHLY_PAYMENTS',
    HIGH_APR = 'HIGH_APR',
    LOW_LINE_SIZE = 'LOW_LINE_SIZE',
    OTHER = 'OTHER',
    DO_NOT_TRUST = 'DO_NOT_TRUST',
    TOO_MUCH_WORK = 'TOO_MUCH_WORK',
}

// sync with enum CustomerSurveyType in aven_backend/src/entity/customerSurveyResponse.types.ts
export enum CustomerSurveyType {
    WithdrawOrDecline = 'WithdrawOrDecline',
}

export enum DownloadMobileAppLink {
    iOS = 'https://apps.apple.com/us/app/aven-card/id1541705487?itsct=apps_box&itscg=30200',
    android = 'https://play.google.com/store/apps/details?id=com.aven.app&hl=en_US',
}
