import axios, { AxiosInstance } from 'axios'
import { logger } from '@/utils/logger'
import { runWithRetryLogic } from '@/utils/http-client'
import assert from 'assert'

export interface SmartySuggestion {
    street_line: string
    secondary: string
    city: string
    state: string
    zipcode: string
    entries: number
}

export class ValidatedAddress {
    addressStreet?: string
    addressCity?: string
    addressState?: string
    addressPostalCode?: string
    addressUnit?: string
    addressValidationError?: AddressValidationError | string

    constructor(data: { street?: string; city?: string; state?: string; zipcode?: string; secondary?: string; addressValidationError?: AddressValidationError | string }) {
        this.addressStreet = data.street
        this.addressUnit = data.secondary
        this.addressCity = data.city
        this.addressState = data.state
        this.addressPostalCode = data.zipcode
        this.addressValidationError = data.addressValidationError
    }

    public fullDescription = () => {
        const street = this.addressUnit ? `${this.addressStreet} ${this.addressUnit}` : `${this.addressStreet}`
        return `${street}, ${this.addressCity}, ${this.addressState} ${this.addressPostalCode}`
    }

    public descriptionWithNoSecondaryAddress = () => {
        const address = [this.addressStreet, this.addressCity, this.addressState].filter((a) => !!a).join(', ')
        return `${address}${this.addressPostalCode ? ` ${this.addressPostalCode}` : ''}`
    }
}

// https://www.smartystreets.com/docs/cloud/us-street-api#http-response-output
export enum InvalidSmartyStreetFootnoteCodes {
    A1 = 'A1', // Address is invalid.
    M1 = 'M1', // Primary number (e.g., house number) is missing

    N1 = 'N1', // Address is missing secondary information (apartment, suite, etc.).
    CC = 'CC', // The submitted secondary information (apartment, suite, etc.) was not recognized,

    F1 = 'F1', // Military or diplomatic address
    PB = 'PB', // PO Box street style address.
    P1 = 'P1', // PO, RR, or HC box number is missing.
    P3 = 'P3', // PO, RR, or HC box number is invalid.
    RR = 'RR', // Confirmed address with private mailbox (PMB) info.
    R7 = 'R7', // Confirmed as a valid address that doesn't currently receive US Postal Service street delivery.
    U1 = 'U1', // Address has a "unique" ZIP Code. https://www.smartystreets.com/articles/unique-zip-codes
}

const InvalidMatchCodesToErrorMessage: Map<InvalidSmartyStreetFootnoteCodes, string> = new Map([
    [InvalidSmartyStreetFootnoteCodes.A1, 'Invalid Address'],
    [InvalidSmartyStreetFootnoteCodes.M1, 'Invalid or missing house number'],
    [InvalidSmartyStreetFootnoteCodes.N1, 'Please provide apartment or unit'],
    [InvalidSmartyStreetFootnoteCodes.CC, 'Invalid apartment or unit'],
    [InvalidSmartyStreetFootnoteCodes.R7, 'Address is not receiving USPS mail currently'],
])

export class AddressValidationError extends Error {
    private smartyFootnoteCodes: InvalidSmartyStreetFootnoteCodes[]
    constructor(message: string, codes: InvalidSmartyStreetFootnoteCodes[]) {
        super(message)
        this.name = 'AddressValidationError'
        this.smartyFootnoteCodes = codes
        Object.setPrototypeOf(this, new.target.prototype)
    }

    public missingSecondaryAddress = (): boolean => {
        return this.smartyFootnoteCodes.includes(InvalidSmartyStreetFootnoteCodes.N1)
    }

    public invalidSecondaryAddress = (): boolean => {
        return this.smartyFootnoteCodes.includes(InvalidSmartyStreetFootnoteCodes.CC)
    }

    public description = (): string => {
        return `AddressValidationError, ${this.message}, codes: ${JSON.stringify(this.smartyFootnoteCodes)}`
    }
}

interface PredictionParams {
    key: string
    search: string
    max_results: number
    prefer_states: string
    selected?: string
}

export class SmartyStreetClient {
    private SMARTY_STREET_AUTO_COMPLETE_URL = 'https://us-autocomplete-pro.api.smartystreets.com/lookup'
    private SMARTY_STREET_VALIDATE_URL = 'https://us-street.api.smartystreets.com/street-address'
    private httpClient: AxiosInstance

    private invalidMatchCodesArray: string[] = Object.values(InvalidSmartyStreetFootnoteCodes)

    constructor() {
        this.httpClient = axios.create({
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
            timeout: 10000,
        })
        this.httpClient.interceptors.response.use(undefined, function smartyErrorStatusHandler(error) {
            if (error.status == 401) {
                logger.fatal('Smarty Street API auth error, check VUE_APP_SMARTY_STREET_API_KEY in env file against what is in Smarty Street account-> API Keys', error)
            } else if (error.status == 402) {
                logger.fatal('Smarty Street API error, payment required!', error)
            } else if (error.status == 413) {
                logger.fatal(`Smarty Street API error, request too big, request: ${JSON.stringify(error.request)}, config: ${JSON.stringify(error.config)}`, error)
            } else if (error.status == 429) {
                logger.fatal('Smarty Street API error, api call was rejected due to rate limit', error)
            } else if (error.status < 200 && error.status >= 300) {
                logger.fatal(`Smarty Street API error, status: ${error.status}`, error)
            }
        })
    }

    public getPredictions = async (address: string, maxResults: number = 5, preferStates: string[], selectedSuggestion: SmartySuggestion | null = null): Promise<SmartySuggestion[]> => {
        assert(process.env.VUE_APP_SMARTY_STREET_API_KEY, 'No smarty street api key!')
        preferStates = preferStates || []
        const params: PredictionParams = {
            key: process.env.VUE_APP_SMARTY_STREET_API_KEY,
            search: address,
            max_results: maxResults,
            prefer_states: preferStates.join(';'),
        }
        if (selectedSuggestion) {
            // This is a specific format prescribed by Smarty:
            // See "Example Logic Flow": https://www.smarty.com/docs/cloud/us-autocomplete-pro-api
            params.selected = `${selectedSuggestion.street_line}${selectedSuggestion.secondary ? `, ${selectedSuggestion.secondary}` : ''}${
                selectedSuggestion.entries ? ` (${selectedSuggestion.entries})` : ''
            }, ${selectedSuggestion.city}, ${selectedSuggestion.state} ${selectedSuggestion.zipcode}`
        }
        const response = await this.httpClient.get(this.SMARTY_STREET_AUTO_COMPLETE_URL, {
            params,
        })
        logger.info(`input: ${address}, predictions: ${JSON.stringify(response?.data['suggestions'])}`)
        const predictionsWithService = (response ? response.data['suggestions'] : []).map((prediction: SmartySuggestion) => {
            return {
                ...prediction,
                service: 'smarty',
            }
        })
        return predictionsWithService
    }

    private _validateAddress = async (suggestion: SmartySuggestion, secondaryUnit: string = '') => {
        const params = {
            key: process.env.VUE_APP_SMARTY_STREET_API_KEY,
            street: suggestion.street_line,
            city: suggestion.city,
            state: suggestion.state,
            zipcode: suggestion.zipcode,
            match: 'invalid',
        } as any

        if (secondaryUnit) {
            params['secondary'] = secondaryUnit
        }
        logger.info(`validate with params: ${JSON.stringify(params)}`)
        const response = await this.httpClient.get(this.SMARTY_STREET_VALIDATE_URL, {
            params,
        })
        logger.info(`validate prediction: ${JSON.stringify(suggestion)}, response: ${JSON.stringify(response.data)}`)
        return this.parseValidationResponse(response.data)
    }

    public validateAddress = async (suggestion: SmartySuggestion, secondaryUnit: string = '') => {
        return await runWithRetryLogic(async () => await this._validateAddress(suggestion, secondaryUnit), 1)
    }

    private parseValidationResponse = (data: object[]): ValidatedAddress => {
        const smartyData: any = data[0]
        if (!smartyData) {
            const validationError = new AddressValidationError('Invalid Address', [InvalidSmartyStreetFootnoteCodes.A1])
            return new ValidatedAddress({ addressValidationError: validationError })
        }

        // see examples, https://www.smartystreets.com/products/apis/us-street-api
        // doc: // https://www.smartystreets.com/docs/cloud/us-street-api#http-response-output
        // tldr;
        // "dpv_footnotes": "AABB",
        // "AABB" ==> ['AA', 'BB']
        const matchCodes: string[] = smartyData['analysis']['dpv_footnotes'].match(/.{1,2}/g)
        // intersection of received codes and InvalidMatchCodes
        const errorCodes = matchCodes.filter((c) => this.invalidMatchCodesArray.includes(c)).map((c) => c as InvalidSmartyStreetFootnoteCodes)
        if (errorCodes.length > 0) {
            const validationError = new AddressValidationError(InvalidMatchCodesToErrorMessage.get(errorCodes[0]) || 'Cannot use this address for application', errorCodes)
            return new ValidatedAddress({ addressValidationError: validationError })
        }

        let addressStreet = smartyData['delivery_line_1']
        const addressComponents = smartyData['components']

        // Remove extra secondary unit/designators if they exist.
        // There may be 'extra' designators, which must be removed from the end of string first
        addressStreet = this.maybeRemoveSecondaryDesignator(addressStreet, addressComponents.extra_secondary_designator, addressComponents.extra_secondary_number)
        // We may also need to remove the secondary designators if they exist
        addressStreet = this.maybeRemoveSecondaryDesignator(addressStreet, addressComponents.secondary_designator, addressComponents.secondary_number)

        if (addressComponents.extra_secondary_number) {
            // we don't know what to do with this kind of address
            // i.e. “5619 Loop 1604, Bldg E-5, Ste. 101 San Antonio TX”,
            // 101 is secondary_number, Ste is secondary_designator
            // E-5 is extra_secondary_number, Bldg is extra_secondary_designator
            // added analytics event so we can monitor this.
            window.logEvent('eventSmartyReturnedExtraSecondaryAddress', { smartyAddressComponents: addressComponents })
        }
        return new ValidatedAddress({
            street: addressStreet,
            city: addressComponents.city_name,
            state: addressComponents.state_abbreviation,
            zipcode: addressComponents.zipcode,
            secondary: addressComponents.secondary_number,
        })
    }

    public maybeRemoveSecondaryDesignator = (street: string, secondaryDesignator: string | null, secondaryNumber: string | null): string => {
        // NOTE: It might be tempting to use a regex here for matching. I avoided doing so since I don't
        // trust dynamically created regex. An awkward value can create an extremely complex regex which
        // can give us some headaches.

        if (!secondaryDesignator || !secondaryNumber) {
            return street
        }

        const suffix = ` ${secondaryDesignator} ${secondaryNumber}`
        if (!street.endsWith(suffix)) {
            return street
        }

        return street.substring(0, street.length - suffix.length)
    }
}
