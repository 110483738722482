import { App } from 'vue'
import VueObserveVisibility from 'vue-observe-visibility'
import VueCookies from 'vue3-cookies'
import VueGtag from 'vue-gtag'
import { createGtm } from '@gtm-support/vue-gtm'
import { plugin as formkitPlugin, defaultConfig } from '@formkit/vue'
import formkitConfig from '@/config/formkit.config'
import { i18n } from '@/utils/i18n'
import router from '@/routes/router'
import { ValidationProvider } from 'vee-validate'
export const initPlugins = (app: App<Element>) => {
    app.use(VueCookies as any, {
        expireTimes: '60d',
        path: `${process.env.VUE_APP_COOKIES_URL}`,
        domain: '',
        secure: true,
        sameSite: 'None',
    })

    // TODO: should be ok to delete this plugin as follow up for now
    app.use(VueObserveVisibility)

    app.use(formkitPlugin, defaultConfig(formkitConfig))

    app.use(i18n)

    // TODO: should be ok to delete this plugin as follow up for now
    app.component('ValidationProvider', ValidationProvider)

    if (['production'].includes(process.env.VUE_APP_NODE_ENV || '')) {
        app.use(
            VueGtag,
            {
                config: {
                    id: process.env.VUE_APP_GOOGLE_ANALYTICS_TAG as string,
                    params: {
                        send_page_view: true,
                        allow_enhanced_conversions: true,
                    },
                },
                includes: [
                    {
                        id: process.env.VUE_APP_GOOGLE_GLOBAL_SITE_TAG as string,
                        params: {
                            send_page_view: true,
                            allow_enhanced_conversions: true,
                        },
                    },
                ],
            },
            router
        )

        // Google Tag Manager (GTM)
        // Be careful if you're going to change ID. It's used in GTM to identify the environment.
        app.use(
            createGtm({
                id: [process.env.VUE_APP_GOOGLE_TAG_MANAGER_ID as string, process.env.VUE_APP_GOOGLE_TAG_MANAGER_ID_ADVISOR as string],
            })
        )
    }
}
