interface MetaPayload {
    title: string
    meta: (({ name: string } | { property: string }) & { content: string })[]
    script?: { type: string; json: any }[]
}

const titleTemplate = `%s | Aven Card`

export const setMeta = (metaPayload: MetaPayload) => {
    const title = document.querySelector('title')
    if (title) {
        title.textContent = titleTemplate.replace('%s', metaPayload.title)
    } else {
        const newTitle = document.createElement('title')
        newTitle.textContent = titleTemplate.replace('%s', metaPayload.title)
        document.head.appendChild(newTitle)
    }

    for (const tag of metaPayload.meta) {
        const existingTag = document.querySelector(`meta[${'name' in tag ? 'name' : 'property'}="${'name' in tag ? tag.name : tag.property}"]`)
        if (existingTag) {
            existingTag.setAttribute('content', tag.content)
        } else {
            const newTag = document.createElement('meta')
            newTag.setAttribute('content', tag.content)
            if ('name' in tag) {
                newTag.setAttribute('name', tag.name)
            } else {
                newTag.setAttribute('property', tag.property)
            }
            document.head.appendChild(newTag)
        }
    }

    if (!metaPayload.script) {
        return
    }
    for (const tag of metaPayload.script) {
        const existingTag = document.querySelector(`script[type="${tag.type}"]`)
        if (existingTag) {
            existingTag.textContent = JSON.stringify(tag.json)
        } else {
            const newTag = document.createElement('script')
            newTag.setAttribute('type', tag.type)
            newTag.textContent = JSON.stringify(tag.json)
            document.head.appendChild(newTag)
        }
    }
}
