import { appSessionStorage, sessionStorageKey } from '@/utils/storage'
import { logger } from '@/utils/logger'
import { markStateStale } from '@/utils/stateUtils'
import { experimentPagePaths } from '@/experiments/src/routes/marketingRoutes'
import { LoanAssetType, UnderwritingMetadata } from 'aven_types'
import { Flows } from '@/flow/flowController'

export const goToPageWithPartialState = (preservedKeys: string[], href: string, preserveSearch?: boolean) => {
    if (preservedKeys.length > 0) {
        logger.info(`Going to ${href} with partial state. Clearing appSessionStorage...`)
        appSessionStorage.clearWithException(preservedKeys)
    } else {
        logger.info(`Going to ${href} with clean state. Clearing appSessionStorage...`)
        appSessionStorage.clear()
    }

    const [path] = (href || '').split('?')
    if (preserveSearch) {
        // Pass href as we were given
        window.location.href = href
    } else {
        // pass just the path, without ?foo=bar...
        window.location.href = path
    }
}

export const goToPageWithCleanState = (href: string, preserveSearch?: boolean) => {
    goToPageWithPartialState([], href, preserveSearch)
}

export const goToInitialPageWithPartialState = (preservedKeys: string[], preserveSearch?: boolean) => {
    const startPagePath = appSessionStorage.getItem(sessionStorageKey.startPagePath) || '/'
    return goToPageWithPartialState(preservedKeys, startPagePath, preserveSearch)
}

export const goToInitialPageWithCleanState = (preserveSearch?: boolean) => {
    const startPagePath = appSessionStorage.getItem(sessionStorageKey.startPagePath) || '/'
    return goToPageWithCleanState(startPagePath, preserveSearch)
}

export const reloadPageWithCleanState = () => {
    logger.info(`Reloading to ${window.location.href} with clean state. Marking current state as stale...`)
    // Mark state as stale so init in main.ts will delete state and continue with fresh session
    markStateStale()
    return window.location.reload()
}

export const getStartPagePath = (): string => {
    const startPath = appSessionStorage.getItem(sessionStorageKey.startPagePath) || ''
    if (Object.values(experimentPagePaths).includes(startPath as any)) {
        return startPath
    }
    return '/'
}

export const maybeReloadWithCleanStateIfTokenOrDifferentFlow = (uwMetadata?: UnderwritingMetadata): boolean => {
    if (window.prerender) {
        // Don't reload if we're in prerender mode
        return false
    }

    if (appSessionStorage.getItem(sessionStorageKey.jwtTokens)) {
        logger.info(`User went to landing page with jwt tokens! Clearing their stale state`)
        reloadPageWithCleanState()
        return true
    }

    if (!uwMetadata) {
        logger.info(`User went to landing page without uw metadata, continuing...`)
        return false
    }

    const currentFlow = appSessionStorage.getItem(sessionStorageKey.currentFlow)
    if (currentFlow === Flows.origination && ![LoanAssetType.home, LoanAssetType.home_no_loyalty].includes(uwMetadata.loanAssetType)) {
        logger.info(`User went to landing page with different flow! Clearing their stale state`)
        reloadPageWithCleanState()
        return true
    }

    if (currentFlow === Flows.uccOrigination && ![LoanAssetType.personalPropertyAttestation].includes(uwMetadata.loanAssetType)) {
        logger.info(`User went to landing page with different flow! Clearing their stale state`)
        reloadPageWithCleanState()
        return true
    }

    logger.info(`User went to landing page with same flow and asset type, continuing...`)
    return false
}
