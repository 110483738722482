/**
 * App Storage class
 * @description This will be responsible for storing data into the application.
 * Commonly, people use LocalStorage or SessionStorage. This is just a wrapper over them
 * because to restrict the usage of Global window storage throughout the application
 * Default, this is just using the LocalStorage
 */
import assert from 'assert'
import { inspect, logger } from '@/utils/logger'
import { merge } from 'lodash'
import type { Ref } from 'vue'
import { customRef } from 'vue'

const sessionStorageKey = {
    basicInfo: 'basicInfo',
    sessionId: 'sessionId',
    inviteCode: 'inviteCode',
    inviteType: 'inviteType',
    paymentCalcBanner: 'paymentCalcBanner',
    pifInviteCode: 'pifInviteCode',
    pifBonus: 'pifBonus',
    pifRewardType: 'pifRewardType',
    pifForAllBonusDollars: 'pifForAllBonusDollars',
    pifSenderName: 'pifSenderName',
    agentPifInviteCode: 'agentPifInviteCode',
    agentPifShareLink: 'agentPifShareLink',
    currentFlow: 'currentFlow',
    locale: 'locale',
    applicantId: 'applicantId',
    applicantType: 'applicantType',
    loanApplicationId: 'loanApplicationId',
    primaryReturnToken: 'primaryReturnToken',
    addedCoApplicantOnFailure: 'addedCoApplicantOnFailure',
    experimentName: 'experimentName',
    ambiguousAddressData: 'amiguousAddressData',
    addressState: 'addressState',
    tcpaConsentText: 'tcpaConsentText',
    phoneNumber: 'phoneNumber',
    dateOfBirth: 'dateOfBirth',
    coApplicantPhoneNumber: 'coApplicantPhoneNumber',
    phoneHash: 'phoneHash',
    plaidLinkToken: 'plaidLinkToken',
    institutionInfo: 'institutionInfo',
    jwtTokens: 'jwtTokens',
    coApplicantJwtTokens: 'coApplicantJwtTokens',
    mloJwtTokens: 'mloJwtTokens',
    mloJwtLoanTokens: 'mloJwtLoanTokens',
    mloId: 'mloId',
    applicationData: 'applicationData',
    statedUsage: 'statedUsage',
    statedUsageAmount: 'statedUsageAmount',
    statedIncome: 'statedIncome',
    coApplicantStatedIncome: 'coApplicantStatedIncome',
    statedAnnualRentalIncome: 'statedAnnualRentalIncome',
    sessionAccessJWT: 'sessionAccessJWT',
    notaryAccessJWT: 'notaryAccessJWT',
    firstName: 'firstName',
    lastName: 'lastName',
    secondaryFirstName: 'secondaryFirstName',
    secondaryLastName: 'secondaryLastName',
    attestedTitleName: 'attestedTitleName',
    email: 'email',
    coApplicantFirstName: 'coApplicantFirstName',
    coApplicantLastName: 'coApplicantLastName',
    isFirstLienPosition: 'isFirstLienPosition',
    isFixedTermPaymentOffer: 'isFixedTermPaymentOffer',
    employer: 'employer',
    jobTitle: 'jobTitle',
    coApplicantJobTitle: 'coApplicantJobTitle',
    startPagePath: 'startPagePath',
    clearStorageOnNavigation: 'clearStorageOnNavigation',
    identityQA: 'identityQA',
    verifiedKba: 'verifiedKba',
    sessionRecordingInitialized: 'sessionRecordingInitialized',
    sessionRecordingUrl: 'sessionRecordingUrl',
    inviteCodeRequired: 'inviteCodeRequired',
    creditOffer: 'creditOffer',
    preQualificationOffer: 'preQualificationOffer',
    offerAcceptedAt: 'offerAcceptedAt',
    incomeVerificationCompleted: 'incomeVerificationCompleted',
    incomeVerificationMethod: 'incomeVerificationMethod',
    rentalIncomeVerificationCompleted: 'rentalIncomeVerificationMethod',
    assetVerificationCompleted: 'assetVerificationCompleted',
    preQualificationFailureCode: 'preQualificationFailureCode',
    payStubInfo: 'payStubInfo',
    bankStatementsInfo: 'bankStatementsInfo',
    w2StatementInfo: 'w2StatementInfo',
    profitAndLossStatementInfo: 'profitAndLossStatementInfo',
    socialSecurityInfo: 'socialSecurityInfo',
    pensionInfo: 'pensionInfo',
    retirementInfo: 'retirementInfo',
    form1099Info: 'form1099Info',
    otherIncomeLetterInfo: 'otherIncomeLetterInfo',
    taxReturnInfo: 'taxReturnInfo',
    utilityBillInfo: 'utilityBillInfo',
    insuranceInfo: 'insuranceInfo',
    floodInsuranceInfo: 'floodInsuranceInfo',
    disputeProviderDataDocInfo: 'disputeProviderDataDocInfo',
    supportingDocument: 'supportingDocument',
    incomePortalUploadedDocuments: 'incomePortalUploadedDocuments',
    alreadySubmittedHMDA: 'alreadySubmittedHMDA',
    applicantSubmittedEmployer: 'applicantSubmittedEmployer',
    coApplicantSubmittedEmployer: 'coApplicantSubmittedEmployer',
    applicantMaritalStatus: 'applicantMaritalStatus',
    coApplicantMaritalStatus: 'coApplicantMaritalStatus',
    priorApplicationFoundResponseJSON: 'priorApplicationFoundResponseJSON',
    addReview: 'addRating',
    isUnderwritingInfoUnchanged: 'isUnderwritingInfoUnchanged',
    landingWarning: 'landingWarning',
    hasExtendedLinesOffer: 'hasExtendedLinesOffer',
    landingPageOverride: 'landingPageOverride',
    isMailInviteExpired: 'isMailInviteExpired',
    preQualificationOverride: 'preQualificationOverride',
    experimentsOverrides: 'experimentsOverrides',
    returnToken2: 'returnToken2',
    secondarySignersList: 'secondarySignersList',
    underwritingMetaData: 'underwritingMetaData',
    creditCardMarketData: 'creditCardMarketData',
    personalLoanMarketData: 'personalLoanMarketData',
    abTestOverrides: 'abTestOverrides', // JSON like: [{"type": "underWritingPolicyExperiment", group: "control"}]
    notaryReviewPage: 'notaryReviewPage',
    mloApplicationPii: 'mloApplicationPii',
    mloUwJobId: 'mloUwJobId',
    employmentType: 'employmentType',
    incomeVerificationAllowedDocs: 'incomeVerificationAllowedDocs',
    isInIncomeVerification: 'isInIncomeVerification',
    isInFloodInsuranceVerification: 'isInFloodInsuranceVerification',
    residenceType: 'residenceType',
    identityTries: 'identityTries',

    // Auto
    autoSessionId: 'autoSessionId',
    autoSessionAccessJWT: 'autoSessionAccessJWT',
    autoExperimentOverrides: 'autoExperimentOverrides',
    autoUnderwritingMetaData: 'autoUnderwritingMetaData',

    // this overrides the timeout value for axios only used for cypress tests atm due to them being http/1.1 https://github.com/cypress-io/cypress/issues/3708
    httpTimeout: 'httpTimeout',

    // Flags
    trustsFeatureFlag: 'trustsFeatureFlag',

    // Privacy Policy Banner
    displayedPrivacyPolicyBanner: 'displayedPrivacyPolicyBanner',

    // MLO
    mloOverview: 'mloOverview',
    mloActiveLoanApplications: 'mloActiveLoanApplications',
    mloPastLoanApplications: 'mloPastLoanApplications',
    mloPrefilledApplication: 'mloPrefilledApplication',

    // Churn Retention
    retentionPqOffer: 'retentionPqOffer',
    balanceSweepDetails: 'balanceSweepDetails',

    // Non-owner occupied
    rentalIncomeInfo: 'rentalIncomeInfo',
    verifyCurrentRent: 'verifyCurrentRent',
} as const

export class AppStorage {
    storage: Storage | undefined
    inMemoryStorage: Map<string, string>
    public storeName: string

    constructor(storage: Storage, storeName: string) {
        this.storage = storage
        this.storeName = storeName
        this.inMemoryStorage = new Map()
    }

    /**
     * ONLY USE THIS TO INTERACT WITH STORAGE AND IN MEMORY STORAGE;
     * ALL OTHER METHODS SHOULD DIRECTLY USE THE PROVIDED KEY
     * */
    private getNamespacedKey(key: string): string {
        return `${this.storeName}_${key}`
    }

    private getOriginalKey(key: string): string {
        return key.replace(`${this.storeName}_`, '')
    }

    // window.storage only accepts string
    // https://developer.mozilla.org/en-US/docs/Web/API/Storage/setItem
    setItem(key: string, value: string) {
        assert(typeof key === 'string', `AppStorage.setItem key must be a string`)
        assert(typeof value === 'string', `AppStorage.setItem value for ${key} must be a string`)

        if (key === sessionStorageKey.experimentsOverrides) {
            const enabledOverridesStr = this.getItem(sessionStorageKey.experimentsOverrides)
            if (enabledOverridesStr) {
                /*
                    We need to merge experiments with any existing loaded experiments
                    There are multiple endpoints that get experimentOverrides and each of them passes a subset of the data.
                    For example, referrer is not passed down on return applicant JODL endpoint, while phone number is not passed
                    on the creation of the session when the application is loaded.
                    This does a deep merge of the groups on every experimentation type.
                 */
                const enabledOverrides = JSON.parse(enabledOverridesStr)
                const newOverrides = JSON.parse(value)
                value = JSON.stringify(merge(enabledOverrides, newOverrides))
            }
        }

        logger.info(`set_session_storage_value [${key}: ${value}]`)

        this.inMemoryStorage.set(this.getNamespacedKey(key), value)
        try {
            this.storage?.setItem(this.getNamespacedKey(key), value)
        } catch (e) {
            console.log('Browser storage unavailable, failed with error:', e)
        }

        // Let the app know that the value has been set
        const valueUpdatedEvent = new CustomEvent(`store-updated-${this.storeName}-${key}`, { detail: value })
        window.dispatchEvent(valueUpdatedEvent)
    }

    /**
     * Value can be of any type, if its not a string user must provide a transformer function which returns a string
     * @param key {string}
     * @param value {any}
     * @param transformer {function}
     */
    setItemIfPresent(key: string, value: any, transformer?: (param: any) => string) {
        if (typeof value === 'undefined' || value === null) {
            console.log(`Skipping setting key ${key}`)
            return
        }

        assert(typeof value === 'string' || transformer, 'Value is not of type string and no transformer was provided')

        const finalValue = transformer ? transformer(value) : value
        return this.setItem(key, finalValue)
    }

    /**
     * Get a reactive ref for a key
     * This will live update sync with the storage, useful for rendering data in Vue components
     * and keeping them in sync with the storage
     * @param key
     */
    getItemReactive(key: string): Ref<string | undefined> {
        const reactiveRef = customRef<string | undefined>((track, trigger) => {
            window.addEventListener(`store-updated-${this.storeName}-${key}`, () => {
                trigger()
            })

            return {
                get: () => {
                    const value = this.getItem(key)
                    track()
                    return value
                },
                set: (newVal: string | undefined) => {
                    if (newVal === undefined) {
                        this.removeItem(key)
                    } else {
                        this.setItem(key, newVal)
                    }
                },
            }
        })
        logger.info(`Get reactive item for [${key}]`)

        return reactiveRef
    }

    getItem(key: string): string | undefined {
        // Prioritize browser storage since it is shared across tabs
        let value = this.storage?.getItem(this.getNamespacedKey(key))
        if (typeof value === 'string') {
            // Keep in sync inMemoryStorage
            this.inMemoryStorage.set(this.getNamespacedKey(key), value)
        } else {
            value = this.inMemoryStorage.get(this.getNamespacedKey(key))
            // Don't sync back to browser storage, otherwise deleting a key will not work when multiple tabs are open
        }

        return value ?? undefined
    }

    removeItem(key: string) {
        console.log(`Removing '${key}' from storage`)
        logger.info(`remove_session_storage_value [${key}]`)
        this.inMemoryStorage.delete(this.getNamespacedKey(key))
        this.storage?.removeItem(this.getNamespacedKey(key))
    }

    clear() {
        console.log('Clearing storage contents')
        // log storage clearing. a snap shot of the entire storage before the change is taken
        // in logEvent
        window.logEvent && window.logEvent('clear_session_storage')
        this.inMemoryStorage.clear()
        this.storage?.clear()
    }

    /** @param exceptedKeyList string[] The keys we want to keep */
    clearWithException(exceptedKeyList: string[]) {
        console.log(`Clearing storage contents except: ${exceptedKeyList.join(', ')}`)
        const clearKeys: string[] = []

        // clear session id last so we can link logs by sessionId
        Object.keys(this.getAll())
            // Since we got all keys from actual storage, we need to remove the namespace
            .map((key) => this.getOriginalKey(key))
            .filter((key) => key !== sessionStorageKey.sessionId)
            .forEach((key) => {
                if (!exceptedKeyList.includes(key)) {
                    clearKeys.push(key)
                }
            })

        clearKeys.forEach((key) => this.removeItem(key))

        if (!exceptedKeyList.includes(sessionStorageKey.sessionId) && this.getItem(sessionStorageKey.sessionId)) {
            this.removeItem(sessionStorageKey.sessionId)
        }
    }

    getAll(): { [key: string]: string } {
        const inMemoryStorageObj = Object.fromEntries(this.inMemoryStorage)
        const browserStorageObj = Object.assign({}, this.storage)
        console.debug(`In memory storage contents: ${inspect(inMemoryStorageObj)}`)
        console.debug(`Browser storage contents: ${inspect(browserStorageObj)}`)
        return Object.assign(browserStorageObj, inMemoryStorageObj)
    }
}

/**
 * Creating the instances of storage.
 */
// eslint-disable-next-line no-storage/no-browser-storage
const appLocalStorage = new AppStorage(window.localStorage, 'appLocalStorage')
// eslint-disable-next-line no-storage/no-browser-storage
const appSessionStorage = new AppStorage(window.sessionStorage, 'appSessionStorage')

const stores = new Map<string, AppStorage>([
    ['appLocalStorage', appLocalStorage],
    ['appSessionStorage', appSessionStorage],
])
export const getStore = (storeName: string): AppStorage => {
    let store = stores.get(storeName)
    if (!store) {
        // eslint-disable-next-line no-storage/no-browser-storage
        store = new AppStorage(window.sessionStorage, storeName)
        stores.set(storeName, store)
    }
    return store
}

if (process.env.VUE_APP_NODE_ENV !== 'production') {
    /**
     * Enable direct access for dev purposes.
     * This is meant to be called from the browser console or for external tools to interact with
     * storage.
     * This should NEVER be used directly by application code, the use of @ts-ignore is deliberate
     * because of this so we don't invite devs to use this in code.
     *
     * TL;DR:
     * PLEASE DO NOT ACCESS THIS IN CODE, ADD TYPINGS, ETC...
     */
    // @ts-ignore
    window.appLocalStorage = appLocalStorage
    // @ts-ignore
    window.appSessionStorage = appSessionStorage
    // @ts-ignore
    window.getStore = getStore
}

export { appLocalStorage, appSessionStorage, sessionStorageKey }
